.leadership-wrapper {
    position: relative;
    min-height: 600px;
}

.leadership-container {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    color: #ffffff;
    min-height: 400px;
    padding-top: 80px;
}

.para-leadership-label {
    font-size: 20px !important;
    color: white !important;
}

.head-leadership-label {
    font-size: 50px !important;
    font-weight: 500 !important;
    color: white !important;
}

.head-leadership-label span {
    font-weight: 700 !important;
    color: #4E297B !important;
}

.cards-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    /* margin-top: 30px; */
    width: 100%;
    position: absolute;
    top: 230px;
    left: 0;
}

.leadership-card {
    position: relative;
    /* Makes this card the reference point for absolute positioning of name */
    width: 300px;
    height: 350px;
    border-radius: 8px;
    margin: 10px;
    z-index: 99;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

.leadership-card:hover {
    transform: translateY(-10px);
}

.leadership-image {
    width: 100%;
    height: 100%;
    object-fit: cover;  
    border-radius: 10px; 
}

.leader-container {
    position: absolute;
    bottom: -20px; /* Move the container upwards by 20px */
    left: 0;
    right: 0;
    color: white;
    text-align: center;
    z-index: 999; /* Ensures it stays above the image */
    display: block;
    padding: 0px 20px 0px 20px;
}

.leader-wrapper {
    border-radius: 12px !important;
    background-color: white;
    z-index: 9999;
    padding: 10px; /* Add padding for better visuals */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.leadership-card:hover .leader-name {
    display: block;
    /* Show the name when card is hovered */
}

.leader-name-text {
    font-size: 18px;
    font-weight: 600;
}

@media (max-width: 1199px) {
    .head-leadership-label {
        font-size: 36px !important;
    }
    .leadership-container {
        padding-top: 50px;
    }
    .cards-container {
        top: 180px;
    }
}
@media (max-width: 899px) {
    .para-leadership-label {
        font-size: 18px !important;
    }
    .head-leadership-label {
        font-size: 30px !important;
    }
    .cards-container {
        top: 160px;
    }
    .leadership-wrapper {
        min-height: 580px;
    }
}
@media (max-width: 600px) {
    .head-leadership-label {
        font-size: 20px !important;
    }
    .para-leadership-label {
        font-size: 14px !important;
    }
    .leadership-container {
        padding: 30px 20px 40px;

    } 
    .cards-container {
        position: relative;
        flex-wrap: wrap;
        top: 0;
        margin-top: 15px;
    }
    .leadership-card {
        width: 100%;
        margin: 15px 0;
    }
}