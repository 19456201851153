.mainContent .notifications-section {
  border: 1px solid black;
  border-radius: 15px;
  padding: 24px;
}

.notifications-section h5{
  font-size: 1.5rem;
  font-weight: 500;
}

.notifications-section .notification-setting:nth-child(2) {
  margin-top: 20px;
}
.notifications-section .notification-setting {
  gap: 20px;
  margin-top: 10px;
}

.notifications-section .notification-setting p {
  font-weight: 600;
  font-size: 18px;
  line-height: 37px;
}

.notifications-section .notification-image {
  object-fit: contain;
  width: 51px;
  aspect-ratio: 1.7;
}

.notifications-section .divider {
  margin-top: 15px;
  
  background: rgba(35, 31, 32, 0.3);
}

.notifications-section {
  background-color: white;
  border: 1px solid #333;
  border-radius: 8px;
}

.notifications-section .notification-description {
  max-width: 100%;
  opacity: 60%;
  color: black;
  margin-bottom: 1.5rem;
}
.notifications-section .MuiSwitch-sizeMedium.css-julti5-MuiSwitch-root {
  width: 50px;
  height: 30px;
  border-radius: 25px;
  padding: 0;
}
.notifications-section .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked, .notifications-section .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase  {
  padding: 5px;
  color: #fff;
}
.notifications-section .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background: #4E297B;
  opacity: 1;
}
@media (max-width: 1199px) {
  .notifications-section .notification-setting p {
    font-size: 16px;
    line-height: 32px;
  }
  .notifications-section .divider {
    margin-top: 10px;
  }

}
@media (max-width: 600px) {
  .notification-setting {
    max-width: 100%;
  }

  .notifications-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}
