.team-member {
  display: flex;
  flex-direction: column;
  margin-left: 0;
}

.team-selection .team-member-grid {
  justify-content: flex-start;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  border: 1px solid;
  color: #4a4a4a; /* Stone color */
  height: 220px;
  margin: 0px;
  padding: 50px 10px 0;
}

.special {
  margin-bottom: 0px;
}

.border-stone {
  border-color: #a9a9a9; /* Stone border color */
}

.border-blue {
  border-color: #1e90ff; /* Blue border color */
}

.image {
  object-fit: contain;
}

.special-image {
  aspect-ratio: 1.57;
  width: 88px;
}

.regular-image {
  border-radius: 50%;
  aspect-ratio: 1;
  width: 70px;
}

.card .name {
  margin-top: 16px;
  font-size: 20px;
}

.special-name {
  font-weight: 600;
}

.description {
  margin-top: 10px;
}
@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .card h4 {
    font-size: 23px;
  }
  h5.special-name {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
  }
  .team-member p.description {
    text-align: center;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .card h4 {
    font-size: 23px;
  }
  h5.special-name {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
  }
  p.description {
    text-align: center;
  }
  .card {
    height: 200px;
  }
}
