.sidebarContent .profile-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 0;
  background-color: white;
  border-radius: 15px;
  border: 1px solid #4a4a4a;
  max-width: 100%;
}

.profile-sidebar .profile-image-container {
  position: relative;
  width: 160px;
  height: 160px;
}

.profile-sidebar .profile-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  font-size: 2.5rem;
}

.profile-sidebar .profile-edit-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 10px;
  bottom: 10px;
}

.profile-sidebar .profile-name {
  margin-top: 20px;
  font-weight: 600;
  color: black;
  font-size: 25px;
}

.profile-sidebar .edit-info-btn {
  margin-top: 10px;
  color: #4E297B;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.profile-sidebar .profile-details {
  width: 100%;
  padding: 0 20px;
  margin-top: 40px;
}

.profile-sidebar .profile-label {
  margin-top: 20px;
  font-size: 18px;
  color: black;
  font-weight: 500;
}

.profile-sidebar .profile-value {
  margin-top: 8px;
  font-size: 16px;
  color: #000;
}

.profile-sidebar .divider-bottom {
  margin-top: 40px;
  width: 100%;
}

.profile-sidebar .logout-btn span {
  color: #F02525;
}

.profile-sidebar .logout-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  color: #F02525;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  text-transform: capitalize;
}

.profile-sidebar .logout-icon {
  width: 24px;
  height: 24px;
}

.header-box{
  padding: 1rem 1rem 0rem 1rem;
}

.address-image{
  float: right;
}
.header-box h1{
  float: left;
  padding: 0px 15px;
  font-size: 1.5rem;
  margin-top: 0.8rem;
  text-align: center;
  font-weight: 800;
  width: 93%;
  padding-left: 4rem;
}

.header-box img{
  width: 2rem;
  margin-top: 0.9rem;
}

.editProfile{
  overflow-x: hidden;
  overflow-y: hidden;
}

.editProfile .birthdate, .editProfile .mobileNumber, .editProfile .email, .editProfile .firstName, .editProfile .lastName{
  margin-top: 0px;
}

.editProfile .profile-edit-icon{
  position: relative;
  left: 7rem;
  bottom: 3rem;
}
.editProfile .MuiStack-root{padding-top: 0px;}
.editProfile .anniversaryDate{
  margin-top: 5px;
  margin-bottom: 15px;
}

button.continue{
  margin-bottom: 2rem;
  width: 90%;
  margin:0px auto;
  margin-left: 2rem;
  text-transform: capitalize;
  height: 3.5rem;
  margin-bottom: 1.5rem;
  background: #4E297B;
}
button.continue:hover{
  background: #2e144e;
}

.MuiDialog-container .profile-avatar {
  width: 7rem;
  height: 7rem;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 2rem;
}
.camera-upload-button{
  background-color: transparent;
  border: none;
}