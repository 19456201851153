.businessBanner{
  text-align: center;
}
.businessBanner h3{
  text-align: center;
  font-weight: 300;
  font-size: 60px;
  line-height: 80px;
  margin:0px auto;
  margin-top: 60px;
}
.businessBanner h3 span{
  font-weight: 700;
}
.businessBanner h6{
  text-align: center;
  color: #636363;
  font-size: 18px;
  line-height: 26px;
  margin:0px auto;
  margin-top: 20px;
  width: 100%;
  font-weight: 300;
}
.businessBanner button{
  background: #4E297B;
  color: #ffff;
  margin: 0px auto;
  padding: 14px 20px;
  border-radius: 100px;
  margin-top: 30px;
}
.businessCalender img{
  margin: 0px auto;
  margin-bottom: 50px;
}
.businessCounter{
  background: #f6f6f6;
}
.businessCounter h2{  
  margin: 0px auto;
  font-size: 50px;
  font-weight: 600;
  color: black;
  text-align: center;
  margin-top: 60px;
}
.businessCounter h6{
  margin: 0px auto;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 60px;
}

.businessCounter h5{
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
}
.businessCounter p{
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 60px;
}
.businessCalender h4{
  font-size: 50px;
  line-height: 60px;
  font-weight: 600;
  margin: 0px auto;
} 
.businessCalender h3{
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  margin: 0px auto;
}


.sixBox{
  margin-left: 30px;
  margin-right: 30px;
  padding: 0px 100px 0px 170px;
}

#root .sixBox .sixBoxContent{
  background-color: #f6f6f6;
    width: 90%;
    padding-left: 0px;
    margin-right: 17px;
    margin-bottom: 17px;
}

.sixBox img{
  text-align: center;
  margin: 0px auto;
  display: flex;
  justify-content: center;
}

.sixBox h2{
  font-size: 22px;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.sixBox p{
  text-align: center;
  color: #414141;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  }
  .businessOnlineBooking{
    padding: 0px 100px;
  }
  .businessOnlineBooking img{
    width: 40%;
  }
  .businessOnlineBooking img.phone2{
    position: relative;
    left: -150px;
    top: 120px;
  }
  .businessOnlineBooking h3{
    color: #4E297B;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    margin-top: 150px;
  }
  .businessOnlineBooking h2{
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    margin-top: 20px;
  }
  .businessOnlineBooking p{
    color: #636363;
    font-weight: 300;
    size: 17px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .businessOnlineBooking .businessCheck{
    background-color: #f5f5f5;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 60px;
    padding: 5px 15px;
  }
  .businessOnlineBooking .businessCheck img{
    width: 30px;
    margin: 0px;
  }
  .businessOnlineBooking .businessCheck span{
    color: #4E297B;
    font-size: 18px;
    line-height: 21.6px;
    font-weight: 400;
    margin-left: 10px;
    align-items: center;
    top: -5px;
    position: relative;
  }
  .businessOnlineBooking img.phone4{
    float: right;
  }
  .businessOnlineBooking img.phone3{
    position: relative;
    left: 330px;
    top:90px
  }
  .MuiGrid-container.businessCategory{
    margin-top: 0px;
  }
  .businessCategory h4{
    text-align: center;
    font-size: 40px;
    line-height: 60px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .businessCategory p{
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
  }
  .businessDownload{
    margin-bottom: 120px;
  }

  .business .slick-slider{
    margin-top: 50px;
  }
  .business .testimonial{
    margin-left: 0px;
    padding-left: 0px;
  }
  .business.testimonialContainer{
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .businessDownload h4{
    font-size: 40px;
    line-height: 60px;
    margin: 0px auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .businessDownload p{
    margin: 0px auto;
    color:#636363;
    font-weight: 300;
    font-size: 17px;
    line-height: 26px;
  }
  .businessDownload h2{
    font-weight: 600;
    font-size: 50px;
    line-height: 75px;
  }
  .businessDownload h3{
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
  }
  .businessDownload .first{
    background: #4E297B;
  }
  .businessDownload .second{
    background: rgba(78, 41, 123, 0.1);
  }
  .businessDownload .first h2, .businessDownload .first h3{
    color: white;
  }
  .businessDownload .first h2{
    margin-top: 50px;
    margin-left: 50px;
  }
  .businessDownload .first h3{
    margin-top: 20px;
    margin-left: 50px;
  }
  .businessDownload .first img{
    margin-top: 20px;
    margin-left: 50px;
  }
  .businessDownload .second h2{
    margin-top: 50px;
    margin-left: 50px;
  }
  .businessDownload .second h3{
    margin-top: 20px;
    margin-left: 50px;
  }
  .businessDownload .second img{
    margin-top: 20px;
    margin-left: 50px;
  }
  .businessDownload .businessdownloadPurpleFirst{
    float: left;
  }
  .businessDownload .businessdownloadPurpleSecond {
    float: right;
  }
  .businessDownload .businessdownloadGrayFirst{
    float: left;
  }
  .businessDownload .businessdownloadGraySecond {
    float: right;
  }
  .businessDownload .businessdownloadPurpleSecond img{
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 50px;
  }
  .businessDownload .businessdownloadGraySecond img{
    margin-top: 0px;
    margin-right: 50px;
  }
  
  
  @media (max-width: 1440px) {
    .businessCounter h2{
      font-size: 40px;
    }
    .businessCounter h6{
      font-size: 16px;
      padding-top: 10px;
    }
    .businessCalender h4{
      font-size: 40px;
    }
    .businessBanner h3{
      font-size: 40px;
      line-height: 60px;
    }
    .businessCalender img.calender{
        width: 60%;
        margin-top: 50px;
    }
    .businessCalender h4{
      font-size: 30px;
    }
    .businessCalender h3{
      font-size: 16px;
    }
    .sixBox h2{
      font-size: 18px;
    }
    .sixBox p{
      font-size: 16px;
    line-height: 24px;
    }
    .businessCounter h5{
      font-size: 30px;
    }
    .businessOnlineBooking img.phone2 {
      left: -70px;
      top: 52px;
    }
    .businessOnlineBooking h3{
      margin-top: 90px;
    }
    .businessOnlineBooking h2{
      font-size: 35px;
      line-height: 50px;
    }
    .businessDownload h3{
      font-size: 20px;
      line-height: 40px;
    }
    .business .testimonial .customerName, .business .testimonial .customerLocation{
      font-size: 12px;
    }
    .business .testimonialContainer .customerWords p{
      font-size: 2rem;
    }
    .business .testimonial .customeerCircle{
      width: 40px;
      height: 40px;
    }
    .business .testimonial .customerInitials{
      font-size: 13px;
    }
    .businessDownload{
      margin-bottom: 70px;
    } 
    .businessDownload .businessdownloadGraySecond img {
      margin-top: 100px;
      margin-right: 0px;
      width: 80%;
    }
    .businessDownload .businessdownloadPurpleSecond img{
      margin-top: 100px;
      margin-right: 0px;
      width: 82%;
    }
  }

  @media (max-width: 1366px) {
    .businessBanner h3{
      font-size: 40px;
      line-height: 60px;
    }
    .businessCalender img.calender{
        width: 60%;
        margin-top: 50px;
    }
    .businessCalender h4{
      font-size: 30px;
    }
    .businessCalender h3{
      font-size: 16px;
    }
    .sixBox h2{
      font-size: 18px;
    }
    .sixBox p{
      font-size: 16px;
    line-height: 24px;
    }
    .businessCounter h5{
      font-size: 30px;
    }
    .businessOnlineBooking img.phone2 {
      left: -70px;
      top: 52px;
    }
    .businessOnlineBooking h3{
      margin-top: 90px;
    }
    .businessOnlineBooking h2{
      font-size: 35px;
      line-height: 50px;
    }
    .businessDownload h3{
      font-size: 20px;
      line-height: 40px;
    }
    .business .testimonial .customerName, .business .testimonial .customerLocation{
      font-size: 12px;
    }
    .business .testimonialContainer .customerWords p{
      font-size: 2rem;
    }
    .business .testimonial .customeerCircle{
      width: 40px;
      height: 40px;
    }
    .business .testimonial .customerInitials{
      font-size: 13px;
    }
    .businessDownload{
      margin-bottom: 70px;
    } 
    .businessDownload .businessdownloadGraySecond img {
      margin-top: 100px;
      margin-right: 0px;
      width: 80%;
    }
    .businessDownload .businessdownloadPurpleSecond img{
      margin-top: 100px;
      margin-right: 0px;
      width: 82%;
    }
  }

  @media (max-width: 1280px) {
    .businessBanner h3{
      font-size: 40px;
      line-height: 60px;
    }
    .businessCalender img.calender{
        width: 60%;
        margin-top: 50px;
    }
    .businessCalender h4{
      font-size: 30px;
    }
    .businessCalender h3{
      font-size: 16px;
    }
    .sixBox h2{
      font-size: 18px;
    }
    .sixBox p{
      font-size: 14px;
    line-height: 20px;
    }
    .businessCounter h5{
      font-size: 30px;
    }
    .businessOnlineBooking img.phone2 {
      left: -70px;
      top: 52px;
    }
    .businessOnlineBooking h3{
      margin-top: 90px;
    }
    .businessOnlineBooking h2{
      font-size: 35px;
      line-height: 50px;
    }
    .businessDownload h3{
      font-size: 20px;
      line-height: 40px;
    }
    .business .testimonial .customerName, .business .testimonial .customerLocation{
      font-size: 12px;
    }
    .business .testimonialContainer .customerWords p{
      font-size: 2rem;
    }
    .business .testimonial .customeerCircle{
      width: 40px;
      height: 40px;
    }
    .business .testimonial .customerInitials{
      font-size: 13px;
    }
    .businessDownload{
      margin-bottom: 70px;
    } 
    .businessDownload .businessdownloadGraySecond img{
      margin-top: 100px;
      margin-right: 0px;
      width: 80%;
    }
    .businessDownload .businessdownloadPurpleSecond img{
      margin-top: 100px;
      margin-right: 0px;
      width: 82%;
    }
  }
  @media (max-width: 480px) {
    .businessBanner h3{
      font-size: 25px;
      line-height: 50px;
      padding: 0px 20px;
    }
    .businessBanner h6{
      font-size: 14px;
      padding: 0px 20px;
    }
    .businessCounter{
      padding: 0px 20px;
    }
    .businessCalender img.calender{
        width: 60%;
        margin-top: 50px;
    }
    .businessCalender h4{
      font-size: 30px;
    }
    .businessCalender h3{
      font-size: 16px;
    }
    .sixBox{
      padding: 0px 0px 0px 40px;
      margin-left: 0px;
    }
    .businessCalender{
      padding: 0px 0px 0px 40px;
    }
    .businessOnlineBooking {
      padding: 0px 20px 0px 20px;
    }

    .sixBox h2{
      font-size: 18px;
    }
    .sixBox p{
      font-size: 14px;
    line-height: 20px;
    }
    .businessCounter h2{
      font-size: 25px;
    }
    .businessCounter h5{
      font-size: 20px;
    }
    .businessOnlineBooking img.phone2 {
      left: 20px;
      top: 0px;
    }
    .businessCounter h6{
      margin-top: 20px;
      padding: 0px 20px;
    }

    .businessOnlineBooking h3{
      margin-top: 90px;
    }
    .businessOnlineBooking h2{
      font-size: 35px;
      line-height: 50px;
    }
    .businessDownload h3{
      font-size: 20px;
      line-height: 40px;
    }
    .businessCategory{
      padding: 0px 20px;
    }
    .businessDownload{
      padding: 0px 20px;
    }
    .businessDownload h4{
      font-size: 30px;
    }

    .businessCategory h4{
      font-size: 30px;
      line-height: 50px;
    }

    .business .testimonial .customerName, .business .testimonial .customerLocation{
      font-size: 12px;
    }
    .business .testimonialContainer .customerWords p{
      font-size: 2rem;
    }
    .business .testimonial .customeerCircle{
      width: 40px;
      height: 40px;
    }
    .business .testimonial .customerInitials{
      font-size: 13px;
    }
    .businessDownload{
      margin-bottom: 70px;
    } 
    .businessDownload .businessdownloadGraySecond img{
      margin-top: 100px;
      margin-right: 0px;
      width: 93%;
      margin-left: 0px;
    }
    .businessDownload .businessdownloadPurpleSecond img{
      margin-top: 100px;
      margin-right: 0px;
      width: 93%;
    }
    .businessDownload p{
      text-align: center;
    }
    .business .testimonial .testimonialArea{
      max-width: 100%;
    } 
    .business .testimonial .testimonialDesc {
      font-size: 13px;
    }
    .business .testimonialContainer .customerWords p {
      font-size: 2rem;
    }
    .businessOnlineBooking img.phone4{
      float: left;
    }
    .businessOnlineBooking img.phone3 {
      position: relative;
       left: 20px; 
       top: 0px; 
  }
  }
  