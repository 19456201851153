.sidebar {
  display: flex;
  flex-direction: column;
  margin-left: 1.25rem;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 0.625rem;
}

.profile-card {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 40px;
  background: white;
  border-radius: 1.5rem;
  border: 1px solid #555;
  width: 100%;
}

.MuiTabScrollButton-horizontal{
  opacity: 1;
}

.profile-card .shopOpensAt{
  color: var(--Black, #231f20);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 0.2rem;
    margin-left: 0px;
}

.profile-card .header {
  display: flex;
  gap: 0px;
  width: 100%;
}

.profile-card .header h4{
  color: white  ;
}

.profile-card .header .businessListImage{
  width: 70px;
  height: 70px;
  border-radius: 50px;
  margin-right: 10px;
}

.shopMainDiv .profile-card .crown{
  top:0px
}
.avatar.css-1no4qoj-MuiAvatar-root {
  background-color: #1976d2;
  color: white;
  width: 4.375rem;
  height: 4.375rem;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.25rem;
  border-radius: 50%;
}

.profile-card .initialls{
  width: 40px; 
  height: 40px;
  background-color: #4e297b; 
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin-right: 10px;
  margin-top: 0px,
}

.profile-card .details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 10px;
  position: relative;
}

.shop-name {
  font-size: 1.5rem;
  font-weight: 600;
}

.profile-card .sidebarRating {
  display: flex;
  gap: 0.313rem;
  margin-top: 20px;
}
.profile-card .sidebarRating span {
  font-size: 25px;
  margin-right: 0px;
}

.shop-image {
  width: 10.5rem;
  height: auto;
}

.rating-text.css-1yzf8j7-MuiTypography-root {
  font-size: 1.25rem;
  font-weight: 500;
}

.sidebar .status {
  display: flex;
  gap: 0px;
  margin-top: 50px;
}
.sidebar .status .statusTime {
  display: flex;
}

.sidebar .status p {
  font-weight: 500;
  font-size: 1.375rem;
}

.sidebar .status p:nth-child(2) {
  color: #231f20;
  font-weight: 500;
}

.profile-card .status-icon {
  width: 30px;
  height: auto;
}

.profile-card .shopClosed {
  color: #231f20;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  margin-left: 0.75rem;
  margin-right: 0.5rem;
  margin-top: 0.2rem;
}
.profile-card .shopOpened {
  color: #231f20;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  margin-right: 0.625rem;
  margin-left: 0.75rem;
  margin-top: 0.2rem;
}


.profile-card .address {
  display: flex;
  margin-top: 45px;
  margin-bottom: 45px;
}

.profile-card .address a{
  display: flex;
}

.profile-card .address-icon {
  width: 30px;
  height: auto;
}

.profile-card p.address-text a{
  color: #4E297B;
  font-weight: 500;
  font-size: 20px;
  margin-left: 0.75rem;
  text-decoration: none;
}

.profile-card button {
  margin-bottom: 1.25rem;
  height: 2.8rem;
  text-transform: capitalize;
  font-size: 0.938rem;
  background-color : #4e297b;
  border-radius: 15px;
}
.profile-card button:hover{
  background-color : #2e144e;
}

.book-button {
  margin-top: 2.5rem;
  padding: 0.625rem 2.5rem;
  font-size: 1.25rem;
  background-color: #4e297b;
  color: white;
  border-radius: 0.938rem ;
  width: 100%;
}

.divider {
  margin: 1.25rem 0;
  background-color: #bfbfbf;
}

.profile-card h4.heading{
  margin-top: 30px;
  font-size: 26px;
  line-height: 31px;
  font-weight: 600;
}

.profile-card .shopTitleRating {
  font-size: 24px;
}

.opening-times-title {
  font-size: 1.5rem;
  font-weight: 600;
  /* margin-top: 1.25rem; */
}

.opening-times {
  margin-top: 1.25rem;
}

.days {
  flex-direction: column;
}

.day {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.status-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  border-radius: 50%;
}

.closed.css-1yzf8j7-MuiTypography-root {
  color: red;
}

.hours {
  flex-direction: column;
  text-align: right;
}

.hours p,
.days p {
  font-size: 20px;
  /* line-height: 2.813rem; */
}

.social-accounts {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.25rem 0.55rem 1.25rem;
  margin-top: 1.25rem;
  background: white;
  border-radius: 1.5rem;
  border: 1px solid #555;
  width: 100%;
}

.social-accounts h3,
.offers-form h3,
.profile-card h3 {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
}
.offers-form p {
  color: #231f20;
  opacity: 40%;
  margin-top: 0.313rem;
}

.social-icons {
  width: auto;
  height: auto;
  margin-top: 1.25rem;
}

.offers-form {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  margin-top: 1.25rem;
  background: white;
  border-radius: 1.5rem;
  border: 1px solid #555;
}

.offers-form .MuiInputBase-root {
  margin-bottom: 0rem;
}
.offers-form button {
  border-radius: 0.313rem;
  height: 2.8rem;
  font-size: 0.95rem;
  text-transform: capitalize;
  border-radius: 0.638rem;
}

.offers-form .first{
  margin-bottom: 0.8rem;
}

.form-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.form-subtitle {
  margin-top: 0.625rem;
  font-size: 1.125rem;
}

.text-field {
  margin-top: 0.625rem;
}

.phone-container {
  display: flex;
  margin-top: 1.25rem;
}



.submit-button {
  margin-top: 1.25rem;
  padding: 0.625rem 2.5rem;
  background-color: #1976d2;
  color: white;
}
@media screen and (min-width: 1201px) and (max-width: 1500px) {
  
  .appointmentOptionContainer h5 {
    font-size: 1.25rem;
  }
  .appointmentOptionContainer p {
    font-size: 0.938rem;
    margin-top: 0.313rem;
  }
  .appointmentOptionContainer {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  .profile-card .header h4 {
    font-size: 0.938rem;
    padding: 0.938rem;
  }

  .apptModal h1 {
    font-size: 1.4rem;
    margin-left: 4.1rem;
  }
  .apptModal h2 {
    margin-left: 4.063rem;
  }

  .sidebar .status {
    margin-bottom: 0px;
  }

  
  .shop-name span span {
    font-size: 1.5rem;
  }
  .social-accounts img {
    width: 2.5rem;
  }

  .profile-card .sidebarRating span {
    font-size: 1.5rem;
  }
  .profile-card .shopTitleRating {
    font-size: 1.25rem;
    margin-top: 0px;
  }

  .shop-image {
    width: 54%;
  }
  
  .status p,
  .status p:nth-child(2),
  .profile-card p.address-text {
    font-size: 1.063rem;
  }
  .about-title.css-lcem0f-MuiTypography-root,
  h4.membership,
  .academy-videos-container h4,
  .photos .css-mdbzub-MuiTypography-root,
  h2.leading-tight {
    font-size: 1.438rem;
  }
  .salon-details .salon-name {
    min-height: 1.25rem;
    font-size: 0.75rem;
    padding: 0px 0px;
    line-height: 0.938rem;
  }
  .rating-text.css-1yzf8j7-MuiTypography-root {
    font-size: 0.938rem;
  }

  .salon-rating img {
    width: 0.938rem;
  }
  .salon-type-container img {
    width: 1.25rem;
  }
  .salon-type span {
    padding: 0px 0.438rem;
  }
  .buy-button.css-i053oy-MuiButtonBase-root-MuiButton-root {
    right: 1.25rem;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .hours p,
  .days p {
    font-size: 0.75rem;
  }
  .shop-image {
    width: 54%;
  }

  .profile-card {
    padding: 0.938rem 0.625rem 0px 0.938rem;
  }

  .profile-card .header h4 {
    font-size: 0.938rem;
    padding: 0.938rem;
  }
  
  .makeStyles-flagImg-3{
    width: 1.9rem;
  }
  .offers-form .makeStyles-container-1 input{
    font-size: 0.9rem;
    margin-top: 0.2rem;
  }

  .profile-card hr.divider {
    margin: 0px;
  }

  
  .profile-card .sidebarRating span {
    font-size: 1.25rem;
  }
  .social-accounts h3,
  .offers-form h3,
  .profile-card h3 {
    font-size: 1.188rem;
  }
  .profile-card .opening-times {
    margin-bottom: 1.25rem;
  }
  .sidebar .social-accounts img {
    width: 1.875rem;
  }

  .status p,
  .status p:nth-child(2),
  .profile-card p.address-text {
    font-size: 0.938rem;
  }
  
  h4.membership,
  .academy-videos-container h4,
  .photos .css-mdbzub-MuiTypography-root,
  .reviews-title h2,
  h2.leading-tight {
    font-size: 1.188rem;
  }
  .salon-details .salon-name {
    min-height: 1.25rem;
    font-size: 0.625rem;
    padding: 0px 0px;
    line-height: 0.938rem;
  }
  .rating-text.css-1yzf8j7-MuiTypography-root {
    font-size: 0.938rem;
  }

  .salon-rating img {
    width: 0.75rem;
  }
  .salon-type-container img {
    width: 1.25rem;
  }
  .nearBy .salon-type span {
    padding: 0px 0.438rem;
  }
  .buy-button.css-i053oy-MuiButtonBase-root-MuiButton-root {
    right: 1.25rem;
  }
}



.offers-form .makeStyles-container-1 {
  margin-bottom: 1.25rem;
  display: flex;
  gap: 0.938rem;
  padding: 0.313rem;
  max-width: 100%;
  font-size: 1rem;
  background-color: #ffffff;
  border-radius: 0.313rem;
  border: 1px solid rgba(82, 82, 82, 0.5);
  color: #525252;
  width: 100%;
  margin-top: 0.6rem;
}

.offers-form .text-field p {
  opacity: 100%;
  margin-left: 0px;
  margin-top: -0.625rem;
  margin-bottom: 0.313rem;
}
.offers-form .text-danger {
  color: #d32f2f;
  margin-top: -0.625rem;
  margin-bottom: 0.313rem;
  font-size: 0.75rem;
}

.appt .appointmentOptionContainer {
  margin-top: 20px;
  max-width: 100%;
  border: 1px solid #555;
  width: 28.125rem;
  padding: 24px 0px;
  display: flex;
  flex-Direction: column;
  border-Radius: 16px;
  border-color: black;
  color: black;
  
}
.appt .appointmentOptionContainer h5 {  
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.appt main{
  display: flex;
  flex-Direction: column;
  background-color: white;
  overflow: hidden;
}
.appt section{
  display: flex;
  flex-Direction: column;
  justify-Content: center;
  align-Items: center;
  width: 100%;
  padding: 0px;
  border-radius: 0.938rem;
  background: transparent;
}

.appt section .MuiPaper-elevation{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-Width: 100%;
  background-color: white;
  border-radius: 3px;
  box-shadow: 3px;
  width: 100%;
  padding: 42px 40px 32px 20px;
}

.appt .appointmentOptionContainer p{
  margin-top: 10px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  text-align: center;
}

.appt .top{
  margin-top: 0px;
  display: flex;
  justify-content: center;
}
.appt .top h1{
  font-size: 31px;
  line-height: 37px;
  font-weight: 700;
}

.appt .top h2{
  padding-left: 3rem;
}

.appt .apptModal > div{
  padding-top: 5px;
}  

.appt .apptModal .close{
  padding-top: 0px;
}

.apptModal .css-13mhfsw-MuiGrid-root > .MuiGrid-item {
  margin-left: 23%;
}
.apptModal .css-1n5hmtd-MuiAvatar-root {
  margin-top: 0px;
  float: right;
  width: 2.5rem;
  height: 2.5rem;
}
.appt .css-eu3m2i-MuiPaper-root-MuiCard-root {
  padding: 0.625rem 0px 2.5rem 0px;
}
.apptModal .css-1c1rpso-MuiTypography-root {
  text-align: center;
}
.apptModal p {
  text-align: center;
}
/* .nearBy .slick-slider{
  width: 100%;
}
.nearBy .slick-slider .slick-slide{
  padding-right: 15px;
}


.nearBy .slick-list{
  overflow: visible;
} */


@media screen and (max-width: 600px) {
  .shopMainDiv .sidebar {
    margin-left: 0px;
  }
}

/* For screens wider than 1600px */
@media (max-width: 1600px) {
  .profile-card {
    padding: 30px;
  }  
  .profile-card h3, .profile-card h2.opening-times, .social-accounts h3, .offers-form h3{
    font-size: 1.5625rem;    
  } 
  .profile-card .shopOpened,
  .profile-card .shopOpensAt,
  .profile-card .shopClosed
  {
    font-size: 18px;
    margin-top: 0.313rem;
  }
  
  .profile-card p.address-text {
    font-size: 1.2500rem;
  }
  .profile-card button, .offers-form button{
    height: 3.2rem;
    font-size: 1.2rem;
    text-transform: capitalize;
  }
  .hours p, .days p{
    font-size: 17px;    
  }
  .offers-form p{
    font-size: 1.0417rem;
  }
}



/* For screens wider than 1280px */
@media (max-width: 1440px) {
  .profile-card {
    padding: 20px;
  }
  .profile-card h3, .profile-card h2.opening-times, .social-accounts h3, .offers-form h3{
    font-size: 1.1500rem;    
  }
  .profile-card .header .businessListImage {
    width: 50px;
    height: 50px; 
    margin-right: 5px;
}
.profile-card .sidebarRating {
  margin-top: 0;
}
.profile-card .status-icon {
  width: 20px;
}
  .appt .top h1, .appt .top h2{
    padding-left: 30px;
    margin-left: 0px;
  }
  
  
  .profile-card .shopOpened,
  .profile-card .shopClosed,
  .profile-card .shopOpensAt {
    font-size: 14px;
    margin-top: 0.313rem;
    margin-right: 0px;
  }
  .profile-card p.address-text a {
    font-size: 16px;
    margin-left: 0;
  }
  .profile-card .address-icon {
    width: 20px;
  }
  
  .profile-card .address{
    gap: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 20px;
  }
  .profile-card p.address-text {
    font-size: 0.8167rem;
    margin-left: 0px;
  }

  .shopMainDiv .TitleImage h2 span{
    bottom: 0.8rem;
  }

  .about-map p{
    font-size: 0.8167rem;
    color: #231f20;
  }

  .teamNotFound{
    font-size: 0.8167rem;
    font-weight: 400;
  }

  .profile-card button, .offers-form button{
    height: 2.8rem;
    font-size: 1rem;
  }

  .offers-form input{
    height: 0.8rem;
  }
  .offers-form label{
    font-size: 0.8rem;
  }
  .offers-form .makeStyles-container-1 input{
    font-size: 0.8rem;
  }
  .profile-card h4.heading {
    margin-top: 20px;
    font-size: 20px;
    line-height: 24px;    
}
.status-dot {
  width: 16px;
  height: 16px;
}
  .hours p, .days p{
    font-size: 14px;
  }
  .offers-form p{
    font-size: 0.8333rem;
  }
  .sidebar .status{
    margin-top: 1rem;
  }
  .profile-card .shopTitleRating{
    font-size: 1.05rem;
    margin-top: 0.2rem;
  }
}




@media (max-width: 1199px) {  
  .profile-card .header .businessListImage {
    width: 45px;
    height: 45px; 
  }  
  .sidebar .status .statusTime {
    flex-wrap: wrap;
    font-size: 13px;
  }
  .sidebar .day {
    gap: 0;
  }
  
}
@media (max-width: 899px) {
.sidebar{
  margin-left: 0px;
}
.profile-card .shopOpened, .profile-card .shopOpensAt{
  padding: 0px;
}
/* .profile-card h3, .profile-card h2.opening-times, .social-accounts h3, .offers-form h3{
  font-size: 1.5rem;
} */
.social-accounts .MuiGrid-container .MuiGrid-root{
  max-width: 3rem;
}
.social-accounts img{
  width: 2rem;
}
.offers-form p{
  font-size: 0.8667rem;
}
.profile-card .shopOpened, .profile-card .shopClosed, .profile-card .shopOpensAt, .profile-card p.address-text, .profile-card .shopTitleRating{
  font-size: 0.9rem;
}
.profile-card .sidebarRating{
  margin-top: 0.3rem;
}
.profile-card h3{
  margin-top: 0px;
}
}

