.about-section {
  margin-top: 2.5rem;
}

.about-section .about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

hr.aboutDivider{
  margin-top: 40px;
  height: 1px;
  background-color: #bfbfbf;
}

.about-section .about-content {
  display: flex;
  flex-direction: column;
  margin-top: 17px;
}

.about-content .about-title{
  color: #231f20;
    font-size: 36px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 43.2px;
    word-wrap: break-word;
}

.about-section .about-description {
  margin-top: 1rem;
  font-size: 22px;
  line-height: 37px;
  text-align: justify; 
  max-width: 800px; 
  margin-left: auto; 
  margin-right: auto;
  word-wrap: break-word; 
  font-weight: 400;
}

.about-section .about-address-container {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.about-section .about-address-container a{
  text-decoration: none;
}

.about-section .about-address {
  margin-right: 0.7rem;
}

.about-section .about-icon {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.about-section .about-nearby {
  margin-top: 1rem;
  font-weight: 300;
}

.containerWrap .about-section .about-map {
  width: 100%;
  object-fit: contain;
  border-radius: 0;
  aspect-ratio: 1.11;
  padding-top: 40px; 
}

.about-section .about-map > div {
  border-radius: 20px;
  height: 350px !important;
}

/* .about-section .about-map > div > div{
  height: 500px !important;
} */

@media (max-width: 1199px) {
  .about-section .about-map > div {
    height: 300px !important;
  }
}

@media (max-width: 600px) {
  .about-container {
    flex-direction: column;
  }

  .about-title {
    font-size: 1.75rem;
  }

  .about-description {
    font-size: 1.25rem;
  }

  .about-map {
    margin-top: 1rem;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .about-section .about-description {
    font-size: 14px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .about-section .about-description {
    font-size: 0.9rem;
    font-weight: 400;
  }
}
@media screen and (min-width: 881px) and (max-width: 1024px) {
  .about-section .about-title {
    font-size: 18px;
  }
  .about-section .about-description {
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 880px) {
  .about-section .about-title {
    font-size: 18px;
  }
  .about-section .about-description {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .about-section .about-map .mapSection {
    height: 550px;
  }
  .about-section .about-map{
    aspect-ratio: auto;
  }
}
