.reviews-section {
  margin-top: 30px;
}

.reviews-header {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  justify-content: space-between;
  max-width: 100%;
}

.reviews-title {
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.reviews-title h2 {
  
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
}

.reviewsRating{
  display: flex;
}

.rating-image {
  object-fit: contain;
  margin-top: 0.75rem;
  width: 50%;
  float: left;
}

.reviews-header .overall-rating {
  font-weight: 500;
  font-size: 1.2rem;
  margin-left: 0px;
  margin-top: 7px;
  float: left;
}

.reviews-header .see-all-button {
  margin: auto 0;
  padding: 7px 25px;
  font-size: 20px;
  background-color: #4E297B; /* Stone color */
  color: white;
  border-radius: 30px;
  text-transform: capitalize;
}
.reviews-header .see-all-button:hover{
  background-color: #2e144e;
}

.reviews-section .reviews-list {
  display: flex;
  gap: 1.25rem;
  padding: 16px 0px;
}

@media (max-width: 1440px) {
  .reviews-header .see-all-button {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 899px) {
  .reviews-section {
    margin-top: 0;
  }
}
@media (max-width: 600px) {
  .reviews-header .see-all-button {
    font-size: 14px;
    line-height: 18px;
  }
}
