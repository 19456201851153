#root .favorites-page {
  padding-bottom: 80px;
  background-color: white;
}
.favorites-page .MuiContainer-root {
  max-width: 100%;
  padding: 0px 100px;
}

.favorites-page .salon-list .MuiGrid-item{
  display: flex;
}

@media (max-width: 600px) {
  .favorites-page {
    padding-bottom: 24px;
  }
}

.favorites-page .favorites-title {
  font-size: 31px;
  font-weight: bold;
  color: black;
}

@media (max-width: 600px) {
  .favorites-page .favorites-title {
    margin-top: 24px;
    font-size: 1.75rem;
  }
}

.favorites-page .salon-list {
  margin-top: 40px;
}

@media (max-width: 600px) {
  .favorites-page .salon-list {
    margin-top: 24px;
  }
}
@media (max-width: 1600px) {
  .favorites-page .favorites-title{
    font-size: 1.6146rem;
    font-weight: 583;
  }
}

@media (max-width: 1440px) {
  .favorites-page .favorites-title{
    font-size: 1.4531rem;
    font-weight: 525;
  }
}

@media (max-width: 1366px) {
  .favorites-page .favorites-title{
    font-size: 1.3785rem;
    font-weight: 498;
  }
}

@media (max-width: 1280px) {
  .favorites-page .favorites-title{
    font-size: 1.2917rem;
    font-weight: 467;
  }
  
}

@media (max-width: 1200px) {
  .rating-section p{
    margin-top: 5px;
    margin-right: 5px;
  }
  .favorites-page .salon-card .image-section{
    height: 250px;
  }
}

@media (max-width: 899px) {
  .favorites-page .MuiContainer-root {
    padding: 0px 50px;
  }
  .favorites-page .favorites-title{
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .favorites-page .favorites-title{
    font-size: 31px;
    font-weight: 700;
    line-height: 37.2px;
  }
  .favorites-page .salon-card .rating-section{
    gap: 0px;
  }
  .favorites-page .salon-card .rating-section p{
    font-size: 12px;
    line-height: 10px;
  }
  .favorites-page .salon-card .salon-info h6{
    -website-line-clamp:3;
  }
}

@media (max-width: 768px) {
  .favorites-page .favorites-title{
    font-size: 30px;
    font-weight: 600;
  }
  
}
@media (max-width: 600px) {
  .favorites-page .MuiContainer-root {
    padding: 0px 20px;
  }
}