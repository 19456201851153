.appointment-booking {
  display: flex;
  flex-direction: column;
}

.appointment-booking .time-selection-title {
  margin: 20px 0;
  font-weight: bold;
}

.appointment-booking .time-selection-header {
  display: flex;
  gap: 10px;
  padding: 20px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 500px;
  width: 20%;
}

.appointment-booking .bookingTime {
  padding-left: 0px;
  max-width: 100%;
}

.appointment-booking .date-indicator {
  display: flex;
  gap: 5px;
  align-items: center;
}
.bookingTime .MuiContainer-root {
  max-width: 100%;
  padding: 0px 48px;
}
.appointment-booking .time-selection-header select {
  background: transparent;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: large;
}
.appointment-booking .time-selection-header select option {
  color: black;
}

.custom-date-picker input {
  cursor: pointer;
}
button.MuiPickersDay-root.Mui-selected{
  background-color: #4E297B;
}
button.MuiPickersDay-root.Mui-selected:hover{
  background-color: #2e144e;
}

/* Force selected date to always be purple */
.MuiPickersDay-root.Mui-selected {
  background-color: #4E297B !important; /* Purple background */
  color: #fff !important; /* White text */
}

/* Ensure hover doesn't change the color */
.MuiPickersDay-root.Mui-selected:hover {
  background-color: #2e144e !important;
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .time-selection-header {
    width: 30%;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .time-selection-header {
    width: 40%;
  }
}
