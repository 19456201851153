.favorites-page .breadcrumb-container {
  display: flex;
  gap: 16px;
  color: #57534e; /* text-stone-800 equivalent */
  margin-top: 30px;
}

.breadcrumb-container .breadcrumb-image {
  object-fit: contain;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

@media (max-width: 600px) {
.favorites-page .breadcrumb-container{
  flex-direction: row;
}
.favorites-page .breadcrumb-container .breadcrumb-content{
  padding-top: 3px;
}
}

.favorites-page .favorites-title{
  padding-top: 40px;
  margin-bottom: 60px;
}
@media (min-width: 1240px) and (min-height: 472px) and (max-resolution: 96dpi) {
  .favorites-page .MuiContainer-root{
    padding: 0px 30px;
  }
  
}
