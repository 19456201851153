.noScroll {
  /* overflow: hidden; */
}

#root .search {
  min-height: auto;
}
.search .grid-container {
  padding-top: 0px;
  overflow-x: none;
  overflow-y: none;
  padding-left: 100px;
  margin-top: 0px;
}
.search .grid-container .scrollableLeft {
  padding-bottom: 20px;
  padding-right: 20px;
  /*max-height: calc(100vh - 114px);*/
  /* max-height: calc(-64px + 100vh); */
  max-height: 100vh;
  overflow-y: auto;
  border-right: 1px solid #ccc;
}

/* Custom scrollbar styling */
.search .grid-container .scrollableLeft::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.search .grid-container .scrollableLeft::-webkit-scrollbar-track {
  background: #f9f9f9; /* Track background */
  border-radius: 10px; /* Rounded corners for the track */
}
.search .grid-container .scrollableLeft::-webkit-scrollbar-thumb {
  background: #4e297b; /* Scrollbar handle color */
  border-radius: 10px; /* Rounded corners for the handle */
  border: 2px solid #4e297b; /* Adds a gap between handle and track */
}

.search .grid-container .scrollableLeft::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

.search .grid-container .mapRight {
  padding-top: 0px;
  display: flex;
  justify-content: center;
  padding-left: 0px;
  min-height: auto;
  position: relative;
}
.search .grid-container .scrollableLeft .searchResultContainer {
  display: flex;
  padding-top: 4px;
  align-items: center;
  padding-bottom: 20px;
}
.search .grid-container .scrollableLeft .searchResultContainer .backButton {
  line-height: 1;
  cursor: pointer;
}
.search .grid-container .scrollableLeft .searchResultContainer .resultMessage {
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.search .grid-container .scrollableLeft .onlyBottom {
  justify-content: center;
  padding-bottom: 20px;
}

.nearestFilter {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 56px;
  line-height: 36px;
}
.nearestFilter.selected {
  border-color: #4e297b;
  color: #4e297b;
  background: rgba(78, 41, 123, 0.05);
}
.listTitle h1 {
  margin: 0 0 20px;
  font-size: 26px;
  line-height: 30px;
}

@media (max-width: 1280px) {
  .search
    .grid-container
    .scrollableLeft
    .searchResultContainer
    .backButton
    img {
    width: 25px;
  }
  .grid-container .search-grid .MuiSelect-select {
    padding: 8.5px 0px 8.5px 5px;
  }
}
@media (max-width: 1199px) {
  .search .grid-container {
    padding-left: 64px;
  }
}
@media (max-width: 899px) {
  .search .grid-container {
    padding-left: 0px;
  }
  .search .grid-container .scrollableLeft {
    overflow-y: hidden;
    max-height: 100%;
    padding-left: 64px;
  }

  .search .grid-container .mapRight {
    padding-left: 0px;
    order: -1;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer {
    width: calc(100% - 145px);
  }
  .search .grid-container .mapRight {
    flex-direction: column;
  }

  .noScroll {
    overflow: auto;
  }
}

@media (max-width: 600px) {
  .search .grid-container {
    padding-left: 20px;
  }
  .search .grid-container .scrollableLeft {
    overflow-y: hidden;
    max-height: 100%;
    padding-left: 20px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer {
    width: auto;
  }
  .search .grid-container .mapRight {
    padding-top: 0px;
    flex-direction: column;
    order: -1;
  }
}
