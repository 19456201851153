.review-card {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.review-card .card {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: auto;
  margin: 10px 0px 0px 0px;
  padding: 20px 20px 20px 20px;
  background-color: white;
  border-radius: 16px;
  border: 1px solid #4b5563; /* Equivalent to border-stone-800 */
  box-shadow: none;
  height: auto;
}

.review-card .card .card-content {
  display: flex;
  flex-grow: 1;
  gap: 10px;
  padding: 0px 0px 0px 0px;
}

.review-card .card .avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review-card .card .name {
  font-weight: 500;
  font-size: 1.5rem; /* Equivalent to text-2xl */
  margin-top: 0px;
}

.rating {
  width: 168px;
  height: auto;
}

.date {
  margin-top: 5px;
  color: #9ca3af; /* Equivalent to text-neutral-400 */
}

.review-card .comment {
  color: #231F20;  

}

@media (max-width: 1199px) {
  .review-card .card {
    padding: 15px;
  }
  .review-card .comment {   
    margin-top: 5px;
  }
}
@media (max-width: 899px) {
  .review-card .card .card-content {
    flex-direction: row;
  }
  .review-card .card {
    padding: 10px;
  }
}
@media (max-width: 600px) {
  .review-card .card {
    margin-top: 0;
  }
}


