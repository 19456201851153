.time-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* justify-content: space-between; */
  margin-top: 64px;
  max-width: 100%;
  text-align: center;
  font-size: 1.25rem;
  color: #4b5563; /* stone-800 */
}

/* .time-button.css-xm0w9d-MuiButtonBase-root-MuiButton-root { */
.time-button-available {
  padding: 15px !important;
  border: 1px solid rgba(35, 31, 32, 0.5) !important; /* stone-800 with opacity */
  border-radius: 12px !important;
  background-color: white !important;
  height: 70px !important;
  width: 20% !important;
  color: #231f20 !important;
  font-size: 16px !important;
}

.time-button-break {
  padding: 15px !important;
  border: 1px solid rgba(240, 37, 37, 0.5) !important; /* stone-800 with opacity */
  border-radius: 12px !important;
  background-color: white !important;
  height: 70px !important;
  width: 20% !important;
  color: rgba(240, 37, 37, 0.5) !important;
  font-size: 16px !important;
  cursor: auto !important;
  flex-direction: column !important;
  line-height: 1.2 !important;
}

.time-button-break .time-slot-type {
  text-transform: capitalize;
}

.time-button-premium {
  padding: 15px !important;
  border: 1px solid #4e297b !important; /* stone-800 with opacity */
  border-radius: 12px !important;
  background-color: #4e297b !important;
  height: 70px !important;
  width: 20% !important;
  color: #fdfcfd !important;
  font-size: 16px !important;
  flex-direction: column !important;
  line-height: 1.2 !important;
}

.time-button-premium .time-slot-type {
  text-transform: capitalize;
}

.time-button-block {
  padding: 15px !important;
  border: 1px solid rgba(35, 31, 32, 0.5) !important; /* stone-800 with opacity */
  border-radius: 12px !important;
  background-color: white !important;
  height: 70px !important;
  width: 20% !important;
  color: rgba(35, 31, 32, 0.5) !important;
  font-size: 16px !important;
  cursor: auto !important;
  flex-direction: column !important;
  line-height: 1.2 !important;
}

.time-button-block .time-slot-type {
  text-transform: capitalize;
}

.time-button-booked {
  padding: 15px !important;
  border: 1px solid rgba(78, 41, 123, 0.5) !important; /* stone-800 with opacity */
  border-radius: 12px !important;
  background-color: white !important;
  height: 70px !important;
  width: 20% !important;
  color: rgba(78, 41, 123, 0.5) !important;
  font-size: 16px !important;
  cursor: auto !important;
  flex-direction: column !important;
  line-height: 1.2 !important;
}

.time-button-booked .time-slot-type {
  text-transform: capitalize;
}

.time-button-available-selected {
  padding: 15px !important;
  border: 2px solid rgba(78, 41, 123, 0.5) !important; /* stone-800 with opacity */
  border-radius: 12px !important;
  background-color: white !important;
  height: 70px !important;
  width: 20% !important;
  color: rgb(78, 41, 123) !important;
  font-size: 16px !important;
}

@media (max-width: 1199px) {
  .time-selector {
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .time-selector {
    margin-top: 40px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .time-button.css-xm0w9d-MuiButtonBase-root-MuiButton-root {
    padding: 24px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .time-button.css-xm0w9d-MuiButtonBase-root-MuiButton-root {
    padding: 17px;
  }
}
