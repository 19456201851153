.marker-label{
  font-size: 12px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #000;
  position: absolute;
  left: -40px;
  z-index: 1000;
}
.mapRight .search-grid {
  background: white;
  border-radius: 50px;
  z-index: 1;
  position: absolute;
  top: 100px;
  width: calc(100% - 90px);
  margin-left: 0px;
}
.mapRight .searchMap{
  width: 100vw !important;
  height: 100vh !important;
}
.grid-container .search-grid .mapSearch {
  width: 100%;
  flex-shrink: 0;
  border-radius: 60px !important;
  border: 1px solid black;
  color: black;
  text-transform: capitalize;
  padding: 13px 0px;
}

.grid-container .search-grid .filterPrice
 {
  padding: 10px 30px 10px 20px;
}

.grid-container .search-grid .filterPrice .MuiSlider-marked{
  padding: 20px 0;
  margin-bottom: 0px;
}

.grid-container .search-grid .genderFilter,
.grid-container .search-grid .clearFilter,
.grid-container .search-grid .searchFilter, 
.grid-container .search-grid .categoryFilter{
  padding: 10px 10px;
}
.grid-container .search-grid .nearByFilter{
  padding: 10px 0px 10px 20px;
}
.grid-container .search-grid label {
  font-size: 14px;
  line-height: 1.73rem;
}
@media (max-width: 1600px) {
  .grid-container .search-grid .genderFilter{
    padding: 10px 5px;
  }
  .grid-container .search-grid label {
    font-size: 13px;
    line-height: 1.53rem;
  }
}

@media (max-width: 1440px) {
  .grid-container .search-grid label {
    font-size: 11px;
  }
  .grid-container .search-grid .filterPrice {
    padding: 10px 20px 10px 20px;
  }
  .grid-container .search-grid .filterPrice .MuiSlider-markLabel{
    font-size: 0.5rem;
  }
  .grid-container .search-grid .mapSearch{
    padding: 10px 0px;
  }
  .grid-container .search-grid .genderFilter{
    padding: 10px 5px;
  }
  .grid-container .search-grid .MuiSelect-select{
    padding: 11.5px 9px;
  }
}
@media (max-width: 1366px) {
  .grid-container .search-grid label {
    font-size: 10px;
    line-height: 1rem;
  }
  .grid-container .search-grid .filterPrice {
    padding: 10px 20px 10px 20px;
  }
  .grid-container .search-grid .filterPrice .MuiSlider-markLabel{
    font-size: 0.5rem;
  }
  .grid-container .search-grid .MuiSelect-select{
    padding: 11.5px 9px;
  }
  .grid-container .search-grid .mapSearch{
    padding: 9px 0px;
  }
  .grid-container .search-grid .genderFilter{
    padding: 10px 5px;
  }
}
@media (max-width: 1280px) {
  .grid-container .search-grid label {
    font-size: 10px;
    line-height: 1rem;
  }
  .grid-container .search-grid .MuiSvgIcon-root{
    right: 0px;
  }
  /* .mapRight .search-grid{
    width: 58%;
    margin-left: 0px;
  } */
  .grid-container .search-grid .filterCategory{
    height: auto;
  }
  .grid-container .search-grid .filterPrice {
    padding: 10px 20px 0px 20px;
  }
  .grid-container .search-grid .genderFilter{
    padding: 10px 5px;
  }
  
}
@media (max-width: 1199px) {
  .mapRight .search-grid{
    top: 75px;
    width: calc(100% - 50px);
    margin-left: 0px;
    padding: 15px 20px;
    border-radius: 20px;
}
.grid-container .search-grid .nearByFilter {
  padding: 10px 10px 10px 10px;
}
.grid-container .search-grid .filterPrice .MuiSlider-markLabel {
  font-size: 12px;
}

.grid-container .search-grid .MuiSelect-select {
    padding: 10.5px 6px;
}
.grid-container .search-grid label {
  font-size: 12px;
  line-height: 0.8rem;
}
.grid-container .search-grid .MuiSvgIcon-root {
  right: -2px;
}
.grid-container .search-grid .filterCategory{
  height: auto;
}
.grid-container .search-grid .filterPrice {
  padding: 10px 20px 0px 20px;
}

}
@media (max-width: 899px) {
  .mapRight .search-grid{
    top:auto;
    position: relative;
    width: 100%;
    padding: 50px 0px;
  }
  .mapRight .searchMap{
    margin-top: 0px;
    width: 100% !important;
    height: 400px !important;
    overflow: hidden;
  }
  .mapRight .searchMap div{
    overflow: visible !important;
  }
  .mapRight .search-grid{
    top: 0px;
    width: 50%;
    margin: 10px auto;
    padding: 10px;
    border-radius: 0;
    background: transparent;
  }
  .grid-container .search-grid .MuiSelect-select {
    padding: 10.5px;
    font-size: 12px;
  }
  
}
@media (max-width: 600px) {
  .mapRight .searchMap{
    margin-top: 0px;
    width: 100% !important;
    height: 400px !important;
    overflow: hidden;
  }
  .mapRight .searchMap div{
    overflow: visible !important;
  }
  .mapRight .search-grid{
    top: 0px;
    width: 100%;
    margin: 0px auto;
    padding: 50px 20px;
  }
  .grid-container .search-grid .categoryFilter, .grid-container .search-grid .genderFilter, .grid-container .search-grid .nearByFilter,
  .grid-container .search-grid .searchFilter, .grid-container .search-grid .clearFilter
  {
    padding: 10px 0px;
  }
  .grid-container .search-grid .MuiSelect-select {
    padding: 10.5px;
    font-size: 12px;
  }

  .grid-container .search-grid .filterPrice{
    padding: 10px;
  }

  .mapRight .searchMap div::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Webkit browsers */
  }
  
  .mapRight .searchMap div {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;     /* Firefox */
  }
  
}

