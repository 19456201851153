.team-member {
  display: flex;
  flex-direction: column;
  margin-left: 0;
}

.team-selection .team-member-grid {
  justify-content: flex-start;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  border: 1px solid;
  color: #4a4a4a; /* Stone color */
  height: 220px;
  margin: 0px;
  padding: 50px 10px 0;
}

.special {
  margin-bottom: 0px;
}

.border-stone {
  border-color: #a9a9a9; /* Stone border color */
}

.border-blue {
  /* border-color:#4E297B; */
  border: 1.5px solid #4e297b;
  /* Blue border color */
}

 .image.regular-image {
  object-fit: cover;
} 

.special-image {
  aspect-ratio: 1.57;
  width: 88px;
}

.regular-image {
  border-radius: 50%;
  aspect-ratio: 1;
  width: 70px;
  height: 70px;
}

.card .name {
  margin-top: 16px;
  font-size: 20px;
  /*min-height: 66px; */
  padding: 0px;
  text-align: center;
  word-break: break-all;
}

.special-name {
  font-weight: 600;
}

.description {
  margin-top: 10px;
}

.team-member .card:hover {
  cursor: pointer;
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .card h4 {
    font-size: 23px;
  }
  .card h5.special-name {
    text-align: center;
    margin-top: 20px;
  }
  .team-member p.description {
    text-align: center;
    font-size: 12px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .card h4 {
    font-size: 23px;
  }
  .team-member .special-image {
    width: 60px;
  }
  .card h5.special-name {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
  }
  .team-member p.description {
    text-align: center;
    font-size: 12px;
  }
  .card {
    height: 200px;
  }
}
@media (max-width: 1280px) {
  .card {
    padding-top: 30px;
    height: 180px;
  }
}
@media screen and (min-width: 900px) and (max-width: 1024px) {
  .card h4 {
    font-size: 20px;
  }
  .team-member .special-image {
    width: 60px;
  }
  .card h5.special-name {
    text-align: center;
    font-size: 10px;
    margin-top: 10px;
  }
  p.description {
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .team-member .card {
    height: auto;
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .card {
    padding-top: 20px;
    height: 150px;
  }
  .special-image {
    width: 75px;
  }
}
/* @media only screen and (max-width: 899px) {
  .team-member .card {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .card h4 {
    font-size: 20px;
  }
  .team-member .special-image {
    width: 60px;
  }
  .card h5.special-name {
    text-align: center;
    font-size: 10px;
    margin-top: 10px;
  }
  p.description {
    text-align: center;
    font-size: 12px;
  }
  .team-member .card {
    height: auto;
    margin-bottom: 30px;
  }
}
@media (max-width: 480px) {
  .card h5.special-name{
    font-size: 15px;
  }
  .team-member .card{
    margin-bottom: 0px;
  }
  .team-member p.description{
    font-size: 0.7333rem;
    font-weight: 367;
  }

} */
