.faq-container {
    padding: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.faq-content {
    padding-top: 80px;
    max-width: 1000px;
}

.faq-container-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    gap: 20px;
}

.faq-content-wrapper {    
    color: #333333;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    padding: 20px 0px 20px 0px;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0;
    box-shadow: none;    
}
.faq-content .MuiAccordion-root {
    box-shadow: none;
}
.faq-content .faq-question {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    color: #333333;
}
.faq-content .Mui-expanded.MuiAccordionSummary-root {
    border-bottom: 1px solid #d7d7d7;
    margin: 12px 0;
}
.faq-content .Mui-expanded.MuiAccordionSummary-root .faq-question {    
    color: #4E297B;        
}
.faq-content .faq-content-wrapper.Mui-expanded {
    border-bottom: none;
    padding: 0;      
}
.faq-content .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
}

.faq-content-wrapper .MuiSvgIcon-root {
    width: 28px;
    height: 28px;
    padding: 3px;
}
.faq-content .faq-content-wrapper.Mui-expanded .MuiSvgIcon-root  {
    fill: #4E297B;
    background: rgba(78, 41, 123, 0.2);
    padding: 5px;
}

.faq-answer {
    color: #333333 !important;
    padding: 20px 0px 0px 0px !important;
}

@media (max-width: 1199px) {
    .faq-container {
        padding: 70px;
    }
    .faq-content {
        padding-top: 50px;
    }
}
@media (max-width: 899px) {
    .faq-container {
        padding: 50px;
    }   
    .faq-container-wrapper .faqTitle {
        font-size: 36px;
    }
    .faq-content {
        padding-top: 10px;
    }
    .faq-content .faq-question {
        font-size: 18px;
        line-height: 24px;
    }
    .faq-content-wrapper {
        padding: 12px 0;
    }
}
@media (max-width: 600px) {
    .faq-container {
        padding: 30px 20px;
        gap: 10px;
    }
    .faq-container-wrapper .faqTitle {
        font-size: 24px;
        line-height: 30px;
    }
    .faq-container-wrapper {
        gap: 10px;
    }
    .faq-content {
        padding-top: 0;
    } 
    .faq-content .faq-question {
        font-size: 16px;
        line-height: 20px;
        padding-right: 5px;
    }
    .faq-content-wrapper .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
    }
    .faq-answer {
        font-size: 14px !important;
        padding-top: 0 !important;
    }
    .faq-content-wrapper {
        padding: 0;
    }
    .subscriptionBlock {
        padding: 0 20px 30px;
    }
}

