.booking-summary {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 1rem auto;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 22px rgba(0, 0, 0, 0.15);
}



.booking-summary .salon-info {
  display: flex;
  gap: 10px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 6px 13px rgba(0, 0, 0, 0.12);
  color: #4b5563;
}
.booking-summary .salon-info h6 {
  font-size: 0.8rem;
}

.booking-summary p.salon-type {
  opacity: 40%;
}

.booking-summary .salon-image {
  width: 40%;
  object-fit: cover;
  border-radius: 15px 0px 0px 15px;
  height: 100px;
}

.booking-summary .salon-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  /* padding-left: 0px;
  width: 100%;
  margin-top: 1rem; */
}

.booking-summary .salon-header {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.booking-summary .salon-details .salon-name {
  margin-top: 0px;
  font-size: 12px;
  margin-left: 0px;
  line-height: 15px;
}

.booking-summary .salon-name {
  font-weight: 600;
  font-size: 0.875rem;
}

.booking-summary .salon-rating {
  display: flex;
  align-items: center;
  margin: 0;
  padding-right: 10px;
}

.booking-summary .rating-icon {
  width: 12px;
  object-fit: contain;
  margin-left: 5px;
}

.booking-summary .salon-type {
  /* font-size: 0.875rem; */
  font-size: 11px;
}

.booking-summary .salon-location {
  display: flex;
  align-items: center;
}
.booking-summary .salon-location p {
  margin-left: 5px;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
 text-overflow: ellipsis;
}

.booking-summary .location-icon {
  width: 11px;
  object-fit: contain;
  fill: #fbbf24;
}

.booking-summary .date-time {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  flex-direction: column;
}
.booking-summary .date-time p {
  margin-left: 10px;
  opacity: 100%;
  color: black;
}

.booking-summary .date {
  display: flex;
  align-items: center;
}
.booking-summary .time {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.booking-summary .date-icon,
.booking-summary .time-icon {
  width: 20px;
  object-fit: contain;
}

.booking-summary .summary-title {
  margin-top: 1.5rem;
  font-weight: 600;
  text-align: left;
  color: black;
}
.booking-summary .summary-title {
  margin-top: 30px;
}

.booking-summary hr.divider {
  border-color: #ccc;
  margin: 15px 0px;
}

.booking-summary .divider {
  margin-top: 1rem;
  height: 1px;
  background-color: #4b5563;
}

.booking-summary .services {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 1rem;
  color: #4b5563;
}

.booking-summary .service-detail {
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
}

.booking-summary .service-names {
  display: flex;
  flex-direction: column;
}

.booking-summary .service-prices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
}
.booking-summary .service-prices p {
  margin: 15px 0px;
}

.booking-summary .price {
  margin-top: 2rem;
}

.booking-summary .total {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-weight: bold;
}
.booking-summary button:hover{
  background-color: #2e144e;
}

.booking-summary .continue-button {
  margin-top: 2rem;
  padding: 1.25rem 4rem;
  background-color: #3b82f6;
  color: white;
}
.booking-summary .continue-button.css-19xj9t7-MuiButtonBase-root-MuiButton-root {
  text-transform: capitalize;
}

/* .conf img {
  background-color: #3b82f6;
  padding: 30px 25px;
  border-radius: 50px;
} */
.conf .confirmed {
  padding: 59px 50px;
  border-radius: 20px;
}

.confirmed button:hover{
  background-color: #2e144e;
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .booking-summary {
    padding: 15px;
  }
  .salon-header {
    gap: 0px;
  }
  .salon-info h6 {
    font-size: 8px;
  }
  .salon-info {
    gap: 5px;
  }
  .booking-summary .salon-location p {
    font-size: 8px;
  }
  p.salon-type {
    font-size: 11px;
  }
  .salon-rating {
    margin-bottom: 0px;
  }
  .salon-location {
    margin-top: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .booking-summary {
    padding: 15px;
  }
  .salon-header {
    gap: 0px;
  }
  .salon-details .salon-name {
    font-size: 7px;
    min-height: auto;
    width: 100px;
  }
  .salon-info h6 {
    font-size: 5px;
  }
  .salon-info {
    gap: 5px;
  }
  .salon-location p {
    font-size: 8px;
  }
  p.salon-type {
    font-size: 8px;
  }
  .salon-rating {
    margin-bottom: 0px;
    margin-left: -5px;
  }
  .salon-location {
    margin-top: 5px;
  }
}


@media (max-width: 1600px) {
  .mainBox h1, .team-selection h2.title, .appointment-booking h4{
    font-size: 1.6146rem;    
  }
  .booking-summary .salon-image{
    width: 30%;
  }

  /* .itemList h5{
    font-size: 1.3542rem;
    font-weight: 417;
    margin-top: 0px;
  } */
  /* .bookingTab .itemList p{
    font-size: 0.8333rem;
    font-weight: 417;
    opacity: 60%;
    margin-top: 0px;
  } */
  .booking-summary .rating-icon{
    margin-left: 2px;
  }
  .booking-summary .salon-info h6 {
    font-size: 0.6rem;
  }
  .booking-summary .salon-details .salon-name{
    font-size: 0.6250rem;
    font-weight: 500;
  }
  .booking-summary .salon-location p{
    font-size: 0.6208rem;    
  }
  .team-member h5.special-name{
    font-size: 1.0417rem;
    font-weight: 500;
  }
  .team-member p.description{
    font-size: 0.8333rem;    
  }
  /* .summary h5{
    font-size: 1.1458rem;
    font-weight: 500;
  } */
  .summary button{
    font-size: 1.0417rem;    
  }
}

@media (max-width: 1440px) {
  .mainBox h1, .team-selection h2.title, .appointment-booking h4{
    font-size: 1.4531rem;    
  }

  .booking-summary .salon-image{
    width: 35%;
  }

  .booking-summary .itemList h5{
    font-size: 1.2188rem;    
  }
  .booking-summary .salon-info h6 {
    font-size: 0.4rem;
  }
  .booking-summary .rating-icon {
    width: 11px;
    object-fit: contain;
    margin-left: 2px;
    margin-right: 5px;
  }


  .bookingTab .itemList p{
   font-size: 15px;
  }
  .booking-summary .salon-details .salon-name{
    font-size: 0.6625rem;    
  }
  .booking-summary .salon-location p{
    font-size: 0.5688rem;    
  }
  .team-member h5.special-name{
    font-size: 0.9375rem;    
  }
  .team-member p.description{
    font-size: 0.7500rem;    
    margin-top: 0px;
  }
  .summary h5{
    font-size: 20px;
  }
  .summary button{
    font-size: 0.9375rem;    
  }
  .professionalName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}
}

@media (max-width: 1366px) {
  /* .mainBox h1, .team-selection h2.title, .appointment-booking h4{
    font-size: 1.3785rem;
    font-weight: 498;
  }
  .itemList h5{
    font-size: 1.1561rem;
    font-weight: 417;
  }
  .bookingTab .itemList p{
    font-size: 0.7115rem;
    font-weight: 375;
    opacity: 60%;
    margin-top: 0px;
  }
  .booking-summary .salon-details .salon-name{
    font-size: 0.5336rem;
    font-weight: 427;
  }
  .booking-summary .salon-info h6 {
    font-size: 0.4rem;
  }
  .booking-summary .salon-location p{
    font-size: 0.5688rem;
    font-weight: 375;
  }
  .team-member h5.special-name{
    font-size: 0.8893rem;
    font-weight: 427;
  }
  .team-member p.description{
    font-size: 0.7115rem;
    font-weight: 356;
    margin-top: 0px;
  }
  .summary h5{
    font-size: 0.9783rem;
    font-weight: 427;
  }
  .summary button{
    font-size: 0.8893rem;
    font-weight: 356;
  } */
}

@media (max-width: 1280px) {
  /* .mainBox h1, .team-selection h2.title, .appointment-booking h4{
    font-size: 1.2917rem;
    font-weight: 467;
  }
  .itemList h5{
    font-size: 0.8rem;
    font-weight: 417;
  }
  .bookingTab .itemList p{
    font-size: 0.7115rem;
    font-weight: 375;
    opacity: 60%;
    margin-top: 0px;
  }
  .booking-summary .salon-details .salon-name{
    font-size: 0.7000rem;
    font-weight: 400;
  }
  .booking-summary .salon-info h6 {
    font-size: 0.6rem;
  }
  .booking-summary .salon-location p{
    font-size: 0.5167rem;
    font-weight: 333;
    padding-right: 5px;
  }
  .team-member h5.special-name{
    font-size: 0.8893rem;
    font-weight: 427;
  }
  .team-member p.description{
    font-size: 0.6667rem;
    font-weight: 333;
    margin-top: 0px;
  }
  .summary h5{
    font-size: 0.9167rem;
    font-weight: 400;
  }
  .summary button{
    font-size: 0.8333rem;
    font-weight: 356;
  } */
}

@media (max-width: 1200px) {
  /* .mainBox h1, .team-selection h2.title, .appointment-booking h4{
    font-size: 1.4333rem;
    font-weight: 438;
  }
  .date-selector-container .icon-image{
    width: 30px;
  }
  .itemList h5{
    font-size: 1.0156rem;
    font-weight: 417;
  }
  .bookingTab .itemList p{
    font-size: 0.7115rem;
    font-weight: 375;
    opacity: 60%;
    margin-top: 0px;
  }
  .booking-summary .salon-details .salon-name{
    font-size: 0.7rem;
    font-weight: 375;
  }
  .booking-summary .salon-info h6 {
    font-size: 0.4rem;
  }
  .team-member h5.special-name{
    font-size: 0.6667rem;
    font-weight: 438;
  }
  .team-member p.description{
    font-size: 0.6250rem;
    font-weight: 313;
    margin-top: 0px;
  }
  .summary h5{
    font-size: 0.8594rem;
    font-weight: 575;
  }
  .summary button{
    font-size: 1rem;
    font-weight: 313;
  }
  .booking-summary .salon-image {
    width: 35%;
    object-fit: cover;
    border-radius: 10px 0px 0px 10px;
    height: 80px;
  }
  .booking-summary .salon-info, .booking-summary .salon-header{
    gap: 5px;
  } */
}

@media (max-width: 1199px) {
  /* .mainBox h1, .team-selection h2.title{
    font-size: 1.0333rem;
    font-weight: 438;
  }
  .itemList h5{
    font-size: 0.8667rem;
    font-weight: 417;
  }
  .bookingTab .itemList p{
    font-size: 0.7115rem;
    font-weight: 375;
    opacity: 60%;
    margin-top: 0px;
  }
  .serviceContainerGrid .booking-summary{
    padding: 8px;
  }
  .date-selector-container .icon-image{
    width: 25px;
  }
  .booking-summary .salon-details .salon-name{
    font-size: 0.45rem;
    font-weight: 475;
    line-height: 10px;
    margin-left: 2px;
  }
  .booking-summary
  .booking-summary .summary p{
    font-size: 11px;
  }
  .booking-summary .summary span{
    font-size: 0.5rem;
  }

  .booking-summary .salon-image{
    width: 35%;
    height: 70px;
  }
  .booking-summary .salon-info h6 {
    font-size: 0.5rem;
  }
  .booking-summary .summaryContainer{
    margin-top: 0px;
  }
  .booking-summary .summaryContainer .summary8{
    padding-top: 0px;
  }
  .booking-summary .summary hr{
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .booking-summary .summary .summaryTotal{
    margin-top: 0px;
  }

  .booking-summary p.salon-type{
    font-size: 8px;
    opacity: 70%;
    padding-left: 2px;
  }

  .summary p{
    font-size: 11px;
  }
  .summary span{
    font-size: 0.65rem;
  }

  
  .booking-summary .salon-location p{
    padding-right: 10px;
  }

  .team-member p.description{
    font-size: 0.5333rem;
    font-weight: 267;
    margin-top: 0px;
  }
  .summary h5{
    font-size: 0.7333rem;
    font-weight: 520;
  }
  .summary button{
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1;
    padding: 11px 8px;
    margin-top: 15px;
  } */
  .summary h5 {
    font-size: 18px;
  }
  .card h5.special-name {
     font-size: 14px;
  }
  .team-member p.description {
    font-size: 14px;
  }
}

@media (max-width: 899px) {
  /* .booking-summary .salon-details .salon-name{
    font-size:0.6rem;
  }
  .booking-summary .salon-details{
    gap: 2px;
  } */
}

@media (max-width: 600px) {
/* .booking-summary .salon-header{
  flex-direction: column;
  gap:0;
}
.booking-summary .salon-details .salon-name{
  width: 100%;
}
.booking-summary .salon-info h6{
  font-size: 0.8rem;
  margin: 1px 0px;
}
.mainBox h1, .team-selection h2.title{
  font-size: 1.1rem;
}
.itemList .servicePrice h4{
  font-size: 15px;
}
.team-member .card{
  padding-bottom: 20px;
  margin-bottom: 0px;
  padding-top: 18px;
}
.card h5.special-name{
  font-size: 15px;
}
.summary h5 {
  font-size: 1.25rem;
  font-weight: 600;
}
.mainBox .booking-summary button{
  background-color: #482E77;
  font-size: 0.9rem;
  color: #fff;
}
.serviceCardLeft{
  width: 100%;
  float: left;
}
.serviceCardLeft h6{
  width: auto;
}
.serviceCardRight{
  width: 100%;
  float: left;
}
.professionalContainer .MuiPaper-root{
  display: flex;
  flex-direction: column;
}
.professionalContainer h3{
  margin-top: 0px;
}
.team-selection h2{
  margin-bottom: 20px;
}

.date-selector-container .navigation-button.left{
  justify-content: flex-start;
}
.date-selector-container .navigation-button.right{
  justify-content: flex-end;
  padding-right: 0px;
}
.bookingTime .time-selector button{
  width: 46%;
}
.firstVisit h4{
  font-size: 22px;
}
.confirmed button{
  width: 100%;
} */

@media (max-width: 600px) {
  .bookingTab .itemList p {
    font-size: 12px;
    text-align: center;
  }
  .summary {
    order: -1;
    margin-top: 0 !important;
    margin-bottom: 15px;
  }
  .card h5.special-name {
    font-size: 14px;
 }
  .team-member p.description {
    font-size: 12px;
  }
}
}


