.otp.login-form h1 {
  margin-top: 31px;
}

.otp p.login-form-subtitle {
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 400;
}
.otp h4 {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
}
.otp h4 button:disabled{
  color: gray;
  cursor: not-allowed;
  pointer-events: none;
}
.otp h4 button{
  background: none;
    border: none;
    padding: 0px;
    margin: 0px;
    width: auto;
    font-size: 17px;
    font-weight: 600;
    padding-left: 5px;
    color: #4e297b;
    font-family: 'Poppins';
    line-height: 19px;
}
.otp h4 button:hover{
  background-color: transparent;
  color: #231F20;
}

.otp .timer{
  margin-top: 10px;
}

.otp button {
  margin-bottom: 0;
}
.otpContent {
  max-width: 450px;
  margin: auto;
  position: relative;
}
.otp h4 span {
  text-decoration: none;
  cursor: pointer;
  color: #4e297b;
  font-weight: 600;
}

.otp a {
  text-decoration: none;
  color: #0178fa;
}
.otp .text-danger {
  margin-top: 5px;
  margin-left: 6px;  
  float: none;
}

.otp .login-form-container {
  max-width: 700px !important;
  height: calc(100% - 100px);
  padding: 20px !important;
  display: flex !important;
  position: relative;
  margin: auto;
  box-shadow: none;
  padding: 0 !important;
  background: #4e297b;
}
.otp .login-form {
  min-height: 100%;
  background: #fff;
}

.otp_form .otp .otp-form-button {
  margin-top: 20px;
  text-transform: capitalize;
  padding: 20px 15px;
  border-radius: 10px;
  width: 100%;
  font-size: 18px;
  line-height: 21px;
  font-weight: 300;
  background: #4e297b;
  color: #fff;  
  position: relative;  
}
/* .otp_form .otp .otp-form-button .btnText {
  display: flex;
  align-items: center;  
} */
.otp_form .otp .otp-form-button .loaderSpinner {
  position: absolute;
  left: calc(50% + 50px);
  transform: translateX(-50%);
}

.otp_form .otp .otp-form-button:hover {
  background: #2e144e;
}

.otp_form .otp .otpBtnDisable {
  background: rgba(78, 41, 123, 0.4);
  cursor: none;
}

.otp-input input {
  width: 50px !important;
  text-align: center;
  height: 60px;
  margin: 0px 0.5rem;
  border-radius: 10px;
  border: 1px solid #4e297b;
  font-size: 18px;
}
.otp-input input:focus-visible{
  border: 1px solid #4e297b;
}

@media (max-width: 1199px) {
  .otpContent {
    width: 100%;
  }
  .otp-input input {
    width: 50px !important;    
  }
  .otp p.login-form-subtitle {
    margin-bottom: 30px;
  }
  .otp h4 {
    margin: 30px 0;
    font-size: 16px;
    line-height: 24px;
  }
  .otp .text-danger {
    left: 15px;
  }
}

@media (max-width: 899px) {
  .otp p.login-form-subtitle {
    margin-bottom: 45px;
  }
  .otp .text-danger {
    left: 35px;
  }
}

@media (max-width: 600px) {
  .otp .login-form {
    min-height: auto;
  }
  .otp-input input {
    width: 40px !important;
    height: 50px;
    margin: 0 0.35rem;
  }
  .otpContent {
    margin: 20px 0 0;
  }
  .otp_form .otp .otp-form-button {
    margin-top: 0;
  }
  .otp .text-danger {
    left: 0;
  }
}
