.appointment-manager .appointment-card {
  padding-right: 20px;
  margin-top: 14px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 24px;
  border: 1px solid #4a4a4a;
  box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
  width: 560px;
  border-left: none;
}



.appointment-manager .card-content {
  display: flex;
  gap: 20px;
}

@media (max-width: 960px) {
  .card-content {
    flex-direction: column;
  }
}

.appointment-manager .image-container {
  width: 24%;
}



.appointment-manager .card-image {
  object-fit: cover;
  width: 145px;
  aspect-ratio: 1.11;
  border-radius: 24px 0px 0px 24px;
  height: 100%;
}

.appointment-manager .details-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 62%;
}

@media (max-width: 960px) {
  .details-container {
    margin-left: 0;
    width: 100%;
  }
}

.appointment-manager .card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: black
}

.appointment-manager .card-date,
.appointment-manager .card-location {
  margin-top: 10px;
  color: #4a4a4a;
  font-size: 0.875rem;
}

.appointment-manager .card-location {
  margin-top: 20px;
}


