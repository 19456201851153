.service-navigation {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  font-size: 16px;
}
span.rightArrow {
  margin: 0 5px;
}

.service-navigation__image {
  object-fit: contain;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
}

.service-navigation button {
  padding: 0px;
  min-width: 0px;
}

.service-navigation button:hover {
  background-color: transparent;
}

.service-navigation__text {
  flex: auto;
  margin: auto 0;
}

.service-navigation__highlight {
  color: #000; /* Equivalent to text-stone-800 */
  opacity: 50%;
}

/* Responsive design */
@media (max-width: 600px) {
  .service-navigation {
    align-items: center;
  }

  .service-navigation__image {
    margin-bottom: 8px;
  }
  .service-navigation__text {
    flex-wrap: wrap;
    padding-right: 20px;
  }
  .service-navigation__text p {
    font-size: 14px;
  }
}
