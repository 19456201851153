/* Main container styling */
.main-container {
  margin-top: 20px;
  max-width: max-content;
}

#root .reviews-container {
  padding: 2rem 3rem;
  max-width: 100%;
}

/* Testimonial card */
.reviews .testimonial-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #d3d2d2;
  border-radius: 16px;
  padding: 15px 20px;
  transition: transform 0.3s ease-in-out;
}


/* Testimonial content */
.reviews .testimonial-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Testimonial header */
.reviews .testimonial-header {
  display: flex;
  gap: 16px;
  align-items: center;
}
.reviews .testimonial-header span{
  font-size: 20px;
}


.reviews .testimonial-name {
  font-weight: 600;
  font-size: 22px;
  line-height: 28.6px;
}

.reviews .testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

/* Testimonial details */
.reviews .testimonial-date {
  color: #231f20;
  opacity: 70%;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  padding-top: 10px;
}

.reviews .testimonial-text {
  margin-top: 20px;
  line-height: 25px;
  font-size: 16px;
  font-weight: 400;
  color: #231f20;
}

.reviews .reviews-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0 auto;
  padding: 50px 0px 0px 0px;
  border-radius: 0;
}

.reviews .reviews-title {
  margin: auto 0;
  font-size: 2rem; /* Adjusted for responsiveness */
  font-weight: bold;
  color: black;
}

.reviews .ratingsReviews{
  font-size: 1rem;
  font-weight: 600;
}

.reviews .sort-button {
  align-items: center;
  gap: 4px;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background-color: #292524;
  border-radius: 500px;
  text-transform: none;
  float: right;
  text-align: right;
}

.sort-button .sort-icon {
  width: 20px;
  object-fit: contain;
  aspect-ratio: 2;
  flex-shrink: 0;
}




/* Responsive Design */
@media (max-width: 599px) {
  .testimonial-card {
    padding: 15px;
  }

  .testimonial-image {
    width: 60px;
    height: 60px;
  }
  
}

@media (max-width: 1600px) {
  .breadcrumb-items span{
  font-size: 0.8250rem;
  font-weight: 417;
  }
  
  .reviews .reviews-title{
    font-size: 1.6146rem;
    font-weight: 583;
  }
  
  .testimonial-header span{
    font-size: 1.7rem;
  }
  
  
  .reviews .sort-button{
    padding: 7px 15px;
    font-size: 0.9rem;
  }
  .reviews .sort-button img{
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 1440px) {
  .breadcrumb-items span{
    font-size: 0.7625rem;
    font-weight: 417;
    }
     
    .reviews .reviews-title{
      font-size: 1.4531rem;
      font-weight: 525;
    }  
    
    .testimonial-header span{
      font-size: 1.5rem;
    }
    
    
    .score-section svg{
      font-size: 35px;
    }
    .reviews .sort-button{
      padding: 7px 15px;
      font-size: 0.9rem;
    }
    .reviews .sort-button img{
      width: 15px;
      height: 15px;
    }
}
@media (max-width: 1366px) {
  .breadcrumb-items span{
    font-size: 0.7336rem;
    font-weight: 417;
    }
  
  .reviews .reviews-title{
    font-size: 1.3785rem;
    font-weight: 498;
  }
  
  .testimonial-header span{
    font-size: 1.3rem;
  }
  
  
  
  
  .rating-box .score-section svg{
    font-size: 30px;
  }
  .reviews .sort-button{
    padding: 7px 15px;
    font-size: 0.9rem;
  }
  .reviews .sort-button img{
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 1280px) {
  
  .breadcrumb-items span{
    font-size: 0.7336rem;
  }
  
 
  
  .testimonial-header span{
    font-size: 1.1rem;
  }
  
  
  
 
  .reviews .sort-button{
    padding: 7px 15px;
    font-size: 0.9rem;
  }
  .reviews .sort-button img{
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 1200px) {
  .breadcrumb-items span{
    font-size: 0.6667rem;
    font-weight: 467;
    }
  
  .reviews .reviews-title{
    font-size: 1.2109rem;
    font-weight: 438;
  }
  
  .testimonial-header span{
    font-size: 0.9rem;
  }
  
  
  .rating-box .score-section svg{
    font-size: 25px;
  }
  .reviews .sort-button{
    padding: 5px 15px;
    font-size: 0.8rem;
  }
  .reviews .sort-button img{
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 1024px) {
  .breadcrumb-items span{
    font-size: 0.7336rem;
    font-weight: 417;
    }
  
  .reviews .reviews-title{
    font-size: 1.0333rem;
    font-weight: 373;
  }
  
  .testimonial-header span{
    font-size: 0.9rem;
  }
}