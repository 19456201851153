/* General styles for reviews page */
#root .reviews-container {
  padding: 31px 100px 0rem 100px;
  max-width: 100%;
}

.reviews-container .reviews-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.reviews-container .mainContainer{
  display: flex;
  justify-content: space-between;
}

.reviews-container .sort-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1976d2;
  color: white;
  font-size: 1rem;
  text-transform: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.reviews-section .reviewFilterButton{
  background-color: #4E297B;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  text-transform: none;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  margin-top: 1rem;
  font-weight: 400;
}
.reviews-section .reviewFilterButton:hover{
  background-color: #2e144e;
}

.sort-icon {
  margin-left: 0.5rem;
  width: 1rem;
}

.reviews-section {
  margin-bottom: 2rem;
}

/* Sidebar (Rating box) */
.rating-box {
  padding: 30px 30px 0px 30px;
  border: 1px solid #ddd;
  border-radius: 15px;
}

.rating-stars {
  font-size: 2rem;
}

.ratings-summary {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.reviews-container .divider {
  margin: 30px 0px 30px 0px;
  border: none;
  border-top: 1px solid #d3d2d2;
}

/* Responsive adjustments */

@media (max-width: 1280px) {
  .reviews .reviews-section{
    padding: 0px 0px 0px 0px;
  }
  .reviews .reviews-title{
    font-size: 26px;
    margin-bottom: 0px;
  }
  .rating-box{
    padding: 30px 30px 10px 30px;
  }
}

@media (max-width: 1199px) {
  #root .reviews-container{
    padding: 10px 70px;
  }
  #root .reviews-container > div{ 
    margin-top: 0px;
  }
  .reviews-section .reviewFilterButton{
    margin-top: 0px;
  }
  .reviews-container .reviews-title{
    margin-bottom: 0px;
    font-size: 24px;
  }
  .sort-button {
    width: 100%;
  }

  .reviews-title {
    font-size: 1.5rem;
  }

  .rating-box{
    float: none;
    width: 100%;
    margin-bottom: 20px;
    padding: 15px;
  }
  .rating-box .third{
    padding-right: 0px;
  }
  .rating-box .third hr {
    min-width: 175px;
  }

  .rating-stars {
    font-size: 1.5rem;
  }

  .ratings-summary {
    font-size: 1rem;
  }
}

@media (max-width: 899px) {
  
  #root .reviews-container{
    padding: 10px 50px;
  }
  #root .reviews-container > div{ 
    margin-top: 0px;
  }
  .reviews-section .reviewFilterButton{
    margin-top: 0px;
  }
  .reviews-container .reviews-title{
    margin-bottom: 0px;
  }
  .sort-button {
    width: 100%;
  }

  .reviews-title {
    font-size: 1.5rem;
  }

  .rating-box{
    float: none;
    width: 100%;
    margin-bottom: 20px;
  }
  .rating-box .third hr {
    min-width: 456px;
  }

  .rating-stars {
    font-size: 1.5rem;
  }

  .ratings-summary {
    font-size: 1rem;
  }
}
@media (max-width: 599px) {
  #root .reviews-container{
    padding: 0px 20px 0px 20px;
  }
  .rating-box{
    float: none;
    width: 100%;
    margin-bottom: 20px;
    padding: 15px 20px 0px 20px;
  }
  .rating-box .third hr {
    min-width: 150px;
  }
  .rating-box .divider{
    margin: 30px 0px 30px 0px;
  }
  .reviews-container .reviews-title{
    margin: 0px;
    font-size: 22px;
    
  }
  .reviews-section .reviewFilterButton{
    margin-top: 0px;
    font-size: 14px;
  }
  .reviews-container .MuiGrid-container .testimonialContainer:first-child{
    padding-top: 5px;
  }
  .reviews-container .MuiGrid-container .testimonialContainer{
    padding-top: 20px;
  }
  .reviews .testimonial-name{
    font-size: 18px;
  }
  .score-box .score-text{
    font-size: 18px;
  }
  .rating-box .first{
    padding-right: 0px;
  }
  .rating-box .second {
    padding-right: 0px;
  }
  .third .image-container {
    width: 100%;
    margin-top: 10px;
  }
  .rating-box span.rating-stars {
    font-size: 30px;
  }
  .rating-box .ratings-summary{
    padding-top: 10px;
    font-size: 16px;
  }
  .reviews .testimonial-text, .reviews .testimonial-date{
    font-size: 14px;
  }
  .score-card{
    margin-bottom: 20px;
  }
  .reviews .reviews-section{
    padding: 30px 0px 0px 0px;
  }
  .reviews .testimonial-date{
    padding-top: 0px;
  }
  .reviews .testimonial-text{
    margin-top: 10px;
  }
  #root .reviews-container .MuiGrid-grid-xl-1{
    padding-top: 10px;
  }
}