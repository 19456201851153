.MuiPaper-root.plan_card {
  min-height: 100%;
  box-sizing: border-box;
  padding: 0px;
  background-color: #f6f6f6;
  box-shadow: none;  
  width: 100%;
}

.plan-type {    
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;  
  background: #4e297b;
  color: #fff;  
  text-transform: uppercase;
  display: inline-block;
}
.MuiCardContent-root.plan-card-wrapper {
  padding: 20px !important;
  border-radius: 10px;
}

#package {
  font-size: 18px;
  line-height: 26px;
  color: #636363;
}
.MuiPaper-root.plan_card .plan-card-wrapper .plan_amount {
  margin-top: 30px;    
  font-size: 40px;
  line-height: 60px;
  font-weight: 800;
  color: #333333;
  margin-bottom: 0;
}

.MuiPaper-root.plan_card .plan-card-wrapper .plan_title {
  margin: 30px 0;  
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #333333;
}

.MuiPaper-root.plan_card .plan-card-wrapper .plan_amount .planBy {  
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #636363;
  margin-left: 6px;
}

.MuiPaper-root.plan_card .plan-card-wrapper .plan_amount .package {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #636363;
}

.MuiPaper-root.plan_card .plan-card-wrapper .add-plan-button {
  color: #ffffff;
  width: 100%;  
  background: #4e297b;
  border: 1px solid #4e297b;
  border-radius: 10px;  
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 14px;
  text-align: center;
}

.MuiPaper-root.plan_card .plan-card-wrapper .add-plan-button:hover{
  background-color: #2e144e;
}

.MuiPaper-root.plan_card .plan-card-wrapper .setupfee-btn {
  padding: 10px 15px;
  border-radius: 10px;
  margin-top: 16px;
  background: rgba(78, 41, 123, 0.1);  
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #4e297b;
  text-transform: capitalize;
}

.MuiPaper-root.plan_card .plan-card-wrapper .setupfee-btn span {
  font-weight: 600;
  padding-right: 6px;
  font-size: 18px;
  line-height: 26px;
}
.plan_description_container.MuiBox-root { 
  margin-top: 30px;
  margin-bottom: 0;
}

.MuiPaper-root.plan_card
  .plan-card-wrapper
  .plan_description_container
  .plan_description {  
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #333333;
}


button.priceSubmit{
  background-color: #4e297b;
}
button.priceSubmit:hover{
  background-color: #2e144e;
}
button.priceCancel{
  color: #4e297b;
}

/* .plan-card{
    border-radius: 10px !important;
    background-color: #F5F5F5 !important;
    padding: 10px !important;
    height: 100%;
    box-shadow: none !important;
}

.plan-card-wrapper{
    display: flex;
    flex-direction: column !important;
    gap: 20px !important;
}

.plan-type{
    background-color: #0178FA !important;
    padding: 10px 20px 10px 20px !important;
    border-radius: 10px 10px 10px 10px !important;
    color: #F4F4F4 !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    width: fit-content !important;
}

#package {
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #7A7A7A;
}

.plan-button{
    background-color: #4E297B;
    padding: 10px 20px 10px 20px !important;
    border-radius: 10px !important;
    color: #F4F4F4 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-transform: none !important;
}

.free-plan-box{
    display: flex !important;
    align-items: start !important;
    justify-content: center !important;
}

.free-plan{
    background-color: #0178FA1A !important;
    padding: 10px 20px 10px 20px !important;
    border-radius: 10px !important;
    color: #0178FA !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    display: flex !important;
    align-items: start !important;
    justify-content: center !important;
}

.free-plan-wrapper{
    color: #0178FA !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.plan-description {
    color: #333333 !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
}

.feature-item{
    color: #333333 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 30px !important;
} */
