.appointment-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 14px;
  background-color: white;
  padding: 0px 100px;
}

.appointment-page main {
  width: 100%;
  max-width: 100%;
  padding: 0px;
  margin-top: 0px;
  padding-top: 50px;
}

.appointment-page main > div {
  margin-top: 0px;
}

.main-content {
  z-index: 10;
  margin-top: 3rem;
  margin-right: 24px;
  width: 100%;
  max-width: 1550px;
}

/*
.appointment-page section:first-child {border: 2px solid;} 
*/

.appointment-page .grid-container {
  margin-bottom: 20px;
  justify-content: space-between;
}

.appointment-page .MuiTabs-indicator {
  border: 1px solid black;
  display: none;
}

.appointment-page .image-container {
  width: 145px;
}

.appointment-page .rightAppointment {
  display: inline;
  margin-left: 0px;
}
.appointment-page .rightAppointment > div {
  padding-top: 0px;
}

.appointment-page h4 {
  font-size: 31px;
  line-height: 37.2px;
  font-weight: 700;
  color: #020202;
}

.appointment-page .latestAppointmentTitle {
  font-size: 25px;
  line-height: 37.2px;
  font-weight: 700;
  color: #020202;
  margin: 20px 0;
}

.latestAppointment {
  border: 1px solid black;
  padding: 70px 20px;
  border-radius: 20px;
}
.latestAppointment h1 {
  font-size: 30px;
  font-weight: 600;
  padding-top: 30px;
  line-height: 34.8px;
}

.latestAppointment p {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  padding-top: 20px;
}

.latestAppointment .searchSalon {
  background-color: #4e297b;
  color: white;
  border-radius: 5px;
  margin-top: 25px;
  text-transform: capitalize;
  font-weight: 300;
}
.latestAppointment .searchSalon:hover {
  background-color: #2e144e;
}

.appointment-section {
  margin-top: 16px;
  width: 100%;
}

.appointment-title {
  font-size: 2rem;
  font-weight: bold;
  line-height: tight;
  color: black;
}

.details-container {
  margin-top: 10px;
  width: 100%;
}

@media (max-width: 1600px) {
  .appointment-page h4 {
    font-size: 1.6146rem;
  }
  .appointment-page main {
    width: 100%;
  }
  .appointment-page .title {
    font-size: 1.5625rem;
    font-weight: 500;
  }
  .appointment-page .content-container .title {
    font-size: 0.9375rem;
    font-weight: 500;
  }
  .appointment-page .content-container p.date {
    font-size: 0.7292rem;
    font-weight: 333;
  }
  .appointment-page .content-container p.price {
    font-size: 0.7292rem;
    font-weight: 333;
    opacity: 60%;
  }
  .appointment-page .content-container .book-again {
    font-size: 0.7292rem;
    font-weight: 333;
  }
  .appointment-page .appointment-title {
    font-size: 1.5625rem;
  }
  .appointment-page .image-container {
    width: 30%;
  }
  .appointment-page .tabs button {
    font-size: 1.1rem;
  }

  .appointment-page .appointment-date {
    font-size: 1.25rem;
  }
  .appointment-page .status-container p {
    font-size: 1.0417rem;
    font-weight: 417;
  }
  .getting-there-container h6,
  .manage-appointment-container h6,
  .venue-details-container h6 {
    font-size: 1.3542rem;
  }
  .getting-there-container p,
  .manage-appointment-container p,
  .venue-details-container p {
    font-size: 0.9375rem;
    font-weight: 317;
  }
}

@media (max-width: 1440px) {
  .appointment-page h4 {
    font-size: 1.6146rem;
  }
  .appointment-page main {
    width: 100%;
  }
  .appointment-page .title {
    font-size: 1.4063rem;
    font-weight: 450;
  }
  .appointment-page .content-container .title {
    font-size: 0.8438rem;
    font-weight: 450;
  }
  .appointment-page .content-container p.date {
    font-size: 0.6563rem;
    font-weight: 333;
  }
  .appointment-page .content-container p.price {
    font-size: 0.6563rem;
    font-weight: 333;
    opacity: 60%;
  }
  .appointment-page .content-container .book-again {
    font-size: 0.6563rem;
    font-weight: 333;
  }
  .appointment-page .image-container {
    width: 30%;
  }
  .appointment-page .tabs button {
    font-size: 1.1rem;
  }

  .appointment-page .appointment-title {
    font-size: 1.4063rem;
  }
  .appointment-page .content-container {
    padding-top: 0.5rem;
  }
  .appointment-page .appointment-date {
    font-size: 1.125rem;
  }
  .appointment-page .status-container p {
    font-size: 0.9375rem;
    font-weight: 375;
  }
  .getting-there-container h6,
  .manage-appointment-container h6,
  .venue-details-container h6 {
    font-size: 1.2188rem;
  }
  .getting-there-container p,
  .manage-appointment-container p,
  .venue-details-container p {
    font-size: 0.8438rem;
  }
}

@media (max-width: 1366px) {
  .appointment-page h4 {
    font-size: 1.3785rem;
  }
  .appointment-page .title {
    font-size: 1.334rem;
    font-weight: 427;
  }

  .appointment-page .image-container {
    width: 30%;
  }
  .appointment-page .tabs button {
    font-size: 1.1rem;
  }

  .appointment-page .content-container .title {
    font-size: 0.8004rem;
    font-weight: 427;
  }
  .appointment-page .content-container p.date {
    font-size: 0.6225rem;
    font-weight: 333;
  }
  .appointment-page .content-container p.price {
    font-size: 0.6225rem;
    font-weight: 333;
    opacity: 60%;
  }
  .appointment-page .content-container .book-again {
    font-size: 0.6225rem;
    font-weight: 333;
  }
  .appointment-page .appointment-title {
    font-size: 1.334rem;
  }
  .appointment-page .appointment-date {
    font-size: 1.0672rem;
  }
  .appointment-page .status-container p {
    font-size: 0.8893rem;
    font-weight: 356;
    margin-top: 1px;
  }
  .status-icon {
    width: 1rem;
  }
  .getting-there-container h6,
  .manage-appointment-container h6,
  .venue-details-container h6 {
    font-size: 1.1561rem;
  }

  .manage-appointment-container h6,
  .venue-details-container h6,
  .getting-there-container h6 {
    padding-left: 10px;
  }
  .getting-there-container p,
  .manage-appointment-container p,
  .venue-details-container p {
    padding-left: 10px;
  }
}

@media (max-width: 1280px) {
  .appointment-page h4 {
    font-size: 1.2917rem;
  }
  .appointment-page .title {
    font-size: 1.25rem;
    font-weight: 400;
  }
  .appointment-page .content-container .title {
    font-size: 0.75rem;
    font-weight: 400;
  }
  .appointment-page .content-container p.date {
    font-size: 0.5833rem;
    font-weight: 333;
  }
  .appointment-page .content-container p.price {
    font-size: 0.5833rem;
    font-weight: 333;
    opacity: 60%;
  }
  .appointment-page .content-container .book-again {
    font-size: 0.5833rem;
    font-weight: 333;
  }

  .appointment-page .image-container {
    width: 30%;
  }
  .appointment-page .tabs button {
    font-size: 1rem;
  }

  .appointment-page .onlyBottom p {
    font-size: 12px;
    margin: 0px 9px;
  }

  .appointment-page .appointment-title {
    font-size: 1rem;
  }
  .appointment-page .appointment-date {
    font-size: 0.8rem;
  }
  .appointment-page .status-container p {
    font-size: 0.8333rem;
    font-weight: 333;
  }
  .getting-there-container h6,
  .manage-appointment-container h6,
  .venue-details-container h6 {
    font-size: 1rem;
  }

  .appointment-page .cancellation-policy {
    font-size: 1rem;
  }
  .appointment-manager .appointment-title {
    font-size: 1.1rem;
  }
  .appointment-manager .appointment-avatar img {
    height: 1.5rem;
    width: 1.5rem;
  }
  .appointment-manager .card-title {
    margin-top: 0.6rem;
    font-size: 1rem;
  }
}

@media (max-width: 1200px) {
  .appointment-page h4 {
    font-size: 1.2109rem;
  }
  .appointment-page main {
    width: 100%;
    padding-left: 10%;
  }
  .appointment-page .title {
    font-size: 1.1719rem;
    font-weight: 375;
  }
  .appointment-page .content-container .title {
    font-size: 0.7031rem;
    font-weight: 400;
  }
  .appointment-page .content-container p.date {
    font-size: 0.5469rem;
    font-weight: 333;
  }
  .appointment-page .content-container p.price {
    font-size: 0.5469rem;
    font-weight: 333;
    opacity: 60%;
  }
  .appointment-page .content-container .book-again {
    font-size: 0.5469rem;
    font-weight: 333;
  }

  .appointment-page .image-container {
    margin-right: 0.7rem;
  }

  .appointment-page .appointment-title {
    font-size: 1.1719rem;
  }
  .appointment-page .appointment-date {
    font-size: 0.9375rem;
  }
  .appointment-page .status-container p {
    font-size: 0.7813rem;
    font-weight: 313;
    margin-top: 2px;
  }
  .getting-there-container h6,
  .manage-appointment-container h6,
  .venue-details-container h6 {
    font-size: 1.0156rem;
  }
}

@media (max-width: 1199px) {
  .appointment-page {
    padding: 0 70px;
  }
  .appointment-page main {
    padding-left: 0px;
  }
  .appointment-page h4 {
    font-size: 24px;
  }
  .appointment-page .tabs button {
    margin-top: 0px;
  }
  .appointment-page .count-chip {
    padding: 4px 12px;
  }
  .appointment-page #full-width-tabpanel-1 > .MuiBox-root,
  .appointment-page #full-width-tabpanel-0 > .MuiBox-root {
    padding: 5px 0px 30px 0px;
  }
  #root .appointment-page .content-container {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .appointment-page .title {
    font-size: 1rem;
    font-weight: 320;
  }
  .appointment-page .content-container .title {
    font-size: 14px;
  }
  .appointment-page .content-container p.date {
    font-size: 12px;
  }
  .appointment-page .content-container p.price {
    font-size: 12px;
  }
  .appointment-page .content-container .book-again {
    font-size: 12px;
    width: auto;
  }

  .appointment-page .image-container {
    margin-right: 0px;
  }
  .appointment-page .appointment-title {
    font-size: 18px;
  }
  .appointment-page .appointment-date {
    font-size: 16px;
    margin-top: 0px;
  }
  .appointment-page .status-container {
    margin-top: 20px;
  }
  .appointment-page .status-container p {
    font-size: 16px;
  }
  .appointment-page .grid-container {
    margin-bottom: 0px;
  }
  .getting-there-container,
  .manage-appointment-container,
  .venue-details-container {
    margin: 5px 0px 5px 0px;
  }
  .getting-there-icon,
  .manage-appointment-icon,
  .venue-details-icon {
    width: 50px;
    padding: 10px;
  }
  .manage-appointment-container p,
  .venue-details-container p,
  .getting-there-container p {
    padding-top: 0px;
  }
  .appointment-page .overview-container {
    margin-top: 0px;
  }
  .appointment-page .overview-container h5 {
    font-size: 20px;
  }
  .appointment-page .serviceName {
    margin-top: 0px;
    font-size: 20px;
  }
  .appointment-page .serviceHours {
    font-size: 16px;
    margin-top: 0px;
  }
  .appointment-page .total {
    padding-top: 15px;
  }
  .appointment-page .overview-container h5.totalPrice {
    padding-top: 10px;
    font-size: 20px;
  }
  .appointment-page .cancellation-policy {
    font-size: 18px;
    margin-top: 10px;
  }
  .appointment-page .cancellationPolicyDesc {
    margin-top: 10px;
  }
  .appointment-page .bookingRef {
    font-size: 18px;
    margin-top: 15px;
  }

  .status-icon {
    width: 0.9rem;
  }
  .getting-there-container h6,
  .manage-appointment-container h6,
  .venue-details-container h6 {
    font-size: 17px;
  }
}

@media (max-width: 899px) {
  .appointment-page {
    padding: 0px 50px;
  }
  .appointment-page main {
    padding-left: 0px;
  }
  #root .appointment-page .MuiGrid-grid-xs-12 {
    padding-left: 0px;
    margin-bottom: 10px;
  }
  .appointment-page #full-width-tabpanel-0 > .MuiBox-root,
  .appointment-page #full-width-tabpanel-1 > .MuiBox-root {
    padding: 0px 0px 0px 0px;
  }
  .appointment-page h4 {
    font-size: 20px;
  }
  .appointment-page .tabs button {
    font-size: 18px;
    margin-top: 0px;
  }
  .appointment-page .top .image-container {
    width: 145px;
    height: auto;
  }
  .appointment-page main {
    padding-top: 20px;
  }
  .appointment-page .count-chip {
    padding: 4px 9px;
  }
  .appointment-page .count-chip.upcoming,
  .appointment-page .count-chip.past {
    font-size: 11px;
  }
  #root .appointment-page .content-container {
    padding: 15px 0px;
  }
  .appointment-page .content-container .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
  }
  .appointment-page .content-container p.date {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .appointment-page .content-container p.price,
  .appointment-page .content-container .book-again {
    font-size: 14px;
    font-weight: 400;
    padding-top: 0px;
  }
  .appointment-page .grid8 {
    margin-top: 0px;
  }
  .appointment-page .appointment-card {
    margin-bottom: 30px;
  }
  .appointment-page .appointment-title {
    font-size: 22px;
  }
  .appointment-page .appointment-date {
    margin-top: 5px;
    line-height: 18px;
    font-size: 18px;
  }
  .appointment-page .status-container {
    margin: 15px 0px 0px 0px;
    font-size: 20px;
    font-weight: 400;
  }
  .appointment-page .status-container p {
    line-height: 14px;
  }
  .appointment-page .right .appointment-image {
    height: 270px;
  }
  .appointment-page .overview-container {
    margin-top: 0px;
  }
  .appointment-page .overview-container h5 {
    font-size: 18px;
    margin-bottom: 0px;
  }
  .appointment-page .divider {
    margin: 10px 0;
  }
  .appointment-page .getting-there-container,
  .manage-appointment-container,
  .venue-details-container {
    margin: 0px;
    gap: 0px;
  }
  .getting-there-icon,
  .manage-appointment-icon,
  .venue-details-icon {
    width: 50px;
  }
  .manage-appointment-container p,
  .venue-details-container p,
  .getting-there-container p {
    padding-top: 0px;
    font-size: 15px;
  }
  .appointment-page .serviceName {
    font-size: 18px;
  }
  .appointment-page .serviceHours {
    margin-top: 0px;
    font-size: 16px;
  }
  .appointment-page .total {
    font-size: 22px;
    line-height: 20px;
    padding-top: 20px;
  }
  .appointment-page .overview-container h5.totalPrice {
    font-size: 18px;
    padding-top: 0px;
  }
  .appointment-page .cancellation-policy {
    margin-top: 5px;
    font-size: 22px;
  }
  .appointment-page .cancellationPolicyDesc {
    margin-top: 0px;
    font-size: 14px;
  }
  .appointment-page .bookingRef {
    font-size: 16px;
    margin-top: 0px;
  }
}

@media (max-width: 599px) {
  .appointment-page {
    padding: 0px 20px;
  }
  .appointment-page .card-container {
    flex-direction: column;
  }
  .appointment-page .top .image-container {
    width: 100%;
  }
  #root .appointment-page .content-container {
    padding: 5px 15px 15px 15px;
  }
  .appointment-page .card-container {
    gap: 0px;
  }
  .appointment-page .grid8 {
    margin-top: 10px;
  }
  .appointment-page .appointment-title {
    margin-top: 10px;
    font-size: 20px;
  }
  .appointment-page .appointment-content {
    padding: 0px 15px 0px 15px;
  }
  .appointment-page .serviceName {
    margin-top: 0px;
  }
  .appointment-page .total {
    padding-top: 10px;
    font-size: 16px;
  }
  .getting-there-icon,
  .manage-appointment-icon,
  .venue-details-icon {
    width: 35px;
    padding: 6px;
  }
  .appointment-page .cancellationPolicyDesc {
    line-height: 16px;
  }
  .appointment-page .bookingRef {
    padding-top: 5px;
  }
  .appointment-page .overview-container h5,
  .appointment-page .appointment-date,
  .appointment-page .serviceName,
  .appointment-page .overview-container h5.totalPrice {
    font-size: 16px;
  }
  .appointment-page .status-container {
    width: 100%;
  }
  .appointment-page .status-container p {
    margin: 0px auto;
    font-size: 16px;
  }
  .appointment-page .serviceHours {
    font-size: 14px;
    line-height: 18px;
  }
  .appointment-page .cancellation-policy {
    margin-top: 5px;
    font-size: 18px;
  }
}
