.privacy-policy-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.privacy-policy-container h1 {
    font-weight: bold;
}

.privacy-policy-container div {
    line-height: 1.6;
    font-size: 16px;
}

.privacy-policy-container p {
    margin-bottom: 16px;
}

.termsofservices-container li{
    list-style: none;
    margin-bottom: 15px;
}
.termsofservices-container ul.noCircle li{
    list-style: disc;
}
.termsofservices-container ul.romanNumber li{
    list-style:lower-roman;
}
