.inputWrap {
  position: relative;
}
.inputWrap input {
  height: 39px;
  font-size: 14px;
  line-height: 16px;
}
.inputWrap input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset;
}
.inputWrap .makeStyles-flagContainer-2 {
  background: #fff;
  padding: 6px;
  border-radius: 6px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
}
.inputWrap .makeStyles-flagContainer-2 img {
  width: 27px;
  height: 27px;
}
.inputWrap .makeStyles-flagContainer-2 p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}
.inputWrap .dropIcon {
  display: inline-block;
  margin-left: 10px;
}
.phoneInputError {
  margin: 0;
  padding: 0;
  color: rgb(211, 47, 47);
  font-size: 12px;
  position: absolute;
  top: 100%;
  left: 0;
}

@media (max-width: 1199px) {
  .inputWrap input {
    height: 35px;
  }
}
@media (max-width: 600px) {
  .inputWrap .dropIcon {
    margin-left: 0;
  }
  .inputWrap .makeStyles-flagContainer-2 {
    padding: 5px;    
  }
  .inputWrap .dropIcon svg {
    width: 15px;
  }
  .inputWrap input {
    padding: 0;
    height: 32px;
    font-size: 15px;
  }
  .inputWrap .makeStyles-flagContainer-2 p {
    font-size: 14px;
  }
  .phoneInputError {
    top: 100%;
  }
}
