.appointment-page .appointment-card {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  max-width: 100%;
  box-shadow: none;
  margin-bottom: 30px;
  border: 1px solid #4e297b;
}
.appointment-page .appointment-card.right {
  border: none;
}

.appointment-page .tabs button {
  font-size: 31px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 18px;
  display: inline;
  padding-left: 0px;
}

.appointment-page button > span {
  display: flex;
  align-items: center;
}

.appointment-page .appointmentNotFound {
  text-align: center;
  font-size: 36px;
  margin-top: 250px;
}

.appointment-page .card-container .image-container img {
  border-radius: 0px;
  height: 100%;
  object-fit: cover;
}

.appointment-page .appointment-card .appointment-image {
  border-radius: 10px 0px 0px 0px;
}

.appointment-page .right .appointment-image {
  object-fit: cover;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  height: 370px;
}

.appointment-page .appointment-image {
  object-fit: cover;
  width: 100%;
  border-radius: 10px 0px 0px 10px;
  height: 100%;
}

.appointment-page .appointment-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 30px 0px 30px;
  border-left: 1px solid #4e297b;
  border-right: 1px solid #4e297b;
  border-bottom: 1px solid #4e297b;
  border-radius: 0px 0px 20px 20px;
}

.appointment-page .appointment-title {
  font-size: 30px;
  font-weight: 600;
  color: black;
  line-height: 36px;
}

.appointment-page .appointment-date {
  margin-top: 20px;
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  color: black;
}

.appointment-page .status-container.Cancelled {
  background-color: #f02525;
}
.appointment-page .status-container.Pending {
  background-color: #fab93b;
}
.appointment-page .status-container.Ongoing {
  background-color: #0178fa;
}
.appointment-page .status-container.Completed {
  background-color: #0ac065;
}

.appointment-page .status-container {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  background-color: #3b82f6;
  border-radius: 50px;
  padding: 8px 24px;
  color: white;
}
.appointment-page .status-container p {
  color: white;
  margin-top: 0.4px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}

.appointment-page span {
  padding-left: 3px;
}

.getting-there-icon,
.manage-appointment-icon,
.venue-details-icon {
  width: 60px;
  border: 1px solid black;
  padding: 11px;
  border-radius: 50%;
  overflow: visible;
}
.status-icon {
  width: 1.25rem;
}

.manage-appointment-container h6,
.venue-details-container h6,
.getting-there-container h6 {
  padding-left: 24px;
  font-size: 26px;
  line-height: 31px;
}

.manage-appointment-container p,
.venue-details-container p,
.getting-there-container p {
  padding-left: 24px;
  font-size: 18px;
  line-height: 21.6px;
  padding-top: 10px;
  color: #000;
  opacity: 60%;
}

.appointment-page .divider {
  margin: 16px 0;
}

.getting-there-container,
.manage-appointment-container,
.venue-details-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 15px 0px;
}

.appointment-page .overview-container {
  margin-top: 14px;
  color: black;
  width: 100%;
}

.appointment-page .serviceName {
  margin-top: 14px;
  font-size: 26px;
  line-height: 31px;
  font-weight: 600;
}

.appointment-page .serviceHours {
  margin-top: 10px;
  font-size: 18px;
  color: #231f20;
  opacity: 60%;
}

.appointment-page .overview-container h5 {
  font-size: 26px;
  font-weight: 600;
  margin: 0;
  line-height: 31px;
  color: #231f20;
  opacity: 100%;
}

.appointment-page .overview-container h5.totalPrice {
  font-size: 26px;
  font-weight: 600;
  line-height: 31px;
  color: #231f20;
  opacity: 100%;
  float: right;
  margin-bottom: 0px;
  padding-top: 30px;
}

.appointment-page .total {
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
  padding-top: 30px;
}

.appointment-page .overview-container p.MuiTypography-body2 {
  opacity: 40%;
}

.appointment-page .cancellation-policy {
  margin-top: 30px;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: #000;
}
.appointment-page .cancellationPolicyDesc {
  margin-top: 30px;
  font-size: 18px;
  line-height: 31px;
}

.appointment-page .highlight {
  color: #4e297b;
}

.appointment-page .bookingRef {
  font-size: 22px;
  line-height: 26.4px;
  font-weight: 400;
  margin: 0;
}

.overviewTitle {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 20px;
}

@media screen and (min-width: 1201px) and (max-width: 1366px) {
  .appointment-page .MuiGrid-container {
    margin-left: 0px;
  }
}

.appointment-manager .appointment-card {
  padding-right: 20px;
  margin-top: 14px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 24px;
  border: 1px solid #4a4a4a;
  box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
  width: 560px;
}

.appointment-manager .card-content {
  display: flex;
  gap: 20px;
}

@media (max-width: 960px) {
  .card-content {
    flex-direction: column;
  }
}

.appointment-manager .image-container {
  width: 38%;
}

@media (max-width: 960px) {
  .image-container {
    width: 100%;
  }
}

.appointment-manager .card-image {
  object-fit: contain;
  width: 145px;
  aspect-ratio: 1.11;
}

.appointment-manager .details-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 62%;
}

@media (max-width: 960px) {
  .details-container {
    margin-left: 0;
    width: 100%;
  }
}

.appointment-manager .card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4a4a4a;
}

.appointment-manager .card-date,
.appointment-manager .card-location {
  margin-top: 10px;
  color: #4a4a4a;
  font-size: 0.875rem;
}

.appointment-manager .card-location {
  margin-top: 20px;
}

@media (max-width: 960px) {
  .card-location {
    margin-right: 10px;
  }
}

.appointment-manager .appointment-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  max-width: 660px;
  width: 100%;
  background-color: white;
  margin-top: 0px;
}

.appointment-manager .appointment-header {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 16px;
}

.appointment-manager .appointment-header h4 {
  margin: 0px auto;
}

.appointment-manager .appointment-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #000;
}

.appointment-avatar {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.appointment-main .appointment-action-container {
  flex-direction: column;
  align-items: center;
}

.appointment-action-container .appointment-action-button {
  margin-top: 14px;
  font-size: 1.25rem; /* Corresponds to text-xl */
  font-weight: 500;
  color: #000; /* Corresponds to text-black */
  background: transparent;
  border: none;
  box-shadow: none;
  text-transform: capitalize;
  text-align: left;
  float: left;
  margin-left: 0px;
  padding-left: 0px;
}

.appointment-action-container .appointment-action-button:hover {
  background: none;
  box-shadow: none;
}

@media (max-width: 768px) {
  .appointment-action-button {
    margin-top: 10px; /* Adjust for smaller screens */
  }
}

.appointment-action-container .appointment-action-divider {
  margin-top: 8px;
  width: 100%;
  max-width: 560px;
  border-width: 1px;
  border-style: solid;
  border-color: #292524; /* Corresponds to border-stone-800 */
  opacity: 20%;
}

.appointment-manager h2 {
  font-size: 1.6rem;
  font-weight: bold;
}
.appointment-manager h3 {
  font-size: 1.15rem;
  font-weight: 400;
}

.yesCancel button {
  width: 100%;
  height: 2.8rem;
  border-radius: 10px;
  background-color: #4e297b;
}
.yesCancel button:hover {
  background-color: #2e144e;
}
