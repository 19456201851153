.serviceSection .academy-videos-container {
  margin-top: 2rem;
  max-width: 100%;
  width: 100%;
}
.academy-videos-container h4 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  padding-bottom: 50px;
  padding-top: 30px;
}

.academy-videos-container iframe {
  height: 262px;
  width: 100%;
  border-radius: 10px;
  border: none;
}

.videoDivider {
  margin-top: 30px;
  height: 1px;
  border: 1px solid #bfbfbf;
  max-width: 100%;
}

.title {
  margin-top: 2rem;
  font-weight: 600;
  color: #292524;
}

.video-grid {
  display: flex;
  /*  gap: 1.25rem; */
}

.video-wrapper {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  
}

.video-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.video-thumbnail {
  object-fit: contain;
  height: auto;
  margin-top: 1.25rem;
}

@media (max-width: 900px) {
  .video-grid {
    flex-direction: column;
  }

  .video-thumbnail {
    margin-top: 1.25rem;
    width: 100%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .academy-videos-container iframe {
    height: auto;
  }
}
@media (max-width: 899px) {
  .academy-videos-container h4 {
    padding: 0 0 20px;
  }
}
