.professional.css-gh54bq-MuiContainer-root {
  max-width: 100%;
  padding: 0px 48px;
}
.professional .dropdown p.css-1yzf8j7-MuiTypography-root {
  color: white;
}
.professional select {
  background: transparent;
  border: none;
  font-family: poppins;
  font-size: large;
  color: white;
  font-size: 20px;
  line-height: 1.5;
  padding: 5px 10px;
}
.professional select option {
  color: black;
}
.professional .css-v60ym6-MuiAvatar-root {
  display: none;
}
.selectProfessional {
  border-radius: 10px !important;
  box-shadow: none !important;
  border-color: #231F20 !important;
  align-items: center;
}

.selectProfessional .serviceCardLeft {
  width: calc(100% - 275px);
}
.selectProfessional .serviceCardLeft .textTime {
  margin-top: 0;
}

.selectProfessional .serviceCardRight {
  width: 275px;
}
.professionalName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 155px;
}
@media (max-width: 1440px) {
  .serviceTitle {
    font-size: 16px !important;
  }
  .selectProfessional .serviceCardLeft {
    width: calc(100% - 225px);
  }
  .selectProfessional .serviceCardRight {
    width: 225px;
  }
  .selectProfessional .serviceCardRight > div {
    gap: 10px;
  }
  .professionalName {
    width: 110px;
  }
  .selectProfessional {
    gap: 10px !important;
  }
}

@media (max-width: 600px) {
  .selectProfessional .serviceCardLeft {
    width: calc(100% - 160px);
  }
  .selectProfessional .serviceCardRight {
    width: 160px;
  } 
  .profileAvatar {
    width: 22px !important;
    height: 22px !important;
  }
  .professionalName {
    font-size: 12px !important;
    width: 80px;
  }
  .selectProfessional .serviceCardLeft .textTime {
    font-size: 12px;
  }
  .serviceTitle {
    font-size: 14px !important;
  }
}

/* @media screen and (min-width: 1025px) and (max-width: 1200px) {
  .team-selection h6,
  .team-selection p {
    font-size: 10px;
  }
  .team-selection .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
}
@media screen and (min-width: 900px) and (max-width: 1024px) {
  .team-selection h6,
  .team-selection p {
    font-size: 8px;
  }
  .team-selection .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 900px) {
  .professionalContainer .MuiPaper-root {
    max-width: 100%;
    margin-bottom: 25px;
  }
} */
