.heading {
  margin-top: 40px;
  font-weight: bold;
  color: #000;
  font-size: 26px;
}

h4.heading {
  font-size: 26px;
  margin-top: 20px;
  font-weight: bold;
}

.booking textarea {
  width: 100%;
  height: 100px;
  margin-top: 30px;
  border-radius: 10px;
  font-size: 18px;
  font-family: Poppins;
  padding: 10px;
  resize: none;
}

.reviewCard {
  display: flex;
  padding: 20px;
  margin-top: 30px;
  background-color: white;
  border: 1px solid #4b4b4b;
  border-radius: 12px;
}

.reviewCard:hover {
  cursor: pointer;
}

.reviewCard img {
  width: 30px;
  height: 30px;
}

.image-small {
  width: 43px;
  object-fit: contain;
}

.payVenue {
  margin: auto;
}

.progress-review .payOnline img {
  width: 40px;
  height: 40px;
}

.progress-review .payOnline .MuiGrid-item {
  padding-top: 16px;
}

.booking .couponCard {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 30px;
  background-color: black;
  color: white;
  border-radius: 12px;
}

.couponCard .image-medium {
  width: 57px;
  object-fit: contain;
  margin-top: 7px;
}

.couponCard .exploreOffers {
  margin-top: 10px;
}

.couponCard .image-icon {
  width: 15px;
  object-fit: contain;
  margin-top: 18px;
}

.couponCard .notesCard {
  padding: 20px;
  margin-top: 30px;
  background-color: white;
  border: 1px solid rgba(75, 75, 75, 0.5);
  border-radius: 12px;
}
.coupon-container .coupenContainer .coupenItem {
  max-width: 100%;
  flex-basis: 100%;
}
.coupon-container .coupenContainer .coupenItem .coupon-header {
  padding: 0px;
}
.coupon-container .coupenContainer .coupenItem .coupon-card > div {
  padding: 0px;
}
.coupon-container .coupon-card .coupon-button-container button.enable {
  background-color: #231f20;
  margin-bottom: 0px;
  position: relative;
  bottom: 5px;
}
.coupon-container .coupon-card .coupon-button-container button.disable {
  background-color: #231f20;
  margin-bottom: 30px;
}
.coupon-container .coupon-card:nth-child(1) .coupon-button-container button {
  margin-top: 15px;
}
@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .reviewCard h6 {
    font-size: 14px;
    margin-top: 5px;
  }
  .couponCard .image-icon {
    width: 18px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1024px) {
  .reviewCard h6 {
    font-size: 14px;
    margin-top: 5px;
  }
  .couponCard .first {
    width: 90%;
    float: left;
  }
  .couponCard .first .MuiGrid-item {
    width: 10%;
    float: left;
  }
  .couponCard .first .MuiGrid-item.black {
    position: relative;
    left: 50px;
  }
  .couponCard .second {
    width: 10%;
  }
  .couponCard .image-icon {
    width: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .reviewCard h6 {
    font-size: 13px;
    margin-top: 5px;
  }
}
@media (max-width: 899px) {
  .couponCard .first {
    width: 90%;
    float: left;
  }
  .couponCard .first .MuiGrid-item {
    width: 10%;
    float: left;
  }
  .couponCard .first .MuiGrid-item.black {
    position: relative;
    left: 50px;
  }
  .couponCard .second {
    width: 10%;
  }
  .couponCard .image-icon {
    width: 15px;
  }
}
