/* PlanTypeButton.css */
.plan_type {
  display: flex;
  max-width: 516px;  
  background: #f6f6f6;
  border: 1px solid #4e297b;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  margin-bottom: 0;
}

.plan_type .plan_button {    
  min-width: 155px;
  padding: 14px;  
  color: #414141;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  border-radius: 10px;
}

.plan_type .plan_button.selected {    
  background: #4e297b;
  color: white;    
  border-radius: 10px;
}
.plan_type .plan_button.selected:hover{
    background-color: #2e144e;
} 


.MuiButton-root .plan_type .plan_button:hover {
    background-color: transparent !important; /* Disable hover effect */
}

.MuiButton-root .plan_type .plan_button:active {
    background-color: transparent !important; /* Disable active effect */
}

/* .group-button {
    padding: 10px !important;
    background-color: #0000000D;
    border: 1px solid #0178FA;
    border-radius: 12px;
}

.group-button-wrapper {
    width: 100%;
    gap: 10px;
} */

/* .button {
    color: black !important;
    width: 100%;
    border: none !important;
    background-color: transparent;
    transition: background-color 0.3s, color 0.3s;
    border: unset;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 10px !important;
    /* font-size: 16px !important;
    font-weight: 600 !important;
} */

/* .button:hover {
    color: #0178FA !important;
    background-color: unset !important;
} */

/* .button.selected {
    background-color: #0178FA !important;
    color: white !important;
    border-radius: 12px !important;
} */
@media (max-width: 1280px) {
    .plan_type{
    width: auto;
    }
}