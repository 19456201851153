.empty-favorites-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  background-color: #ffffff;
  border: 1px solid #525252;
  border-radius: 20px;
  box-sizing: border-box;
}

.empty-favorites-container .empty-favorites-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
}

.empty-favorites-container .favorites-icon {
  width: 70px;
  object-fit: contain;
  aspect-ratio: 1.17;
  margin-top: 84px;
}

.empty-favorites-container .empty-favorites-content .favorites-title {
  text-align: center;
  font-weight: 600;
  font-size: 30px; /* 30px */
  color: black;
  margin-bottom: 20px;
}

.empty-favorites-container .favorites-description {
  font-size: 1rem; /* 16px */
  color: black;
}

.empty-favorites-container .view-saloon-button {
  margin-top: 40px;
  padding: 12px 40px;
  font-size: 22px;
  text-transform: none;
  background: #4E297B;
  margin-bottom: 84px;
  border-radius: 10px;
  
}
.empty-favorites-container .view-saloon-button:hover{
  background-color: #2e144e;
  box-shadow: none;
}



@media (max-width: 899px) {
  .empty-favorites-container .favorites-icon{
    margin-top: 50px;
  }
  .empty-favorites-container .view-saloon-button{
    margin-bottom: 50px;
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .empty-favorites-container {
    padding: 10px;
    width: 100%;
  }

  .empty-favorites-container .favorites-title {
    font-size: 1.5rem; /* 24px */
  }

  .empty-favorites-container .favorites-description {
    font-size: 0.875rem; /* 14px */
  }
}
