.cmdBanner{
  background: url('../../Assets/images/cmdBanner.png');
  top: 15px;
  position: relative;
}
.cmdBanner h2{
  font-weight: 300;
  font-size: 60px;
  line-height: 90px;
  text-align: center;
  color: #231f20;
  padding: 200px 0px;
  margin: 0px auto;
}
.cmdBanner h2 span{
  font-weight: 700;
}
.cmdContent{
  padding-left: 100px;
  padding-right: 100px;
}
.cmdContent h2{
  font-size: 60px;
  line-height: 90px;
  font-weight: 700;
  margin-top: 100px;
  color: #231f20;
}
.cmdContent.privacyContact h2{
  margin-top: 50px;
}

.cmdContent p{
  font-size: 18px;
  font-weight: 400;
  color: #636363;
  width: 100%;
}
.cmdContent h3{
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  color: #231f20;
  width: 100%;
  margin-top: 30px;
}

.termsContent p{
  margin-top: 10px;
  margin-bottom: 10px;
}

.privacyContent p b{
  margin-top: 60px;
  color: #231f20;
}

.cmdContent h4{
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #414141;
  width: 100%;
  margin: 10px 0px;
}
.cmdContent p span{
  font-weight: 500;
}
.cmdContent ul li{
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #636363;
} 
.cmdContent ul.noMarker li{
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #636363;
  list-style-type: none;
} 
.cmdContent ul.noMarker li ul li{
  list-style-type: disc;
}

.privacyContact ul li{
  margin-top: 10px;
  margin-bottom: 10px;
}
.cmdContent ul.termsList, .cmdContent ul.noBullet{
  list-style-type: none;
}
.cmdContent ul.withPadding{
  padding-left: 20px;
}

@media (max-width: 1440px) {
  .cmdBanner h2{
    padding: 150px 0px;
  }
}
@media (max-width: 1199px) {
  .cmdBanner h2 {
    padding: 100px 0px;
    font-size: 40px;
    line-height: 50px;
  } 
  .cmdContent h2 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 899px) {
  .cmdBanner h2 {
    padding: 70px 0;
    font-size: 36px;
    line-height: 40px;
  }
  .cmdContent h2 {
    font-size: 36px;
    line-height: 40px;
    margin-top: 70px;
  }
  .cmdContent {
    padding-left: 70px;
    padding-right: 70px;
  }
  .cmdContent p {
    font-size: 16px;
  }
  .cmdContent h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (max-width: 600px) {
  .cmdBanner h2 {
    font-size: 26px;
    line-height: 36px;
    padding: 45px 0;
}
.cmdContent h2 {
  font-size: 30px;
  line-height: 40px;
  margin-top: 50px;
}
  .cmdContent {
    padding-left: 40px;
    padding-right: 20px;
     text-align: justify; 
}
.cmdContent ul, .cmdContent ul.termsList{
  padding-left: 0px;
}
.cmdContent ul.noMarker, .cmdContent ul.refundList{
  padding-left: 20px;
}
.cmdContent h3{
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
}
}