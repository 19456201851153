.hero-section {
    padding: 50px 100px;
}

.hero-section .heder-label {
    color: black;
    font-size: 60px;
    font-weight: 400;
}

.grey-label {
    /* color: #8a8a8a !important; */
    line-height: 25px;
}

.grid1{
    box-shadow: 0px 0px 39px 1px rgba(0, 0, 0, 0.14);
}

.para-label {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400 !important;
}

.hero-section .heder-label span {
    font-weight: 600;
}

/* Intro Section */
.intro-section {
    padding: 40px 0px;
}

.intro-section .introl-label {
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin: 0px !important;
}

.intro-section .superchange-label {
    font-weight: 400;
    color: #000000;
    font-size: 30px;
    margin: 0px !important;
}

.intro-section .business-label {
    font-weight: 500;
    color: #000000;
    font-size: 35px;
    margin: 0px !important;
}

.intro-section .platform-label {
    font-weight: 700;
    color: #4E297B;
    font-size: 40px;
    margin: 0px !important;
}

.intro-section .business-label {
    font-weight: 500;
    font-size: 20px;
}

.intro-section .revenue-label {
    font-weight: 500;
    font-size: 20px;
    margin: 0px !important;
}

/* Why Register Section */
.why-register-section {
    padding: 40px 0px;
}

.why-register-label {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
}

.grow-business-label {
    font-size: 48px !important;
    font-weight: 600 !important;
    line-height: 48px !important;
    color: #000000;
    margin-top: 10px !important;
}

.grow-business-label span {
    color: #4E297B;
    font-weight: 700;
}

.why-register-data {
    font-size: 17px;
    margin-top: 15px !important;
}

/* Growth Advisior */
.growth-advisior-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 40px;
}

/* Connection Form */
.connection-form-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0px;
}

.growth-advisior-section .growth-advisior-label {
    font-weight: 500;
    font-size: 39px;
    color: #000000;
}

.growth-advisior-section .growth-advisior-label span {
    font-weight: 700;
    font-size: 39px;
    color: #4E297B;
}

.connection-form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.connection-label{
    font-size: 39px;
    font-weight: 600 !important;
}

.connection-label span{
    color: #4E297B;
    font-weight: 700;
}

@media (max-width: 1199px) {
    .hero-section {
      padding: 50px 70px;
    }
    .intro-section .superchange-label {
        font-size: 24px;
    }
    .intro-section .platform-label {
        font-size: 30px;
        line-height: 36px;
    }
    .intro-section .revenue-label {
        font-size: 18px;
        line-height: 20px;
    }
    .grow-business-label {
        font-size: 36px !important;
        line-height: 40px !important;
    }
    .hero-section .heder-label {
        font-size: 45px;
        line-height: 50px;
    }
    .para-label {
        font-size: 15px !important;
        line-height: 20px !important;
    }
    h1.connection-label {
        font-size: 30px !important;
        line-height: 36px !important;
    }
    h4.grey-label {
        font-size: 30px !important;
        line-height: 36px !important;
    }
    .aboutFeatures .boxTitle {
        font-size: 36px !important;
        line-height: 40px !important;
    }
    .appSectionEnd {
        margin-bottom: 70px !important;
    }   
  
  }
  

@media (max-width: 899px) {
    .hero-section {
        padding: 30px 50px;
    }

    .intro-section .business-label,
    .intro-section .introl-label {
        text-align: center !important;
        padding: 10px 0;        
    }

    .intro-section .superchange-label {
        text-align: center !important;        
    }

    .intro-section .platform-label {
        text-align: center !important;
        padding: 10px 0;
        display: inline-block;
    }

    .intro-section .revenue-label {
        text-align: center !important;
        padding: 5px 0;
    }
    .grow-business-label br {
        display: none;
    }

    .register-btn {
        text-align: center;
    }   
    .why-register-section .grow-business-label {
        font-size: 30px !important;
        line-height: 36px !important;
    }
    .why-register-section ul li {
        padding: 10px 0;
    }
    .why-register-section ul li h6 {
        font-size: 18px;
    }
    .why-register-section button {
        margin-top: 0;
    }
    .why-register-section img {
        margin-top: 30px;
    }
    .hero-section .heder-label {
        font-size: 36px;
        line-height: 40px;
    }
    .hero-section .subscribeBtn {
        margin-right: auto;
    }
}


@media (max-width: 600px) {
    .hero-section {
        padding: 10px 20px !important;
    }
    .intro-section {
        padding: 15px 0px 30px;
    }
    .hero-section .heder-label {
        font-size: 25px !important;
    }
    .intro-section .business-label {
        display: inline-block;
    }

    .intro-section .superchange-label {
        font-size: 20px;
        line-height: 26px;
    }

    .intro-section .platform-label {
        font-size: 20px;
        padding: 0;
    }

    .intro-section .revenue-label {
        font-size: 15px;
    }
    .why-register-section {
        padding-top: 0;
    }
    .why-register-section .grow-business-label {
        font-size: 24px !important;
        line-height: 30px !important;
    }
    .why-register-section ul li h6 {
        font-size: 16px;
    }
    .why-register-section ul li {
        padding: 5px 0;
    }
    .hero-section .heder-label {
        font-size: 24px !important;
    }
    .para-label {
        font-size: 14px !important;
        line-height: 18px !important;
    }
    h4.grey-label, h1.connection-label {
        font-size: 24px !important;
        line-height: 30px !important;
    }
    .connection-form-section {
        padding: 30px 0;
    }
    .ourService {
        padding-left: 15px;
    }
    h4.connection-label {
        font-size: 14px !important;
    }
    .aboutFeatures .boxTitle {
        font-size: 24px !important;
        line-height: 30px !important;
    }
}