.main-container {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.form-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.formContent {
  max-width: 450px;
  margin: auto;
}

.register .login-section {
  padding-left: 0px;
  margin: auto;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 2px 46px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: calc(100% - 100px);
  max-width: 700px;
  position: relative;
  min-height: auto;
  overflow-y: auto;
}

.checkbox-field .MuiCheckbox-root:focus,
.checkbox-field .MuiCheckbox-root:focus-visible {
  outline: none;
  background-color: transparent; 
}

.form::-webkit-scrollbar {
  width: 10px;
}
.form::-webkit-scrollbar-track  {
  background: #fff;
  border-radius: 10px;
}
.form::-webkit-scrollbar-thumb {
  background: #4e297b;
  border-radius: 10px;
}
.form::-webkit-scrollbar-thumb:hover {
  background: #4e297b;
}

.form-logo {
  width: 60px;
  object-fit: contain;
}

.form .form-title {
  margin-top: 0px;
  text-align: center;
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
  margin-bottom: 20px;
}

.form .form-subtitle {
  margin-top: 15px;
  text-align: center;
  color: #333;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 80%;
  margin: 0 auto;
}

.input-group {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
}

.input-group input {
  padding: 15px 20px;
  height: 50px;
  border: 1px solid rgba(35, 31, 32, 0.5);
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
}

.input-group label {
  display: none;
}

.form-card p.Mui-error {
  margin-left: 0px;
  position: absolute;
  left: 0;
  top: 100%;
  margin: 0;
}

/* Target the checkbox icon */
.register .MuiCheckbox-root .MuiSvgIcon-root {
  border-radius: 8px; /* Customize the border radius */
  border: 1px solid #231f20; /* Optional: Add a border for visibility */
  padding: 0px; /* Optional: Adjust padding */
  width: 30px;
  height: 30px;
  color: #fff;
  fill: #4e297b;
  position: relative;
}
/* .MuiCheckbox-root .MuiSvgIcon-root::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #4E297B;
} */
.register .MuiCheckbox-root {
  position: relative;
  padding: 0px 9px 0px 0px;
}

.register input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset;
}

.register .MuiCheckbox-root.Mui-checked::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  background: #4e297b;
  border-radius: 10px;
}
.register .MuiCheckbox-root.Mui-checked::after {
  content: '';
  background: url('data:image/svg+xml,<svg viewBox="-0.5 -0.5 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="Check--Streamline-Outlined-Material" height="24" width="24"><desc>Check Streamline Icon: https://streamlinehq.com</desc><g id="check"><path id="Vector" d="m9.056345833333335 17.106345833333336 -5.366666666666666 -5.366666666666666 1.0302083333333334 -1.0302083333333334 4.336458333333334 4.336458333333334 9.2 -9.2 1.0302083333333334 1.0302083333333334 -10.230208333333334 10.230208333333334Z" fill="%23ffffff" stroke-width="1"></path></g></svg>');
  width: 20px;
  height: 20px;
  position: absolute;
  left: 4px;
  top: 4px;
  z-index: 1;
}
.register .MuiCheckbox-root .MuiSvgIcon-root path {
  opacity: 0;
}

.register .checkbox-field label {
  margin-left: 0px;
}

/* Change the border on hover */
.register .MuiCheckbox-root:hover .MuiSvgIcon-root {
  color: #4e297b;
}

.form .input-field {
  margin-top: 30px;
}
.register .form fieldset {
  border-radius: 10px;
  display: none;
}
.register input::placeholder {
  opacity: 40% !important;
}

.form .input-field label {
  padding-left: 100px;
}
.checkbox-field label p {
  font-size: 13px;
  line-height: 20px;
  margin-left: 6px;
}

.checkbox-field label p a {
  color: #4e297b;
}

.register .makeStyles-container-1 {
  margin-left: 0;
  display: flex;
  gap: 16px;
  padding: 5px 10px 5px 5px;
  margin-top: 20px;
  max-width: 100%;
  font-size: 16px;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid rgba(82, 82, 82, 0.5);
  color: #525252;
  width: 100%;
  height: 50px;
}

.register small {
  margin-left: 100px;
  color: #d32f2f;
  font-size: 0.75rem;
}

.form-card .checkbox-field {
  margin-top: 20px;
}

.form a {
  color: #0178fa;
  text-decoration: none;
}

.register .form-card button.submit-btn {
  margin-top: 20px;
  text-transform: capitalize;
  padding: 20px 15px;
  border-radius: 10px;
  width: 100%;
  font-size: 18px;
  line-height: 21px;
  font-weight: 300;
  background: #4e297b;
  color: #fff;
  position: relative;
}
.register .form-card button.submit-btn:hover {
  background: #2e144e;
}

.register .form-card button.submit-btn .loaderSpinner {
  position: absolute;
  left: calc(50% + 50px);
  transform: translateX(-50%);
}

.register .form-card button.btnDisable {
  background: rgba(78, 41, 123, 0.4);
  cursor: none;
}

.aside-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: #333;
  color: white;
  padding: 40px;
}

.aside-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.aside-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.aside-title {
  font-size: 180px;
  font-weight: 700;
}

.brand-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: auto;
  background: url(../../Assets/images/authPatternBG.jpg) no-repeat 0 0;
  background-size: cover;
  color: white;
}

.logo-section {
  padding-left: 0px;
  padding-top: 0 !important;
}

.logo-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* @media (max-width: 900px) {
  .main-container {
    flex-direction: column;
  }

  .form-container,
  .aside-container {
    width: 100%;
  }

  .form {
    padding: 20px;
  }

  .aside-title {
    font-size: 4rem;
  }

  .aside-content {
    padding: 20px;
  }
} */

/* @media screen and (min-width: 1201px) and (max-width: 1500px) {
  .checkbox-field {
    margin-top: 20px;
    padding: 0px 20px;
  }
  .form .input-field,
  .input-group,
  .form .form-subtitle {
    padding: 0px 20px;
  }
  .form .input-field label {
    padding-left: 20px;
  }
} */
/* @media screen and (min-width: 320px) and (max-width: 767px) {
  .checkbox-field {
    margin-top: 20px;
    padding: 0px 20px;
  }
  .form .input-field,
  .input-group,
  .form .form-subtitle {
    padding: 0px 20px;
  }
  .form .input-field label {
    padding-left: 20px;
  }
  .form .checkbox-field span {
    font-size: 13px;
  }
} */
/* @media screen and (min-width: 900px) and (max-width: 1024px) {
  .checkbox-field {
    margin-top: 20px;
    padding: 0px 20px;
  }
  .form .input-field,
  .input-group,
  .form .form-subtitle {
    padding: 0px 20px;
  }
  .form .input-field label {
    padding-left: 20px;
  }
  .form .checkbox-field span {
    font-size: 12px;
  }
  .form-card button.submit-btn {
    width: 90%;
  }
} */

/* @media screen and (min-width: 1025px) and (max-width: 1200px) {
  .checkbox-field {
    margin-top: 20px;
    padding: 0px 20px;
  }
  .form .input-field,
  .input-group,
  .form .form-subtitle {
    padding: 0px 20px;
  }
  .form .input-field label {
    padding-left: 20px;
  }
  .form-logo {
    width: 40px;
  }
  .form .form-title {
    font-size: 26px;
  }
  .form .checkbox-field span {
    font-size: 12px;
  }
  .form-card {
    padding: 125px 40px;
  }
  .form-card button.submit-btn {
    width: 90%;
  }
} */

/* @media (max-width: 1600px) {
  .form-card {
    padding: 28.5px 4rem;
  }
  .register .form .input-group label,
  .register .form .input-field label {
    opacity: 50% !important;
  }
  .register .form .form-title {
    font-size: 1.6rem;
  }
  .register .form .form-subtitle {
    font-size: 0.9rem;
  }
  .register .form .input {
    padding: 10.5px 14px;
  }
  .register .form label {
    font-size: 0.8rem;
  }
  .register .makeStyles-container-1 {
    padding: 5px;
  }
  .register .makeStyles-container-1 input::placeholder {
    font-size: 0.8rem;
    opacity: 50% !important;
  }

  .makeStyles-flagImg-3 {
    width: 20px;
  }
  .register .form .checkbox-field span,
  .register .form .checkbox-field p {
    font-size: 0.8rem;
  }
} */
/* @media (max-width: 1440px) {
  .form-card {
    padding: 28.5px 4rem;
  }
  .otp h4 {
    font-size: 1rem;
    margin-top: 2rem;
  } 
  .register .makeStyles-container-1 {
    margin-left: 20px;
    width: 92.5%;
  }
  .form-card .checkbox-field {
    padding: 0px 20px;
  }
  .register .form-card button.submit-btn {
    width: 90%;
  }
}
@media (max-width: 1366px) {
  .register .login-form-container {
    padding: 94px 4rem;
  }
  .otp h4 {
    font-size: 1rem;
    margin-top: 2rem;
  }  
}

@media (max-width: 1280px) {
  .register .login-form-container {
    padding: 95px 2rem;
  }  
}

@media (max-width: 1200px) {
  .form-card {
    padding: 35px 4rem;
  }
  .register .form .input-group label,
  .register .form .input-field label {
    opacity: 50% !important;
  }
  .register .form .form-title {
    font-size: 1.2rem;
  }
  .register .form .form-subtitle {
    font-size: 0.8rem;
  }
  .register .form .input {
    padding: 10.5px 14px;
  }
  .register .form label {
    font-size: 0.8rem;
  }
  .register .makeStyles-container-1 {
    padding: 5px;
  }
  .register .makeStyles-container-1 input::placeholder {
    font-size: 0.8rem;
    opacity: 50% !important;
  }

  .makeStyles-flagImg-3 {
    width: 20px;
  }
  .register .form .checkbox-field span,
  .register .form .checkbox-field p {
    font-size: 0.8rem;
  }
}
@media (max-width: 1024px) {
  .form-card {
    padding: 26.5px 4rem;
  }
  .opt input {
    font-size: 0.7rem;
  }
  .otp h4 {
    font-size: 1rem;
    margin-top: 2rem;
  }
  
  .login-form-container p.dontAccount {
    margin-bottom: 3rem;
  }
  .register .makeStyles-container-1 {
    width: 88%;
  }
} */
/*
@media (min-width: 1240px) and (min-height: 472px) and (max-resolution: 96dpi) {
  .register  .form-card{
    padding: 32px 4rem;
  }
  .register .login-form p.login-form-subtitle{
    margin-bottom: 1.2rem;
    margin-top: 0.3rem;
  }

  .register .form-card .checkbox-field{
    margin-top: 10px;
  }

  .register .login-form button{
    padding: 6px 16px;
  }
  .register .login-form p.login-form-footer{
    margin-bottom: 20px;
  }
  .register .form .form-title {
    font-size: 1rem;
  }

  .register .input-group{
    margin-top: 20px;
  }

  .register .form input{
    padding: 8.5px 14px;
  }
  .register .form label{
    font-size: 0.8rem;
    margin-top: -5px;
  }
  .register .makeStyles-container-1{
    padding: 4px;
    margin-top: 0px;
    height: 2.5rem;
    width: 91.7%;
  }
  .register .makeStyles-container-1 input::placeholder{
    font-size: 0.8rem;
    opacity: 50% !important;
  }

  .register .makeStyles-container-1 p{
    font-size: 12px;
  }

  .makeStyles-flagImg-3{
    width: 10px;
  }
  .register .form .input-field{
    margin-top: 10px;
  }
  .register .form .checkbox-field span, .register .form .checkbox-field p{
    font-size: 0.7rem;
  }
  .login-form-logo {
    margin-bottom: 1rem;
    width: 30px;
  }
  .register .form-card button.submit-btn{
    margin-top: 20px;
    height: 40px;
    margin-bottom: 10px;
  }
  .form .input-field{
    margin-bottom: 10px;
  }
}
*/

@media (min-width: 2506px) and (min-height: 1109px) {
  .register .form-card {
    padding: 190px 5rem;
  }
}

@media (min-width: 3072px) {
  .register .form-card {
    padding: 595.7px 6rem;
  }
}

@media (max-width: 1600px) {
  .form {
    max-width: 600px;
  }
}
@media (max-width: 1366px) {
  .form {
    max-width: 550px;
  }
}
@media (max-width: 1199px) {
  .form {
    max-width: 90%;
    min-height: auto;
  }
  .form .form-title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 18px;
  }
  .form .form-subtitle {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }
  .register .form-card button.submit-btn {
    padding: 16px 15px;
  }
}
@media (max-width: 899px) {
  .form {
    min-height: 800px;
  }
}
@media (max-width: 600px) {
  .form {
    min-height: auto;
    height: calc(100% - 50px);
    overflow: visible;
  }
  .form .form-title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .form .form-subtitle {
    font-size: 14px;
    line-height: 20px;
  }
  .input-group {
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }
  .input-group input {
    height: 52px;
    padding: 10px 15px;
    font-size: 15px;
  }
  .register .makeStyles-container-1 {
    margin-top: 20px;
  }
  .register .form-card button.submit-btn {
    margin-top: 20px;
    font-size: 18px;
    line-height: 20px;
  }
  .form-card .checkbox-field {
    margin-top: 15px;
  }
  .form-card .checkbox-field label {
    margin-right: 0;
  }
  .checkbox-field label p {
    font-size: 12px;
  }
  .MuiCheckbox-root .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    border-radius: 6px;
  }
  .MuiCheckbox-root.Mui-checked::before {
    width: 24px;
    height: 24px;
    border-radius: 6px;
  }
  .MuiCheckbox-root.Mui-checked::after {
    left: 9px;
    top: 9px;
  }
}
