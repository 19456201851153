/* profilePage.css */

main.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}

.payment-methods-section .add-payment-button:hover,
.address-section .add-button:hover,
.profile-sidebar .edit-info-btn,
.profile-sidebar .logout-btn {
  background: transparent !important;
}

.MuiContainer-maxWidthLg.profile.css-kb43wd-MuiContainer-root {
  max-width: 100%;
  padding: 0 100px;
}

@media (max-width: 900px) {
  main.profile {
    margin-top: 10px;
    max-width: 100%;
  }

  .mainContent .MuiGrid-item {
    margin-left: 0;
    width: 100%;
  }
}

/* For screens wider than 1600px */
@media (max-width: 1600px) {
  .profile-sidebar .profile-name {
    font-size: 1.6667rem;
  }
  .profile-sidebar .edit-info-btn {
    font-size: 1.1458rem;
  }
  .profile-sidebar .profile-label {
    font-size: 1.1458rem;
  }
  .social-logins-section .social-login-name,
  .social-logins-section .connect,
  .social-logins-section .disconnect,
  .deleteAccountSection .deleteButton,
  .quick-links-section .quick-link-button,
  .address-section .address-text,
  .deleteAccountSection .description {
    font-size: 1.1rem;
  }
  .address-section .title,
  .payment-methods-section h5,
  .social-logins-section .section-title,
  .notifications-section h5,
  .deleteAccountSection .heading {
    font-size: 1.5rem;
  }
  .address-section .add-button,
  .button-text {
    font-size: 1.2rem;
  }
}

/* For screens wider than 1440px */
@media (max-width: 1440px) {
  .profile-sidebar .profile-name {
    font-size: 1.5rem;
  }
  .profile-sidebar .edit-info-btn {
    font-size: 1.0313rem;
  }
  .social-logins-section .social-login-name,
  .social-logins-section .connect,
  .social-logins-section .disconnect,
  .deleteAccountSection .deleteButton,
  .quick-links-section .quick-link-button,
  .address-section .address-text,
  .deleteAccountSection .description {
    font-size: 1.2rem;
  }
  .address-section .title,
  .payment-methods-section h5,
  .social-logins-section .section-title,
  .notifications-section h5,
  .deleteAccountSection .heading {
    font-size: 1.5rem;
  }
  .address-section .add-button,
  .button-text {
    font-size: 1.2rem;
  }
}

/* For screens wider than 1366px */
@media (max-width: 1366px) {
  .profile-sidebar .profile-name {
    font-size: 1.4229rem;
  }
  .profile-sidebar .edit-info-btn {
    font-size: 0.9783rem;
  }
  /* .social-logins-section .social-login-name, .social-logins-section .connect, .social-logins-section .disconnect, .deleteAccountSection .deleteButton, .quick-links-section .quick-link-button, .address-section .address-text, .deleteAccountSection .description{
    font-size: 0.9rem;
  }
  .address-section .title, .payment-methods-section h5, .social-logins-section .section-title, .notifications-section h5, .deleteAccountSection .heading{
    font-size: 1.3rem;
    font-weight: 500;
  } */
}

/* For screens wider than 1280px */
/* @media (max-width: 1280px) {
  .profile-sidebar .profile-name{
    font-size: 0.8rem;
    font-weight: 400;
  }
  .profile-sidebar .edit-info-btn{
    font-size: 0.7rem;
    font-weight: 400;
  }
  .profile-sidebar .profile-label{
    font-size: 0.9rem;
  }
  .profile-sidebar .profile-value{
    font-size: 0.7rem;
  }
  .profile-sidebar .logout-btn{
    font-size: 0.8rem;
  }
  .address-section .title, .payment-methods-section h5, .social-logins-section .section-title, .notifications-section h5,
   .deleteAccountSection .heading{
    font-size: 1.0rem;
    font-weight: 500;
  }
  .notifications-section .notification-description, .social-logins-section .section-description,
   .payment-methods-section .description, .button-text, .notifications-section .notification-setting p, .address-section .subtitle {
    font-size: 0.8rem;
  }
  .social-logins-section .social-login-name, .social-logins-section .connect, .social-logins-section .disconnect,
  .deleteAccountSection .deleteButton, .quick-links-section .quick-link-button, .address-section .address-text
  ,.deleteAccountSection .description 
  {
    font-size: 0.7rem;
  }
  .social-logins-section .social-login-container{
    margin-top: 0px;
  }
  .notifications-section .divider{
    margin-top: 0px;
  }
  .profile-sidebar .profile-details, .profile-sidebar .divider-bottom{
    margin-top: 1rem;
  }
  .sidebarContent .profile-sidebar{
    padding: 1.25rem 0;
  }
  .profile-sidebar .profile-image-container{
    width: 120px;
    height: 120px;
  }
  .profile-sidebar .profile-edit-icon{
    width: 25px;
    height: 25px;
    right: 5px;
  }
  .quick-links-section .quick-link-button{
    padding: 0.5rem 4rem;
  }
  .notifications-section, .mainContent .address-section, .payment-methods-section{
    padding: 1.25rem;
  }
  .social-logins-section .social-login-icon{
    width: 1.25rem;
  }
  .payment-method-image{
    width: 1.5rem;
  }
  .payment-method-image, .button-text{
    margin-top: 0px;
  }
  .editProfile input, .editProfile select{
    font-size: 10px;
    padding: 12px;
  }
  .editProfile .profile-edit-icon{
    left: 7.5rem;
    bottom: 2.5rem;
    width: 1.5rem;
  }
  .editProfile .header-box h1{
    width: auto;
    margin-bottom: 15px;
  }
  .gender .MuiOutlinedInput-root{
    height: 40px;
  }
  .gender label{font-size: 10px;margin-top: -2px;}
  .editProfile .birthdate{
    min-width: auto !important;
    min-height: 2.5rem !important;
  }
  .birthdate label{
    font-size: 0.6rem;
    margin-top: 6px;
  }
  button.continue{
    width: 83%;
  }
  .gender .MuiSelect-select{
    font-size: 10px;
  }
} */

/* For screens wider than 1200px */
/* @media (max-width: 1200px) {
  .profile-sidebar .profile-name{
    font-size: 1.2500rem;
    font-weight: 375;
  }
  .profile-sidebar .edit-info-btn{
    font-size: 0.8594rem;
    font-weight: 400;
  } 
  button.continue {
    width: 83%;
    height: 2.7rem;
    border-radius: 0.5rem;
}
} */

/* For screens wider than 1024px */
@media (max-width: 1199px) {
  .MuiContainer-maxWidthLg.profile.css-kb43wd-MuiContainer-root {
    padding: 0 70px;
  }
  .profile-sidebar .profile-image-container {
    width: 120px;
    height: 120px;
  }
  .profile-sidebar .profile-edit-icon {
    width: 32px;
    height: 32px;
    right: 5px;
    bottom: 5px;
  }
  .profile-sidebar .profile-name {
    font-size: 22px;
  }
  .profile-sidebar .edit-info-btn {
    font-size: 15px;
    padding: 0;
    line-height: 1;
  }
  .profile-sidebar .profile-label {
    font-size: 16px;
  }
  .profile-sidebar .profile-value {
    font-size: 14px;
  }
  .profile-sidebar .logout-btn {
    font-size: 18px;
    line-height: 22px;
    padding: 0;
  }
  .address-section .title,
  .payment-methods-section h5,
  .social-logins-section .section-title,
  .notifications-section h5,
  .deleteAccountSection .heading {
    font-size: 18px;
  }
  .address-section .add-button,
  .button-text {
    font-size: 18px;
  }
  .address-section .icon {
    width: 24px;
    margin-top: 0;
  }
  .payment-methods-section {
    padding: 24px;
    margin-top: 24px;
  }
  .payment-methods-section .add-payment-button {
    gap: 10px;
    margin-top: 0;
  }
  .payment-method-image {
    width: 24px;
  }

  .social-logins-section .social-login-name,
  .social-logins-section .connect,
  .social-logins-section .disconnect,
  .deleteAccountSection .deleteButton,
  .quick-links-section .quick-link-button,
  .address-section .address-text,
  .deleteAccountSection .description {
    font-size: 1rem;
  }
  .social-logins-section .social-login-icon {
    width: 24px;
    height: auto;
    object-fit: contain;
  }
  .mainContent .notifications-section {
    margin-top: 24px;
  }
  .mainContent .quick-links-section {
    margin: 24px 0;
  }
  .quick-links-section .quick-link-button {
    padding: 10px 15px;
  }
}

@media (max-width: 899px) {
  .MuiContainer-maxWidthLg.profile.css-kb43wd-MuiContainer-root {
    padding: 0 50px;
  }
}

@media (max-width: 600px) {
  .MuiContainer-maxWidthLg.profile.css-kb43wd-MuiContainer-root {
    padding: 0 20px;
  }
  .sidebarContent .profile-sidebar {
    padding: 20px 0;
  }
  .profile-sidebar .profile-image-container {
    width: 90px;
    height: 90px;
  }
  .profile-sidebar .profile-edit-icon {
    right: 0;
    bottom: 0;
  }
  .profile-sidebar .profile-label {
    margin-top: 10px;
  }
  .profile-sidebar .profile-value {
    margin-top: 5px;
  }
  .profile-sidebar .divider-bottom {
    margin-top: 20px;
  }
  .profile-sidebar .logout-btn {
    font-size: 16px;
    line-height: 20px;
  }
  .profile-sidebar .logout-icon {
    width: 20px;
    height: 20px;
  }
  .profile-sidebar .logout-btn {
    margin-top: 15px;
  }
  .mainContent .address-section {
    padding: 20px;
  }
  .address-section .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .address-section .title,
  .payment-methods-section h5,
  .social-logins-section .section-title,
  .notifications-section h5,
  .deleteAccountSection .heading {
    font-size: 16px;
  }
  .address-section .add-button,
  .button-text {
    font-size: 16px;
    padding: 0;
    margin-top: 10px;
  }
  .css-ukgist {
    margin-top: 0 !important;
  }
  .address-section .icon {
    width: 20px;
  }
  .payment-methods-section {
    padding: 20px;
    margin-top: 20px;
  }
  .payment-methods-section .description {
    font-size: 16px;
  }
  .payment-method-image {
    width: 20px;
    margin-top: 10px;
  }
  .social-logins-section {
    margin-top: 20px;
  }
  .social-logins-section {
    padding: 20px;
  }
  .mainContent .notifications-section {
    padding: 20px;
  }
  .notifications-section .notification-description {
    margin-bottom: 0;
  }
  .notifications-section .notification-setting p {
    font-size: 14px;
    line-height: 20px;
  }
  .notifications-section .notification-setting {
    flex-wrap: nowrap;
  }
  .mainContent .deleteAccountSection {
    margin-top: 20px;
    padding: 20px;
  }
  .deleteAccountSection .description {
    text-align: left;
  }
  .quick-links-section .quick-link-button {
    width: 100%;
    padding: 5px;
    border-radius: 10px;
  }
}
