.appointment-page .appointment-card {
  border-radius: 16px;
}
.appointment-page section:nth-child(2) .onlyBottom {
  display: none;
}

.appointment-page .card-container {
  display: flex;
  gap: 25px;
  cursor: pointer;
}

.manageAppt .card-container {
  display: flex;
  gap: 1rem;
  height: 120px;
}

.appointment-page .price {
  opacity: 40%;
  font-size: 14px;
  padding-top: 5px;
  color: #231f20;
}

/* .image-container {
  flex: 41%;
} */

.manageAppt .appointment-image {
  width: 100%;
  height: auto;
  border-radius: 0;
  object-fit: cover;
}

.appointment-page .appointment-image {
  width: 100%;
  height: auto;
  border-radius: 0;
  object-fit: contain;
  border-radius: 20px 0px 0px 20px;
}

.manageAppt .appointment-image {
  width: 100%;
  height: auto;
  border-radius: 0;
  object-fit: cover;
}

.appointment-card .getDirectionBtn {
  border: 1px solid #4e297b;
  border-radius: 20px;
  color: #4e297b;
  text-transform: capitalize;
  margin: 10px 0;
  line-height: 1;
}

#root .appointment-page .content-container {
  flex: 59%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.manageAppt .content-container {
  flex: 59%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0px;
}

.appointment-page .content-container .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
}

.manageAppt .content-container .title {
  font-size: 16px;
}

.appointment-page .content-container p.date {
  font-size: 14px;
  color: #231f20;
  padding-top: 5px;
  line-height: 16px;
}

.manageAppt .content-container p.date {
  font-size: 12px;
  color: black;
}

.appointment-page .title {
  font-weight: 600;
}

.appointment-page .content-container .book-again {
  color: #4e297b; /* Adjust to your theme */
  margin-top: 5px;
  cursor: pointer;
  font-size: 14px;
  width: 30%;
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .appointment-page .card-container {
    gap: 0px;
  }
  .appointment-page .content-container .title {
    padding-right: 10px;
  }
}
@media screen and (min-width: 900px) and (max-width: 1024px) {
  .appointment-page .card-container {
    gap: 0px;
  }
  .appointment-page .content-container .title {
    font-size: 11px;
  }
  .appointment-page .content-container p.date {
    font-size: 9px;
  }
  .appointment-page .content-container .price {
    font-size: 9px;
  }
  .appointment-page .content-container {
    padding: 6px 0px 0px 10px;
  }
  .appointment-page .date {
    margin-top: 0px;
  }
  .appointment-page .content-container .book-again {
    font-size: 9px;
  }
}
