.social-logins-section {
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-top: 32px;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #707070;
  
}

.social-logins-section .section-title {
  align-self: flex-start;
  font-size: 24px;
  font-weight: 500;
  color: black;
}

.social-logins-section .section-description {
  align-self: flex-start;
  margin-top: 8px;
  font-size: 16px;
  color: black;
  opacity: 60%;
}

.social-logins-section .social-login-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.social-logins-section .social-login-details {
  display: flex;
  align-items: center;
  gap: 16px;
}

.social-logins-section .social-login-icon {
  width: 30px;
  height: 60px;
  object-fit: contain;
}

.social-logins-section .social-login-name {
  font-size: 18px;
  font-weight: 500;
  color: black;
}

.social-logins-section .social-login-button {
  padding: 9px 24px;
  font-size: 1.2rem;
  border-radius: 25px;
  line-height: 1;
}

.social-logins-section .disconnect {
  color: #e53935;
  border-color: #e53935;
  text-transform: capitalize;
}

.social-logins-section .connect {
  color: #4a4a4a;
  border-color: #4a4a4a;
  text-transform: capitalize;
}

.social-logins-section .social-login-divider {
  margin-top: 16px;
  height: 1px;
  background-color: rgba(35, 31, 32, 0.3);
}
@media (max-width: 1199px) {
  .social-logins-section {
    margin-top: 24px;
  }
}
