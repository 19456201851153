.button-box .custom-button {
  padding: 16px;
  border-radius: 24px;
  width: fit-content;
}

.custom-button .custom-button.outline {
  color: #3f51b5; /* blue-600 */
  border: 1px solid #3f51b5; /* blue-600 */
}

.button-box button .cancel {
  height: 40px;
}
.button-box button .continue {
  margin-top: 0px;
}

.custom-button .custom-button.filled {
  background-color: #3f51b5; /* blue-600 */
  color: white;
}

@media (max-width: 768px) {
  .custom-button {
    padding: 12px; /* Adjust padding for smaller screens */
  }
}
