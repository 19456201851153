.beautyspotContainer{
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
  border-radius: 15px;
  cursor: pointer;
}
.beautyspotContainer .imageSection img.mainImage{
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  max-height: 250px;
  object-fit: cover;
}
.beautyspotContainer .contentSection{
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.beautyspotContainer .contentSection .infoSection{
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}
.beautyspotContainer .contentSection .infoSection .titleContent {
  width: calc(100% - 85px);
}

.beautyspotContainer .contentSection .infoSection .salonName{
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.beautyspotContainer .contentSection .infoSection .salonType{
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  opacity: 40%;
  padding-top: 10px;
}
.beautyspotContainer .contentSection .locationContent{
  padding-left: 20px;
}
.beautyspotContainer .contentSection .locationContainer{
  display: flex;
  padding-top: 10px;
  
  align-items: flex-start;
}
.beautyspotContainer .contentSection .locationContainer p.locationAddress{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 10px;
}
.beautyspotContainer .contentSection .ratingContainer{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.beautyspotContainer .contentSection .ratingContainer .ratingText{
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding-right: 10px;
  padding-top: 4px;
}
.beautyspotContainer .contentSection .ratingContainer img{
  width: 25px;
  line-height: 1;
}
@media (max-width: 1280px) {
  .beautyspotContainer .contentSection .infoSection .titleContent {
    width: calc(100% - 80px);
  }
  .beautyspotContainer .contentSection .locationContainer img {
    width: 20px;
  }
  .beautyspotContainer .contentSection .infoSection .salonName{
    font-size: 16px;    
  }
  .beautyspotContainer .contentSection .ratingContainer img {
    width: 20px;
  }
}
@media (max-width: 1199px) {
  .beautyspotContainer .contentSection .ratingContainer img{
    width: 20px;
}
.beautyspotContainer .contentSection {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}
.beautyspotContainer .contentSection .locationContainer p.locationAddress{
    /* font-size: 12px; */
}
.beautyspotContainer .contentSection .infoSection{
  padding-left: 15px;
}
.beautyspotContainer .contentSection .locationContent{
  padding-left: 15px;
}
}
@media (max-width: 899px) {  
  .beautyspotContainer .contentSection .locationContainer p.locationAddress{
    font-size: 14px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .title {
    font-size: 16px;
    line-height: 18px;
  }
  
}
