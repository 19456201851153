.subscriptionPage {
    margin-bottom: 1.875rem;
    margin-top: 0;
}

.MuiTypography-root.subscriptionHeader {
    font-family: 'Poppins', sans-serif;
    background: #f6f6f6;
    color: #231F20;
    font-weight: 300;
    padding: 145px 100px;
    font-size: 60px;
    line-height: 90px;
    text-align: center;
}

.MuiTypography-root.subscriptionHeader.subscriptionHeader_subtitle {   
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 60px;
    line-height: 90px;
    text-align: center;
} 

/* .subscrition{
    padding: 70px 100px 50px 100px !important;
    gap: 50px !important;
    display: flex;
    flex-direction: column !important;
} */

.subscriptionHeader_subtitle {}

.MuiGrid-root.subscriptionPlan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 100px;
}

.MuiGrid-root .MuiTypography-root.subscriptionPlan_title {
    font-family: Poppins;
font-weight: 400;
font-size: 50px;
}
.subscriptionPage .titleText {
    width: 60%;
    font-size: 18px;
    line-height: 26px;
}

.MuiGrid-root .subscriptionPlan_title .subscriptionPlan_span_title {
    font-family: Poppins;
font-weight: 700;
font-size: 50px;
}

.MuiGrid-root.plan_container {
        display: flex;        
        padding: 0 100px;
        gap: 30px;
        
}
.subscriptionTabs {
    display: flex;
    justify-content: flex-end;
}


.subscrition-box {
  gap: 40px !important;
}

.subscrition-headeing{
    display: flex !important;
    align-items: end !important;
    justify-content: flex-end !important;
    flex-direction: column !important;
}

.contact-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;    
}
.subscriptionBlock {
    padding: 0 100px 100px;
}

@media (max-width: 1600px) {
    .subscriptionPage .titleText {
        width: 80%;
    }
    .subscriptionBlock {
        display: flex;
    }
}

@media (max-width: 1360px) {
    .subscriptionPage .titleText {
        width: 90%;
    }
}

@media (max-width: 1280px) {
    .subscriptionPage .titleText {
        width: 80%;
    }
    .MuiTypography-root.subscriptionHeader {
        padding: 100px;
        font-size: 50px;
        line-height: 80px;
    }
    .MuiGrid-root .MuiTypography-root.subscriptionPlan_title, .MuiGrid-root .subscriptionPlan_title .subscriptionPlan_span_title {
        font-size: 40px;
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .plan_amount {
        font-size: 36px;
    }
    .faq-container-wrapper .faqTitle {
        font-size: 40px;
    }
    
}

@media (max-width: 1199px) {
    .MuiTypography-root.subscriptionHeader {
        padding: 70px;
        font-size: 40px;
        line-height: 55px;
    }
    .MuiGrid-root.subscriptionPlan {
        padding: 50px 70px;
    }
    .MuiGrid-root .MuiTypography-root.subscriptionPlan_title, .MuiGrid-root .subscriptionPlan_title .subscriptionPlan_span_title {
        font-size: 30px;
    }
    .subscriptionPage .titleText {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
    }
    .plan_type .plan_button {
        min-width: 115px;
    }
    .MuiGrid-root.plan_container {
        padding: 0 70px;
    }
    .MuiGrid-root.plan_container {
        gap: 20px;
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .plan_amount {
        font-size: 30px;
        line-height: 45px;
        margin-top: 20px;
    }
    #package {
        font-size: 16px;
        line-height: 24px;        
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .plan_title {
        margin: 20px 0;
        font-size: 16px;
        line-height: 24px;
    }
    .plan_description_container.MuiBox-root {
        padding-left: 20px;
    }
    .plan-type {
        font-size: 14px;
        line-height: 20px;
    }
    .subscriptionBlock {
        padding: 0 70px 70px;
    }
    .box-content .box-title {
        font-size: 30px;
        line-height: 40px;
    }
    .box-content .box-title {
        font-size: 16px;
        line-height: 24px;
    }
    .subscriptionBlock .box-container {
        gap: 10px;
    }
    .box-button.MuiButton-textPrimary {
        font-size: 16px;
        line-height: 18px;
    }
}
@media (max-width: 899px) {
    .MuiTypography-root.subscriptionHeader {
        padding: 50px;
        font-size: 36px;
        line-height: 45px;
    }
    .MuiGrid-root.subscriptionPlan {
        padding: 50px;
    }
    .subscriptionPlan .subscriptionTabs {
        justify-content: flex-start;
        margin-top: 10px;
    }
    .MuiGrid-root.plan_container {
        padding: 0 50px;
        flex-wrap: wrap;
    }
    .MuiPaper-root.plan_card {
        width: 48%;
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .plan_amount {
        font-size: 24px;
        line-height: 35px;
        margin-top: 20px;
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .plan_amount .planBy {
        font-size: 16px;
        line-height: 24px;
    }
    #package {
        font-size: 14px;
        line-height: 20px;
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .plan_title {
        margin: 15px 0;
    }
    .plan_type .plan_button {
        font-size: 14px;
        line-height: 20px;
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .add-plan-button {
        font-size: 14px;
        line-height: 18px;
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .setupfee-btn {
        font-size: 14px;
        line-height: 24px;
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .setupfee-btn span {
        font-size: 16px;
        line-height: 20px;
        padding-right: 3px;
    }
    .subscriptionBlock {
        padding: 0 50px 50px;
    }
    .contact-box {
        flex-wrap: wrap;
    }    
    .contact-box {
        gap: 20px;
    }
}
@media (max-width: 600px) {
    .MuiTypography-root.subscriptionHeader {
        padding: 30px 20px;
        font-size: 24px;
        line-height: 30px;
    }
    .MuiGrid-root.subscriptionPlan {
        padding: 30px 20px 0;
    }
    .MuiGrid-root .MuiTypography-root.subscriptionPlan_title, .MuiGrid-root .subscriptionPlan_title .subscriptionPlan_span_title {
        font-size: 20px;
    }
    .subscriptionPage .titleText {       
        font-size: 14px;
        line-height: 20px;
    }
    .plan_type {
        flex-wrap: wrap;
    }
    .plan_type .plan_button {
        width: 100%;
    }
    .MuiGrid-root.plan_container {
        padding: 0 20px;
    }
    .MuiPaper-root.plan_card {
        width: 100%;
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .plan_amount {
        font-size: 20px;
        line-height: 30px;
        margin-top: 15px;
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .plan_title {
        font-size: 14px;
        line-height: 20px;
        margin: 10px 0;
    }
    .plan-type {
        font-size: 13px;
        line-height: 18px;
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .add-plan-button {
        font-size: 13px;
        line-height: 16px;
        padding: 12px;
    }
    .setupWrap {
        text-align: left !important;
    }
    .MuiPaper-root.plan_card .plan-card-wrapper .plan_description_container .plan_description {
        font-size: 14px;
        line-height: 24px;
    }
}


