.favorites-page .salon-list .salon-card {
  width: 100%;
  max-width: 100%;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
}

.favorites-page .favorite-icon img {
  width: 70%;
  height: 70%;
}

.favorites-page .salon-card .image-section {
  position: relative;
  height: 350px;
  overflow: hidden;
}

.favorites-page .salon-card .salon-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
}

.favorites-page .salon-card .favorite-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
}

.favorites-page .salon-card .content-section {
  padding: 20px;
}

.favorites-page .salon-card .salonTitleRating{
  display: flex;
  justify-content: space-between;
}

.favorites-page .salon-card .salon-info {
  flex-direction: column;
  gap: 10px;
  padding-right: 0px;
  background-color: transparent;
  border-radius: 0px;
  box-shadow: none;
  width: calc(100% - 90px);
}


.favorites-page .salon-card .rating-section {
  margin-top: 0px;
}

.favorites-page .salon-card .salon-type{
  font-size: 14px;
  line-height: 17px;
  opacity: 40%;
  padding-bottom: 10px;
}
.favorites-page .salon-card .location-section p{
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
}

.favorites-page .salon-card .rating-section p{
  font-size: 14px;
  line-height: 17px;
  padding-top: 4px;
}

.favorites-page .salon-card .salon-info h6 {
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 10px;
}
.favorites-page .salon-card .location-section {
  display: flex;
  align-items: center;
  gap: 8px;
}

.favorites-page .salon-card .location-icon,
.favorites-page .salon-card .star-icon {
  width: 25px;
  height: 25px;
}

.favorites-page .salon-card .location-icon img {
  object-fit: contain;
}

.favorites-page .salon-card .rating-section {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.favorites-page .salon-card .services-section {
  padding: 16px;
  display: none;
}

.favorites-page .salon-card .service-card {
  background-color: white;
  border: 1px solid #333;
  padding: 8px;
  border-radius: 8px;
  text-align: left;
}

.favorites-page .salon-card .see-more-btn {
  margin-top: 20px;
  color: #1976d2;
  text-transform: capitalize;
  text-align: center;
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .favorites-page .service-card .MuiGrid-item {
    padding: 5px 0px 0px 3px;
  }
  .favorites-page .service-card .service-name {
    min-height: 30px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1201px) {
  .favorites-page .services-section .service-card .MuiGrid-item {
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 0px;
    padding-right: 0px;
  }
  .favorites-page .salon-card .rating-section {
    gap: 0px;
  }
  .favorites-page .salon-card .salon-info {
    width: auto;
  }
  
  .favorites-page .salon-card .see-more-btn {
    margin-top: 10px;
  }

  .favorites-page p.service-price {
    margin-top: 3px;
  }

  .favorites-page .service-card .service-name {
    min-height: 10px;
  }
  .favorites-page .salon-card .see-more-btn {
    font-size: 10px;
  }
  .favorites-page .salon-card .salon-info p.salon-type {
    font-size: 15px;
  }
  
}
@media screen and (min-width: 900px) and (max-width: 1024px) {
  .favorites-page .service-card .MuiGrid-item {
    padding: 5px 0px 0px 3px;
  }
  .favorites-page .service-card .service-name {
    min-height: 20px;
  }
  .favorites-page .salon-card .content-section {
    padding: 16px 6px 16px 6px;
  }
  .favorites-page .salon-card .see-more-btn {
    margin-top: 10px;
  }
  .favorites-page p.service-price {
    margin-top: 3px;
  }
  .favorites-page .location-section p {
    font-size: 12px;
  }
  .favorites-page .salon-card .rating-section {
    gap: 0px;
  }
  .favorites-page .salon-card .salon-info h6 {
    font-size: 14px;
  }
  .favorites-page .rating-section p {
    font-size: 11px;
  }
  .favorites-page .rating-section img {
    width: 10px;
    height: 10px;
  }
}
