.appointment-booking .date-selector-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  text-align: center;
  color: #4a4a4a; /* Customize as needed */
  font-size: 1.5rem;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.date-selector-container .navigation-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding-left: 0px;
  /* pointer-events: none !important; */
}
.date-selector-container .navigation-button.left {
  justify-content: flex-start;
}
.date-selector-container .navigation-button.right {
  justify-content: flex-end;
}

.date-selector-container .navigation-button:hover {
  background-color: transparent !important;
}

.css-8je8zh-MuiTouchRipple-root {
  display: none !important;
}

.custom-date-picker input {
  padding: 10.5px 10px;
}

.date-selector-container .icon-image {
  width: 40px;
  height: auto;
}

.date-selector-container .dates-container {
  display: flex;
  flex: 1;
  gap: 16px;
  overflow-x: auto;
  scrollbar-width: none;
}

.date-selector-container .date-box {
  /* padding: 15px 30px; */
  border: 1px solid rgba(107, 114, 128, 0.4);
  background-color: white;
  border-radius: 12px;
  transition: background-color 0.3s,color 0.3s;
  min-width: 12%;
  padding: 8px;
  text-align: center;
  cursor: pointer;
}

.date-box.selected {
  background-color: #4e297b; /* Customize as needed */
  color: white;
}
.date-box.selected:hover{
  background-color: #2e144e;
}

.date-selector-container .date-text {
  font-weight: bold;
}

.date-selector-container .day-text {
  font-weight: 300;
}
@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .date-box {
    padding: 5px 15px;
  }
  .dates-container {
    gap: 12px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1201px) {
  .date-box {
    padding: 5px 11px;
  }
  .dates-container {
    gap: 4px;
  }
  .icon-image {
    width: 30px;
  }
}
@media (max-width: 899px) {
  .date-selector-container .date-box h6 {
    font-size: 0.6rem;
  }
  .date-selector-container .date-box p {
    font-size: 0.775rem;
  }
  .date-selector-container .navigation-button.right {
    padding-right: 0px;
  }
}

@media (max-width: 480px) {
  .date-selector-container .date-box {
    min-width: 50%;
  }
}
