.service-item {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 10px;
}
.bookingTab .base-TabPanel-root .itemList {
  justify-content: space-between;
  height: 100%;
}
.bookingTab .base-TabPanel-root .itemList .MuiGrid-container {
  width: calc(100% - 150px);
}

.booking .itemList .MuiCheckbox-root{
  color:#4E297B;
  padding: 0;
}

.bookingTab .base-TabPanel-root .itemList h5 {  
  font-size: 16px;
  line-height: 20px; 
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden; 
}

.bookingTab .base-TabPanel-root{
  padding: 20px 0px;
}

.bookingTab .base-TabPanel-root .base-TabPanel-root .itemList {
  padding: 10px 15px;
  align-items: center;
}
.bookingTab .base-TabPanel-root .itemList .servicePrice {
  width: 150px;
  margin-left: 0;
  justify-content: flex-end;
}

.bookingTab .base-TabPanel-root .itemList .servicePrice h4 {
  font-size: 18px;
  line-height: 20px;
}
.bookingTab .base-TabPanel-root .itemList .timeText {
  margin-top: 5px;
  font-size: 14px;
}

.service-item.selected {
  border-color: blue;
}

@media (max-width: 1440px) {
  .bookingTab .base-TabPanel-root .itemList {
    padding: 10px;
  }
  .bookingTab .base-TabPanel-root .itemList h5 {
    font-size: 14px;
  }
  .bookingTab .base-TabPanel-root .itemList p {
    font-size: 12px;
  }
  .bookingTab .base-TabPanel-root .itemList .servicePrice h4 {
    font-size: 16px;
    line-height: 18px;
  }
  .bookingTab .base-TabPanel-root .itemList .MuiGrid-container {
    width: calc(100% - 135px);
  }
  .bookingTab .base-TabPanel-root .itemList .servicePrice {
    width: 135px;   
  }
  .bookingTab .base-TabPanel-root .itemList .timeText {
    font-size: 12px;
    line-height: 16px;
  }
  .bookingTab .base-TabPanel-root .itemList .servicePrice .checkSelect {
    width: 25px !important;
    height: 25px !important;
    border-radius: 5px !important;
  }
  /* .itemList .servicePrice span div {
    width: 36px;
    height: 36px;
    border-radius: 10px;
  }
  .itemList .servicePrice img {
    width: 36px;
    height: 36px;
    border-radius: 10px;
  } */
  
  
}



@media (max-width: 1199px) {
  /* .itemList .MuiGrid-container {
    width: 43%;
  }
  .itemList h5 {
    font-size: 16px;
  }
  .bookingTab .itemList h5 {
    font-size: 9px;
  }
  .bookingTab .itemList{
    gap: 0px;
    border-radius: 8px;
  }
  .booking .servicePrice .MuiBox-root{
    width: 15px;
    height: 15px;
    border-radius: 5px;
  }
  

  .itemList h4 {
    font-size: 22px;
  }
  .itemList .servicePrice h4 {
    font-size: 13px;
    margin-right: 0;
  }
  .itemList .servicePrice img {
    width: 80%;
    height: 80%;
  }
  .itemList .servicePrice span div {
    width: 18px;
    height: 18px;
    border-radius: 7px;
  }
  .bookingTab .base-TabsList-root {
    justify-content: left;
  }
  .bookingTab .MuiGrid-container {
    margin-top: 0px;
  }

  .bookingTab .servicePrice .MuiCheckbox-root {
    padding: 0px;
  }
  .booking .servicePrice .Mui-checked {
    padding: 0px;
  }

  .itemList h5 {
    font-size: 10px;
  }
  .itemList .css-rcf17g {
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }
  .itemList .css-1pqm26d-MuiAvatar-img {
    width: 20px;
    height: 20px;
  }
  .itemList .css-4muqlg-MuiGrid-root {
    margin-top: 0px;
  } */ 
}


@media (max-width: 899px) {
  /* .itemList .MuiGrid-container {
    width: 50%;
  }
  .itemList .servicePrice h4 {
    font-size: 9px;
  }
  .itemList h5 {
    font-size: 12px;
  }
  .booking .bookingTab p {
    font-size: 11px;
  }

  .booking .servicePrice .Mui-checked {
    padding: 0px;
  }

  .bookingTab button p {
    font-size: 9px;
    line-height: 16px;
  }
  .bookingTab .base-TabPanel-root {
    padding-top: 0px;
  }
  .bookingTab .base-TabPanel-root .MuiGrid-container {
    margin-top: 0px;
  }
  .itemList .css-rcf17g {
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }
  .itemList .css-1pqm26d-MuiAvatar-img {
    width: 20px;
    height: 20px;
  } */
  /* .itemList .servicePrice span div, .itemList .servicePrice img {
    width: 24px;
    height: 24px;
    border-radius: 5px;
  }
  .itemList .servicePrice h4 {
    font-size: 18px;
  } 
  .bookingTab .itemList p {
    font-size: 14px;
  } */
}

@media (max-width: 600px) {
  .bookingTab .base-TabPanel-root .itemList .timeText {
    text-align: left;
  }
  .bookingTab .base-TabPanel-root .itemList .servicePrice {
    gap: 5px;
  }
  .bookingTab .base-TabPanel-root .itemList .servicePrice {
    width: 125px;
  }
  .bookingTab .base-TabPanel-root .itemList .MuiGrid-container {
    width: calc(100% - 125px);
  }
}
