.breadcrumb-container {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 1rem;
  line-height: 1.5;
  color: #494949;
  margin-top: 0px;
}

.breadcrumb-logo {
  object-fit: contain;
  flex-shrink: 0;
  aspect-ratio: 1;
  width: 30px;
  cursor: pointer;
}

.breadcrumb-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.breadcrumb-items .breadcrumb-label {
  color: black;
  font-weight: 400;
  opacity: 30%;
  line-height: 19.2px;
}

.breadcrumb-separator {
  margin: 0 8px;
  color: #999999;
}

.breadcrumb-items .breadcrumb-label.breadcrumb-last {
  font-weight: 600;
  color: black;
  opacity: 100%;
}

@media (max-width: 600px) {
  .breadcrumb-container {
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
  }
  .reviews-container .MuiGrid-container{
    margin-top: 0px;
  }

  

  .breadcrumb-label {
    font-size: 0.9rem;
  }
}
