.appointment-page .title {
  font-size: 1.25rem;
  color: black;
  white-space: nowrap;
}

.appointment-page .count-chip {
  padding: 3px 10px;
  text-align: center;
  border-radius: 50px;
}

.appointment-page .count-chip.upcoming {
  background-color: #4e297b; /* Blue */
  color: white;
  font-size: 18px;
  margin-left: 10px;
}

.appointment-page #full-width-tabpanel-0 > .MuiBox-root {
  padding: 0;
}
.appointment-page #full-width-tabpanel-1 > .MuiBox-root {
  padding: 0;
}

.appointment-page .count-chip.past {
  background-color: #4e297b;
  color: white;
  border: 1px solid #4e297b;
  font-size: 18px;
  margin-left: 10px;
}
.appointment-page .onlyBottom {
  justify-content: center;
}

.noAppointment {
  border: 1px solid black;
  padding: 70px 20px 70px 20px;
  border-radius: 20px;
  margin: 30px 0;
}
.noAppointment h1 {
  font-size: 30px;
  font-weight: 600;
  padding-top: 30px;
  line-height: 34.8px;
}

.noAppointment p {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  padding-top: 20px;
}

.noAppointment .searchSalon {
  background-color: #4e297b;
  color: white;
  border-radius: 5px;
  margin-top: 25px;
  text-transform: capitalize;
  font-weight: 300;
}
.noAppointment .searchSalon:hover {
  background-color: #2e144e;
}

.onlyBottom .MuiButton-root {
  background: #4e297b;
  color: #fff;
}
.onlyBottom .MuiButton-root:hover{
  background-color: #2e144e;
}

.onlyBottom .MuiButton-root.Mui-disabled {
  opacity: 0.5;
  color: white;
}
