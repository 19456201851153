.mainContent .quick-links-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: #57534e; /* equivalent of text-stone-800 */
  margin-bottom: 30px;
}

.quick-links-section .quick-link-button {
  flex-grow: 1;
  padding: 16px 64px;
  background-color: white;
  border-radius: 16px;
  border: 1px solid #57534e; /* border-stone-800 */
  width: 48%;
  text-transform: capitalize;
  color: #000;
  font-size: 20px;
}
.quick-links-section .quick-link-button:hover{
  background-color: transparent;
  border: 1px solid #57534e; /* border-stone-800 */
}

@media (max-width: 768px) {
  .quick-links-section .quick-link-button {
    padding: 16px 20px;
    max-width: 100%;
  }
}
