.teamContainer h2{
  font-size: 36px;
  line-height: 43px;
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: 0px;
}
.team{
  width: auto;
  height: auto;
  position: relative;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #231F20;
  padding: 16px; 
  display: flex;
  align-items: center;
  gap: 20px; 
  margin-top: 40px;
}
.team img{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}
.team p{
  color: #231F20;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  /* lineHeight: '1.5',
  // textOverflow: 'ellipsis',
  // overflow: 'hidden',
  // whiteSpace: 'nowrap', */
}
.team-member {
  display: flex;
  flex-direction: column;
  /*width: 25%; */
  margin: 0 auto;
}

.team-member-card {
  display: flex;
  gap: 16px;
  padding: 16px;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #4b5563;
  color: #4b5563;
}

.team-member-avatar {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.team-member-name {
  margin: auto 0;
  width: 116px;
}

.teamNotFound{
  text-align: center;
  color: #231F20;
  font-size: 1rem;
  font-family: 'Poppins';
  font-weight: 500;
  margin-top: '20px';
  margin-left: '20px';
}

@media (max-width: 1600px) {
  .team img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 1440px) {
  .team {
    gap: 10px;
    margin-top: 0;
  }
  .team img {
    width: 40px;
    height: 40px;
  }
  .team p{
    font-size: 12px;
  }
}
@media (max-width: 1199px) { 
  .team img {
    width: 35px;
    height: 35px;
  }
  
}
@media (max-width: 768px) {
  .team-member {
    width: 100%;
    margin-top: 16px;
  }
}
@media (max-width: 480px) {
  .team p{
    font-size: 14px;
  }
}
