.mainContent .address-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #44403c;
}

.address-section .add-button{
  font-size: 1.3rem;
}

.address-section p img {
  width: 15px;
}
.address-section p img:hover {
  cursor: pointer;
}

.address-section .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.address-section .title {
  font-size: 1.5rem;
  font-weight: 500;
  color: black;
}

.address-section .add-button {
  display: flex;
  align-items: center;
  color: #4E297B;
  text-transform: capitalize;
}

.address-section .icon {
  width: 30px;
  object-fit: contain;
}

.address-section .subtitle {
  margin-top: 20px;
  font-weight: 600;
  color: #44403c;
}

.address-section .address-text {
  margin-top: 4px;
  color: #44403c;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .address-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}


