.coupon-card {
  max-width: 450px;
  margin: 2rem auto;
  padding: 1.25rem;
  border: 1px solid #4b5563;
  border-radius: 12px;
  background-color: #fff;
}

.coupon-content {
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.coupon-text {
  flex-grow: 1;
}

.coupon-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #4b5563;
}

.coupon-description {
  margin-top: 1rem;
  color: #4b5563;
}

.coupon-description.locked {
  opacity: 0.5;
}

.coupon-code {
  margin-top: 1.25rem;
  padding: 0.75rem;
  text-align: center;
  border: 1px dashed #000;
  border-radius: 8px;
  white-space: nowrap;
}

.unlock-message {
  margin-top: 0.625rem;
  color: #dc2626;
}

.coupon-button-container {
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
}

.apply-button {
  width: 100%;
  margin-top: auto;
}

@media (max-width: 600px) {
  .coupon-content {
    flex-direction: column;
  }

  .apply-button {
    margin-top: 2rem;
  }
}
