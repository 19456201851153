.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 2px 46px rgba(0, 0, 0, 0.1);
}

.login-form-logo {
  width: 36px;  
  margin-bottom: 0;
  position: absolute;
  left: 20px;
  top: 20px;
}

.login-form-title {
  margin-top: 2rem;
  font-weight: bold;
  text-align: center;
}

.login-form-subtitle {
  margin-top: 1rem;
  text-align: center;
}

.login-form-button {
  margin-top: 2rem;
  padding: 1rem;
}

.login-form-footer {
  margin-top: 2rem;
  text-align: center;
}

.topBar .logoMobile {
  display: none;
}
.login-form p a {
  color: #4E297B;
  text-decoration: none;
}
.login-form p a:hover {
  color: #2e144e;  
}
.loginContent {
  width: 450px;
  margin-top: auto;
  margin-bottom: auto;
}

