.customerSupport .container {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  gap: 0px;
  padding: 0px 100px;
}
#root .customerSupport .container .contact-text {
  text-align: center;
}
.customerSupport .container .contact-text h1 {
  font-size: 60px;
  font-weight: 500;
  line-height: 81px;
  margin-top: 60px;
  margin-bottom: 16px;
}
#root .customerSupport .container .contact-text p {
  color: #636363;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  line-height: 26px;
}
.contact-text button {
  text-align: center;
  padding: 12px 30px;
  background: #4E297B1A;
  border: none;
  color: rgba(78, 41, 123);
  border-radius: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
}
.contact-from {
  display: flex;
  justify-content: center;
  margin-top: 82px;
  margin-bottom: 100px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}
.contactinformation {
  /* background: url('../../Assets/images/supportBackground.png'); */
  background-color: #4e297b;
  color: #fff;
  width: 30%;
  padding: 20px 58px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.contactinformation::before{
  content: '';
    width: 186px;
    height: 186px;
    background: #fff;
    opacity: 50%;
    position: absolute;
    right: 80px;
    bottom: 90px;
    /* z-index: 11111; */
    border-radius: 50%;
}

.contactinformation::after{
  content: '';
    width: 390px;
    height: 390px;
    background: #fff;
    opacity: 80%;
    position: absolute;
    right: -150px;
    bottom: -150px;
    border-radius: 50%;
}

.contactinformation h2 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 38px;

}
.contactinformation p {
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  color: rgba(201, 201, 201, 1);
}

.contactinformation .contactInfo{
  padding-top: 100px;
}

.phone-cart {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.phone-cart i {
  font-size: 20px;
}
.phone-cart p {
  padding-left: 10px;
  font-size: 17px;
  color: #fff;
  font-family: Poppins;
  margin-top: 0px;
  margin-bottom: 0px;
}

.phone-cart p a {
  padding-left: 10px;
  font-size: 20px;
  color: #fff;
  font-family: Poppins;
  text-decoration: none;
}

.email-cart {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
.email-cart p {
  padding-left: 10px;
  font-size: 17px;
  color: #fff;
}

.email-cart p a {
  padding-left: 10px;
  font-size: 20px;
  color: #fff;
  font-family: Poppins;
  text-decoration: none;
}

.loc-cart {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding-bottom: 300px;
}
.loc-cart img{
  width: 35px;
}

.loc-cart p {
  font-size: 20px;
  color: #fff;
  margin-top: 0px;
}
.contactinformation-icon{
  margin-bottom: 31px;
}

.contactinformation-icon i {
  padding: 9px 9px 9px 9px;
  margin: 0px 10px 0px 10px;
  font-size: 15px;
  margin-top: 0px;
  border-radius: 60px;
  background-color: #fff7f7;
  color: #000;
  transition: all linear 0.2s;
}

.select-subject-cart {
  background-color: rgba(248, 248, 248, 1);
 /* width: 50%; */
  padding: 33px 25px 0px 58px;

}

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 10px 0;
}

.custom-checkbox input[type='checkbox'] {
  display: none; /* Hide the default checkbox */
}

.custom-checkbox .checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50px; /* Add border radius */
  background-color: #f9f9f9; /* Default background color */
  margin-right: 10px;
  display: inline-block;
  position: relative; /* Needed for positioning the checkmark */
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.custom-checkbox input[type='checkbox']:checked + .checkbox {
  background-color: rgba(78, 41, 123, 1); /* Background color when checked */
  border-color: rgba(78, 41, 123, 1); /* Border color when checked */
}

.custom-checkbox .checkbox::after {
  content: ''; /* Empty content for the checkmark */
  position: absolute;
  top: 40%;
  left: 50%;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg); /* Position and rotate the checkmark */
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s;
}

.custom-checkbox input[type='checkbox']:checked + .checkbox::after {
  opacity: 1; /* Show the checkmark when checked */
}

.select-subject-cart h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.form1 {
  margin-bottom: 30px;
}
.form1 label.supportRadio {
  padding-right: 7px;
  color: #8d8d8d;
  float: left;
}
.form1 {
  position: relative;
  margin-top: 20px;
}

.form1 label.supportRadio {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  color: #636363;
}

.form1 label.supportRadio.select {
  color: #000000;
}

.form1 input[type='radio'] {
  display: none;
}

.form1 input:-webkit-autofill{
  -webkit-box-shadow:0 0 0 100px rgba(248, 248, 248, 1) inset;
}

.form1 label.supportRadio span.supportRadioSpan {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
  width: 22.7px;
  height: 22.4px;
  border-radius: 50%;
  margin-right: 8px;
}

.form1 label.supportRadio span.supportRadioSpan.select {
  border: 1px solid #4e297b;
  background-color: #4e297b;
}

.form1 label.supportRadio .supportRadioSpan svg {
  height: 16px;
  width: 16px;
}

.lader-cart-1 {
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
  width: 100%;

}
.lader1{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 56px;
}

.lader2 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.lader-cart-2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 65px;
}

.lader3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 56px;
}

.lader4 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.lader4 .error{
  padding-top: 10px;
}

.lader4 .flagContainer{
  display: flex;
  align-items: center;
}

.lader5 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 54px;
}


.lader1 label,
.lader2 label, .lader3 label,
.lader4 label,
.lader5 label {
  color: #000;
}

.lader1 input,
.lader2 input,
.lader3 input,
/* .lader4 input, */
.lader5 textarea {
  border: none;
  background-color: rgba(248, 248, 248, 1);
  border-bottom: 1px solid #8d8d8d;
  width: 100%;
  display: block;
  margin: 10px 0px 10px 0px;
  padding: 6px;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins';
  color: #8d8d8d;
}



.lader4 .makeStyles-container-1 {
  border-bottom: 1px solid #8d8d8d;
  display: flex;
  font-size: 16px;
  background-color: transparent;
  padding: 0;
  margin: 15px 0 0 0px;
}

.lader5 label {
  margin-top: 30px;
}

input#lastName:focus {
  border-bottom: 1px solid;
}

#message {
  padding-left: 0px;
}
#message::placeholder {
  font-family: Poppins;
  margin-left: 0px;
  padding-left: 0px;
}

.myClass {
  padding-left: 5px;
}

.lader5 input {
  border: none;
  background-color: rgba(248, 248, 248, 1);
  border-bottom: solid #000 1px;
  width: 100%;
  display: block;
  margin: 10px 0px 19px 0px;
  padding: 6px;
  font-size: 15px;
}

.form1 .error {
  margin: 0;
  color: rgb(211, 47, 47);
  font-size: 12px;
}

.form1 button {
  padding: 12px 20px 12px 20px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  background-color: rgba(78, 41, 123, 1);
  color: #fff;
  border: none;
  border-radius: 10px;
  margin-top: 60px;
  cursor: pointer;
  display: flex;
}
.form1 button:hover{
  background-color: #2e144e
}

.form1 .last{
  display: flex;
  justify-content: space-between;
}
.form1 .last p{
  font-size: 20px;
  color: #4e297b;
}


@media (max-width: 1600px) {
  .loc-cart{
    padding-bottom: 200px;
  }
  .contactinformation .contactInfo {
    padding-top: 50px;
  }
}
@media (max-width: 1440px) {
  .loc-cart{
    padding-bottom: 200px;
  }
  .contactinformation h2{
    font-size: 30px;
  }
  .contactinformation p{
    font-size: 20px;
  }
  .contactinformation .contactInfo {
    padding-top: 20px;
  }
  .phone-cart, .email-cart{
    margin-bottom: 0px;
  }
  .lader5{
    margin-top: 24px;
  }
  .lader-cart-2{
    padding-top: 45px;
  }
}
@media (max-width: 1280px) {
  .contactinformation p {
    font-size: 18px;
  }
  .form1 label.supportRadio{
    margin-right: 15px;
  }
}

@media (max-width: 899px) {
  .lader1 input,
  .lader2 input,
  .lader3 input,
  .lader4 input {
    width: 90%;
  }
  .lader4 .makeStyles-container-1{
    width: 90%;
  }
  .lader5{
    width: 95%;
  }

  .contactinformation-icon i {
    font-size: 14px;
    margin-top: 20px;
  }
  .customerSupport .container{
    padding: 0px 20px;
  }
  .contactinformation{
    padding: 0px 20px;
  }
  .select-subject-cart{
    padding: 0px 20px;
  }
}
@media (max-width: 599px) {
  .contactinformation {
    width: 100%;
  }
  .customerSupport .container{
    padding: 0px 20px;
  } 
  .customerSupport .container .contact-text h1{
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .contact-from{
    margin-top: 20px;
  }
  
  .contactInfo img{
    width: 25px;
  }

  .contactinformation{
    padding: 0px 20px;
    margin-top: 10px;
  }
  .contactinformation h2{
    font-size: 25px;
    margin-top: 10px;
  }
  .contactinformation p{
    font-size: 16px;
    line-height: 30px;
    margin: 0px;
  }
  .contactinformation p a{
    font-size: 16px;
  }

  .contactinformation .contactInfo{
    padding-top: 20px;
  }
  .contactinformation::before, .contactinformation::after{
    display: none;
  }
  .loc-cart{
    padding-bottom: 40px;
  }

  .phone-cart, .email-cart{
    margin-bottom: 10px;
  }
  .email-cart p a{
    padding-left: 20px;
  }

  .select-subject-cart{
    padding: 0px 20px;
  }
  .form1 label.supportRadio{
    padding-bottom: 10px;
  }
  .lader5 {
     width: 100%; 
     margin-top: 0px;
  }
  .lader-cart-1, .lader-cart-2{
    flex-direction: column;
  }
  .lader-cart-1{
    padding-top: 30px;
  }
  .lader-cart-1 .lader1,  .lader-cart-2 .lader3{
    padding-right: 0px;
  }
  .lader1 input, .lader2 input, .lader3 input, .lader4 input, .lader4 .makeStyles-container-1 {
     width: 100%; 
  }
  .lader1 input, .lader2 input, .lader3 input, .lader5 textarea{
    margin: 0px 0px 10px 0px;
  }
  .lader-cart-2{
    padding-top: 0px;
  }
  .contactinformation-icon i{
    margin: 0px 10px 0px 0px;
  }
  .lader2 label, .lader3 label, .lader4 label{
    padding-top: 15px;
  }
  .form1 .last{
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .form1 button{
    margin-top: 20px;
  }
  
  .form1 .last p{
    font-size: 14px;
    margin: 0px;
  }
}
