.payment-methods-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 80px 32px 24px;
  margin-top: 32px;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 500;
  color: black;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #525252;
}

.payment-methods-section h5{
  font-size: 1.5rem;
  font-weight: 500;
}
.button-text {
  font-size: 1.5rem;
}


@media (max-width: 768px) {
  .payment-methods-section {
    padding-right: 20px;
    padding-left: 20px;
    max-width: 100%;
  }
}

.payment-methods-section .description {
  margin-top: 10px;
  font-size: 1.125rem;
  max-width: 100%;
  opacity: 60%;
  text-align: left;
}

.payment-methods-section .add-payment-button {
  display: flex;
  gap: 20px;
  padding-left: 0px;
  margin-top: 15px;
}

.payment-method-image {
  object-fit: contain;
  flex-shrink: 0;
  width: 40px;
  height: auto;
  margin-top: 15px;
}

.button-text {
  align-self: center;
  flex-grow: 1;
  text-transform: capitalize;
  color: black;
  margin-top: 15px;
  font-size: 1.2rem;
}
