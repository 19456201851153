/* LoginPage.css */

html,
body {
  height: 100%;
  margin: 0;
}

.row {
  height: 100%;
  margin: 0;
}

.login {
  padding: 0px;
  margin-top: 0px;
  height: 100vh;
  background-color: #4E297B;
}

.login .grid-container {
  display: flex;
  gap: 0;
  margin-top: 0 !important;
  height: 100%;
}

.login .login-section {
  display: flex;
  flex-direction: column;
  width: 100%;  
  height: 100%;
  padding-top: 0 !important;  
}

.login-form-container {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  padding: 177px 40px;
  background-color: #f1f5f9;
  width: 100%;
}

.logo-section {
  padding-left: 0px;
  padding-top: 0 !important;
}

.login .text-danger {
  color: #d32f2f;
  float: left;
  margin-left: 15%;
}

.logo-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* .bgPattern {
  background: url(../../Assets/images/authPatternBG.jpg) no-repeat 0 0;
} */

.brand-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* padding: 180px 64px; */
  width: 100%;
  min-height: auto;
  background: url(../../Assets/images/authPatternBG.jpg) no-repeat 0 0;
  color: white;
}

.login .makeStyles-container-1 {
  margin-top: 10px;
  display: flex;
  gap: 16px;
  padding: 16px;
  margin-top: 20px;
  max-width: 100%;
  font-size: 16px;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid rgba(35, 31, 32, 0.5);
  color: #525252;
  width: 100%;
  height: 50px;
}

.loginConfirmBox button{
  color: #4E297B;
}

.brand-image {
  max-width: 576px;
}
.login-form {
  margin-top: auto;
  margin-bottom: auto;
  max-width: 700px !important;
  height: calc(100% - 100px);
  padding: 20px !important;
  display: flex !important;
  position: relative;
}
.login-form .makeStyles-container-1 {
  padding: 5px 10px 5px 5px;
  margin-top: 0;
  margin-bottom: 20px;
  width: 100%;
}
.login-form h1 {
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  padding-bottom: 20px;
}
.login-form p.login-form-subtitle {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  margin-bottom: 30px;
  color: #414141;
}
.login-form p.login-form-footer {
  margin-top: 30px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: #414141; 
}
.login-form p.login-form-footer a {
  display: block;
  color: #4E297B;
  font-weight: 600;
  margin-top: 8px;
  text-decoration: none;  
}
.login-form p.login-form-footer a:hover {
  color: #2e144e;
}
.login-form button {
  text-transform: capitalize;
  padding: 20px 15px;
  border-radius: 10px;
  width: 100%;
  margin-top: 0;
  font-size: 18px;
  line-height: 21px;
  font-weight: 300;
  background: #4E297B; 
}
.login-form button:hover {
  background: #2e144e; 
}

.login-form p.login-form-footer.dontAccount a {
  display: inline-block;
}
.login-form input::placeholder {
  opacity: 40% !important;
  
}

.login .logo-section {
  padding-left: 0px !important;
}
.login .logo-section h4 {
  font-size: 100px;
  text-align: center;
}

@media (max-width: 960px) {
  .login-form-container {
    padding: 48px 20px;
  }

  .brand-container {
    padding: 96px 20px;
    font-size: 32px;
  }
}

@media only screen and (max-width: 899px) {
  
  .topBar .logoMobile {
    text-align: center;
   
    display: block;
  }
  .topBar .logoMobile span {
    font-weight: 800;
  }
  .login .logo-section {
    display: none;
  }
  .login-form-container {
    padding: 200px 20px;
  }
}

@media (max-width: 1600px) {
  .login-form {
    max-width: 600px !important;
  }
  .brand-image {
    max-width: 500px;
}
  .login-form-container {
    padding: 71px 2.5px;
  }  

  .login .text-danger {
    margin-left: 16%;
    margin-top: 3px;
  }
}
@media (max-width: 1440px) {
  .login-form-container {
    padding: 74.5px 4rem;
  } 
  
  .login .text-danger {
    margin-left: 3%;
    margin-top: 3px;
  }
}
@media (max-width: 1366px) {
  .login-form {
    max-width: 550px !important;
  }
  .brand-image {
    max-width: 450px;
}
  .login-form-container {
    padding: 77px 3.5rem;
  }
  
  .login .text-danger {
    margin-left: 4%;
    margin-top: 3px;
  }
}

@media (max-width: 1280px) {
  .login-form-container {
    padding: 79.5px 5.5rem;
  }  
  .login .text-danger {
    margin-left: 5%;
    margin-top: 3px;
  }
}

@media (max-width: 1200px) {
  .login-form-container {
    padding: 118.5px 3.5rem;
  }  
  .login-form p.login-form-footer {
    margin-bottom: 0px;
  }
  .login .text-danger {
    margin-left: 5%;
    margin-top: 3px;
  }
}


@media (min-width: 1600px) {
  .login-form-container {
    padding: 176.5px 6rem;
  }
}

/* For standard displays */
/* @media (min-width: 1240px) and (min-height: 472px) and (max-resolution: 96dpi) {
  .login-form-container {
    padding: 65.5px 5rem;
  }
  .login-form p.login-form-subtitle{
    margin-bottom: 1.5rem;
  }
  .login-form button{
    padding: 6px 16px;
  }
  .login-form p.login-form-footer{
    margin-bottom: 20px;
  }
  .makeStyles-flagImg-10{
    width: 25px;
  }
}  */

@media screen and (max-width: 1199px) {
  .login .grid-container {
    height: 100vh;
  }
  .login-form-container {
    box-shadow: none !important;
    display: flex;
  } 
  .login .text-danger {
    margin-left: 6%;
    margin-top: 3px;
  }
  .login-form-logo {
    width: 40px;
  }
  .login-form h1 {
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 18px;
  }
  .login-form p.login-form-subtitle {
    font-size: 16px;
    line-height: 24px;    
    margin-bottom: 30px;    
  }  
  .login-form p.login-form-footer {
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;    
  }
  .loginContent {
    width: 100%;
  }
  .login-form {
    max-width: 90% !important;
  }
  .brand-image {
    max-width: 370px;
  }
}

@media screen and (max-width: 600px) {
  .register {
    height: 100%;
    padding: 25px 0;
  }
  .login .grid-container {
    min-height: auto;
    height: auto;
  }
  .login-form button {
    padding: 16px 15px;
  }
  .login-form-container {
    padding: 20px !important;
    box-shadow: none !important;
  }
  .login-section {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
  .login .text-danger {
    margin-left: 8%;
    margin-top: 3px;
  }
  .topBar .logoMobile img {
    width: 150px;
  }
  .loginContent {
    margin: 50px auto 0;
  }
  .login-form {
    height: auto;
    padding: 30px 20px !important;
  }
  .login-form p.login-form-footer {
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
  }
  .login-form .makeStyles-container-1 {
    margin-bottom: 20px;
  }
  .login-form p.login-form-subtitle {    
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .login-form h1 {
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 10px;
  }
  .login-form button {
    font-size: 18px;
    line-height: 20px;
  }
  .login-form-logo {
    width: 30px;
    top: 30px;
  }
}
