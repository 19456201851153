/* DeleteAccountSection.css */
.mainContent .deleteAccountSection {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  padding: 32px;
  margin-top: 32px;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #4b5563; /* Stone-800 equivalent */
}

.deleteAccountSection .textContainer {
  display: flex;
  flex-direction: column;
  color: black;
}

.deleteAccountSection .heading {
  align-self: start;
  font-size: 1.5rem; /* equivalent to text-2xl */
  font-weight: 500;
}

.deleteAccountSection .description {
  margin-top: 10px;
  font-size: 1.125rem; /* equivalent to text-lg */
}

.deleteAccountSection .deleteButton {
  padding: 10px 20px;
  margin: auto 0;
  font-size: 1.25rem; /* equivalent to text-xl */
  color: #dc2626; /* Red-600 equivalent */
  border: 1px solid #dc2626;
  border-radius: 500px;
  text-transform: capitalize;
  line-height: 1;
}

.deleteAccountSection .deleteButton:hover, .social-logins-section .disconnect:hover{
  border: 1px solid #dc2626;
  background-color: transparent;
}  
.social-logins-section .connect:hover{
  border: 1px solid #4b5563;
  background-color: transparent;

}

@media (max-width: 1199px) {
  .mainContent .deleteAccountSection {
    padding: 24px;
    margin-top: 24px;
  }
}
/* Responsive styling */
@media (max-width: 768px) {
  .deleteAccountSection .deleteAccountSection {
    padding: 20px;
    max-width: 100%;
  }
}
