.address-form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
  overflow-y: auto;
}

.address-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
}

.address-form-content {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-bottom: 0;
  background-color: white;
  border-radius: 24px;
  width: 500px;
}

.address-btn button {
  border: 2px solid #000;
  margin-left: 15px;
  padding: 30px;
  border-radius: 10px;
}

.address-btn .tabs {
  border: none;
}

.address-btn span.MuiTabs-indicator {
  left: 0px !important;
  width: 0px !important;
}

.address-btn button.Mui-selected {
  border: 2px solid #4E297B;
  padding: 30px;
  border-radius: 10px;
}
.address-btn .css-1wbhak1-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #4E297B;
}

.address-form-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  max-width: 100%;
  margin-top: 10px;
}

.address-form-header h4 {
  font-size: 25px;
  font-weight: bold;
}

.address-form-title {
  font-weight: bold;
  text-align: center;
  color: black;
  margin-top: 24px;
}

.address-form-image {
  object-fit: contain;
  width: 40px;
  position: relative;
  bottom: 0px;
  left: 140px;
}

.address-form-fields {
  display: flex;
  flex-direction: column;
  padding: 0 0px;
  margin-top: 0px;
  width: 100%;
}

.address-form-select .MuiGrid-item {
  /*  padding-top: 15px; */
}

.address-find {
  display: flex;
  align-items: center;
  background-color: white;
  border: 2px solid #a1a1a1; /* stone-800 */
  border-radius: 8px;
  padding: 5px;
  margin-top: 16px;
  height: 50px;
}
.address-find-input {
  border: none;
}
.address-find-input::placeholder {
  color: #1f1f1f;
  font-family: Poppins;
  font-size: 15px;
}

.address-find-image {
  object-fit: contain;
  width: 22px;
  margin-right: 8px;
}

.address-find-label {
  flex: 1;
}

.address-form-selects {
  margin-top: 16px;
}

.address-form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
}
.address-form-buttons button:nth-child(2) {
  margin-right: 0px;
  background: #4E297B;
  color: #fff;
}
.address-form-buttons button:nth-child(2):hover{
  background: #2e144e;
}

.address-form-buttons button {
  margin-right: 15px;
  width: 100%;
  height: 50px;
  text-transform: capitalize;
  border-color: #4E297B;
  color: #4E297B;
}
@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .address-form input {
    padding: 8px 13px;
  }

  .address-form .MuiFormLabel-root {
    top: -5px;
  }

  .address-form .MuiSelect-select {
    font-size: 15px;
    padding: 8px 14px;
  }
  .address-form .address-find {
    height: 40px;
  }
  .address-form .address-find-image {
    margin-right: 0px;
    width: 15px;
  }
}
