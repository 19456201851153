/* General Styles */
.app-container {
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-hr {
  border: none; /* Remove default border */
  height: 3px; /* Adjust thickness */
  background: linear-gradient(to right, black 90%, #e2e2e2 10%);
}
.normal-hr {
  border: none; /* Remove default border */
  height: 2px; /* Adjust thickness */
  background: #e2e2e2;
}

.rating-box{
  width: 80%;
  border: 1px solid black;
  padding: 15px;
  border-radius: 15px;
  float: right;
}

.rating-box span.rating-stars{
  font-size: 45px;
}

.rating-box .ratings-summary{
  padding-top: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.score-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 30px;
  justify-content: space-between;
}

.rating-box .first span span svg ::-ms-fill{
  border: 1px solid black;
}
.rating-box .first span{
  color: black;
  padding: 0px 0px;
}


.rating-box .first{
  padding-right: 15px;
  padding-left: 10px;
}


.rating-box .second{
  padding-right: 20px;
}

.rating-box .third{
  padding-right: 20px;
}

.rating-box .third hr{
  min-width: 356px;
}

.score-card span:hover{
  background-color: transparent !important;
}

.score-section input:hover{
  background-color: transparent !important;
}


.score-box {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
  border-radius: 50%;
}

.score-box .score-text {
  font-size: 24px;
  line-height: 38px;
  color: black;
}

.image-container {
  position: relative;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.image-cover {
  object-fit: cover;
  width: 100%;
  height: 120px;
  border-radius: 8px;
}

.image-contain {
  object-fit: contain;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
}

/* Responsive Breakpoints */
@media (max-width: 768px) {
  .score-card {
    flex-direction: column;
    align-items: stretch;
  }

  .score-box {
    width: 30px;
    height: 30px;
  }

  .score-text {
    font-size: 1.2rem;
  }

  .image-cover {
    height: 100px;
  }
}


@media (max-width: 1600px) {
  .rating-box .third hr{
    min-width: 221px;
  }
}

@media (max-width: 1440px) {
  .rating-box .third hr{
    min-width: 196px;
  }
}

@media (max-width: 1366px) {
  .rating-box .third hr{
    min-width: 150px;
  }
}

@media (max-width: 1280px) {
  
  .rating-box .third hr{
    min-width: 150px;
  }
  .rating-box .ratings-summary{
    padding-top: 10px;
  }
  .score-box .score-text{
    font-size: 18px;
    line-height: 30px;
  }
  .rating-box span.rating-stars {
    font-size: 35px;
  }
}