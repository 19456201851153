.subscriptionBlock .box-container {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 30px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.box-container .box-icon {
    font-size: 50px;
    cursor: auto;
}
.box-container .box-icon:hover {
    background: transparent;
}

.box-content {
    display: flex !important;
    gap: 10px;
    flex-direction: column !important;
}

.box-content .box-title {
    color: #231f20;
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 10px;
}

.box-content .box-description {
    color: #7a7a7a;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: 10px;
}

.box-button.MuiButton-textPrimary {
    color: #fff;
    background-color: #4e297b;
    border-radius: 30px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    padding: 12px 30px;
    text-transform: none;
}
.box-button.MuiButton-textPrimary:hover {
    background: #2e144e;
}

@media (max-width: 1199px) {
    .subscriptionBlock .box-container {
        gap: 10px;
    }
    .box-content .box-title {
        font-size: 30px;
        line-height: 40px;
    }
}
@media (max-width: 899px) {
    .subscriptionBlock .box-container {
        height: auto;
        padding: 20px;
    }    
    .box-content .box-title {
        font-size: 24px;
        line-height: 30px;
    }
}
@media (max-width: 600px) {
    .box-content .box-title {
        font-size: 20px;
        line-height: 24px;
    }
    .box-content .box-description {
        font-size: 16px;
        line-height: 24px;
    }
    .box-button.MuiButton-textPrimary {
        font-size: 14px;
        line-height: 18px;
    }
}
