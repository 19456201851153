.offerContainer {
  padding: 0px 64px;
}
.offerSection .overlay img {
  width: 24px;
  margin-left: 10px;
}
/* .offerSection .overlay button:hover{background: white;} */
.offerSection img {
  width: 100%;
}
.overlay button.bookNow{
  background-color: #fff;
  color: #4e297b;
  border-Radius:10px;
  padding: 16px 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.overlay button.viewSalon{
  background-color: transparent;
  color: white;
  box-shadow: none;
  border: 1px solid white;
  margin-left: 15px;
  border-Radius:10px;
  padding: 16px 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.offerSection .overlay button.bookNow:hover{
  background-color: white;
}
@media (max-width: 1199px) {
  .css-xoq34q:hover .overlay {
    flex-direction: column;
  }
  .overlay button.bookNow {   
    padding: 12px 24px;
    font-size: 14px;
    line-height: 20px;       
}
.overlay button.viewSalon {
    padding: 12px 24px;
    font-size: 14px;
    line-height: 20px;  
    margin-top: 15px;
    margin-left: 0;
}
.offerSection .overlay img {
  width: 20px;
}
}
@media (max-width: 899px) {  
  .overlay button.bookNow {   
    padding: 10px 15px;
    font-size: 12px;
    line-height: 16px;       
}
.overlay button.viewSalon {
    padding: 10px 15px;
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
}
.offerSection .overlay img {
  width: 16px;
}
}
@media (max-width: 480px) {
  .offerContainer {
    padding: 0px 20px;
  }
  .overlay{
    flex-direction: column;
  }
  .overlay button.bookNow{
    margin-bottom:15px
  }
  .overlay button.bookNow, .overlay button.viewSalon{
    padding: 10px;
    font-size: 12px;
  }
  .overlay button.viewSalon{
    margin-left: 0px;
  }
  .offerSection .overlay img {
    width: 18px;
  }
}
