.service-categories-container {
  margin-top: 2rem;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 500;
}

.mainBox .booking {
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 30px;
  max-width: 100%;
}
.mainBox .booking .mainNavigation {
  margin-left: 0px;
}
.mainBox .booking .serviceContainer {
  width: 100%;
  margin-left: 0px;
}

.mainBox .booking .serviceContainer .serviceMainTitle {
  margin-top: 20px;
  font-weight: bold;
  text-align: left;
  font-size: 26px;
  color: black;
  margin-top: 42px;
  margin-bottom: 0px;
}

.mainBox .booking .serviceContainer .serviceContainerGrid {
  padding-left: 0px;
  padding-top: 0px;
}

#root .mainBox .beforeService {
  padding-left: 0px;
}

.booking .base-Tabs-root button {
  margin-left: 5px;
}

.service-categories-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.service-item-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.service-item-featured {
  padding: 1rem;
  color: white;
  border-radius: 30px;
  white-space: nowrap;
}

.service-image-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.service-item-image {
  width: 44px;
  height: auto;
  border-radius: 0.25rem;
  aspect-ratio: 2.59;
}

.booking .bookingTab .base--selected.activeServiceBlack {
  background-color: #231f20;
  border-radius: 50px;
}
#root .mainBox .booking .bookingTab .activeServiceBlack:hover {
  background-color: #231f20;
  border: none;
}
.booking .bookingTab .base--selected.activeServicePurple {
  background-color: #4e297b;
  border-radius: 50px;
}


#root .mainBox .booking .bookingTab .activeServicePurple:hover {
  background-color: #2e144e;
  border: none;
}
.booking .bookingTab button:click {
  border: none;
}
.bookingTab {
  justify-content: center;
  padding: 10px 10px;
}

.bookingTab p {
  font-size: 16px;
}
.service-item-text {
  margin-top: 5px;
}

.booking .bookingTab .base-TabsList-root {
  overflow-x: auto;
  display: -webkit-box;
  float: left;
  width: -webkit-fill-available;
  min-width: auto;
  margin-bottom: 0px;
}

.booking .bookingTab .base-TabsList-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  padding-left: 70px;
  padding-right: 70px;
}

.booking .bookingTab .base-TabsList-root::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
  padding-left: 70px;
  padding-right: 70px;
}

.booking .bookingTab .base-TabsList-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #929292;
  padding-left: 70px;
  padding-right: 70px;
}

.booking .bookingTab .base-TabsList-root button {
  width: auto;
  outline: none !important;
}

.bookingTab .base-TabsList-root button:hover {
  border-radius: 50px;
  background: #4e297b;
}

.bookingTab .base-TabsList-root button:hover p {
  color: white;
}

.bookingTab .base--selected p {
  color: white;
}

.bookingTab {
  margin-bottom: 0px;
  justify-content: left;
}
.bookingTab {
  padding: 0px;
  margin-top: 20px;
}

.service-item-featured {
  justify-content: left;
}

.service-item-featured p {
  color: white;
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  /* .bookingTab p {
    font-size: 13px;
  } */
  .bookingTab .css-34dgrd {
    width: auto;
  }
}
@media (max-width: 1199px) {
  .mainBox .booking {
    padding: 0px 70px;
  }
  .bookingTab p {
    font-size: 12px;
  }
  .bookingTab .css-34dgrd {
    width: auto;
    padding: 5px;
  }
  .bookingTab .css-1ju5y80 {
    padding-top: 0px;
  }
  .bookingTab {
    margin-top: 10px;
  }
  .mainBox .booking .serviceContainer .serviceMainTitle {
    font-size: 1.2rem;
    font-weight: 638;
    margin-top: 30px;
  }
}
@media (max-width: 1199px) {
  .bookingTab p {
    font-size: 15px;
  }
}
@media (max-width: 899px) {
  .mainBox .booking {
    padding: 0 50px;
  }
}
@media screen and (max-width: 600px) {
  /* .booking .bookingTab .base-TabsList-root {
    width: 100%;
  }
  .mainBox .booking {
    padding-left: 64px;
    padding-right: 10px;
    margin-top: 20px;
  }
  .booking h1 {
    margin-top: 30px;
  } */
  .mainBox .booking {
    padding: 0 20px;
  }
  .bookingTab p {
    font-size: 14px;
  }
}
