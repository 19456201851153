.container {
  display: flex;
  flex-direction: column;
}

.salon-container .flex {
  display: flex;
}

.gap-2.5 {
  gap: 0.625rem; /* Adjust the gap as needed */
}

.salon-container .font-medium {
  font-weight: 500; /* Adjust the font weight as needed */
}

.image-small {
  width: 1.563rem;
}

.containerWrap .nearBy{
  margin-left: 0px;
}

.nearByTitle h2{
  font-size: 36px;
  line-height: 43px;
  font-weight: 600;
  margin-bottom: 50px;
}

.nearBy img.rating-icon{
  width: 25px;
}
.nearBy .salon-icon{
  display: flex;
  justify-content: right;
  align-items: center;
}
.nearBy .salon-icon img{
  width: 40px;
  height: 100%;
  margin-Right: 10px;
}



.nearBy .salon-details h5{
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
}

.image-large {
  max-width: 100%; /* Responsive behavior */
  height: auto; /* Maintain aspect ratio */
}

.salon-container .rounded-full {
  border-radius: 1.875rem; /* Adjust radius for rounded corners */
}

.salon-container .object-contain {
  object-fit: contain;
}

.salon-container .shrink-0 {
  flex-shrink: 0;
}

.salon-container .aspect-square {
  aspect-ratio: 1 / 1;
}
.nearBy .salon-card {
  border-radius: 1.25rem;
}

.nearBy .salon-card .salon-info {
  width: 100%;
}

.nearBy .salon-card .salon-image {
  height: 12.5rem;
}

.nearBy .salon-details {
  padding-left: 20px;
  margin-top: 20px;
}
.nearBy .salon-info .salon-details .salon-name {
  margin-top: 0.625rem;
  width: 100%;
  margin-left: 0.625rem;
}
.nearBy p.salon-location {
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-Overflow: ellipsis;
  opacity: 40%;
  min-height: 17px;
  font-weight: 400;
  padding-top: 10px;

}
.salon-rating {
  margin-bottom: 0.625rem;
  display: flex;
  align-items: flex-start;
}
.salon-type-container {
  float: left;
  width: 25%;
}
.salon-type-container img {
  float: right;
  margin-top: 2.188rem;
  margin-right: 0.938rem;
}
.salon-type-container .salon-type {
  margin-right: 0.938rem;
  margin-top: 0.625rem;
  height: auto;
  padding: 0.313rem 0px;
}
.nearBy p.rating-text {
  margin-right: 0.313rem;
  font-size: 14px;
  margin-left: 20px;
  padding-bottom: 25px;
  margin-top: 4px;
}
@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .salon-details .salon-name {
    margin-top: 0.625rem;
  }
  .nearBy .salon-rating {
    margin-left: 0px;
  }
  .nearBy .salon-details h5 {
    min-height: 1.188rem;
  }
  .nearBy p.salon-location {
    min-height: 1.563rem;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .salon-info .salon-details .salon-name {
    margin-top: 0.625rem;
    font-size: 0.625rem;
  }
  .nearBy p.rating-text {
    margin-left: 0px;
  }
  .nearBy .salon-type-container img {
    margin-bottom: 0.938rem;
  }
  .nearBy .salon-card {
    border-radius: 0.625rem;
  }
  .nearBy .salon-info .MuiGrid-grid-xs-4 {
    padding-left: 0px;
  }
}
@media screen and (min-width: 900px) and (max-width: 1024px) {
  .salon-info .salon-details .salon-name {
    font-size: 0.563rem;
  }
  .nearBy p.salon-location {
    font-size: 0.5rem;
  }
  .nearBy p.rating-text {
    font-size: 0.688rem;
  }
  .nearBy .salon-rating {
    margin-left: 0px;
  }
  .nearBy .salon-type-container img {
    width: 1.563rem;
    margin-bottom: 1.25rem;
  }
  .nearBy .salon-info {
    padding-right: 0.188rem;
  }
  .nearBy .salon-type-container .salon-type {
    margin-right: 0px;
  }
  .nearBy .salon-type-container span {
    font-size: 0.563rem;
  }
  .nearBy .salon-info .MuiGrid-grid-xs-4 {
    padding-left: 0px;
  }
}
@media screen and (min-width: 768px) and (max-width: 880px) {
  .nearBy h2 {
    font-size: 1.188rem;
  }
}
@media screen and (max-width: 767px) {
  .nearBy p.salon-location {
    min-height: 3.125rem;
  }
}
