.shopMainDiv .slIcon {
  padding-right: 10px;
  padding-left: 0px !important;
}
/* .callMe{
  width: 100% !important;
} */
.shopMainDiv {
  padding: 50px 100px 0rem 100px;
}
.shopMainDiv .onlyMobile,
.shopMainDiv .onlyMobileSlider {
  display: none;
}
.shopMainDiv .shopTitle {
  color: var(--Black, #231f20);
  font-family: Poppins;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 48px */
}
.profile-card .shopTitleRating {
  color: var(--Black, #231f20);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 28.8px */
  margin-top: 0px;
}
#root .shopMainDiv .callNow {
  padding-left: 0px;
}

.shopMainDiv .businessTitle.withInitials {
  position: relative;
  top: 10px;
}

.shopMainDiv .serviceContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.shopMainDiv .serviceContent .mainCardBackground {
  display: flex;
  align-items: flex-start;
  height: 100%;
}


.shopMainDiv .aboutMarkerLabel {
  font-size: 12px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #000;
  position: absolute;
  top: 60px;
  left: -20px;
  z-index: 1000;
}

.shopMainDiv img.socialIcon {
  width: 40px;
  height: 40px;
}

.shopMainDiv .businessListImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.shopMainDiv img.crown {
  width: 40px;
  height: 40px;
  position: relative;
  top: 0px;
}
.shopMainDiv div.crown {
  width: 40px;
  height: 40px;
  position: relative;
  top: 8px;
  margin-right: 0.3rem;
  background-color: #4e297b;
}

.shopMainDiv h2 span.titleWithoutCrown {
  top: 8px;
}

.shopMainDiv .shopTitleRating.ratingWithoutCrown {
  margin-top: 5px;
}

.shopMainDiv .withCrown {
  font-size: 0.9rem;
  position: relative;
  left: 0rem;
  bottom: 0px;
  font-weight: 600;
  color: #ffffff;
}
.shopMainDiv .withoutCrown {
  font-size: 0.9rem;
  position: relative;
  font-weight: 600;
  color: #ffffff;
}
.shopMainDiv .TitleImage h2 span {
  position: relative;
  bottom: 10px;
}
.shopMainDiv .locationIcon {
  position: relative;
  top: 5px;
}

img.coverImage {
  object-fit: cover;
  height: 600px;
}
  
.shopMainDiv .smallImgWrap {
  line-height: 1;
}
.shopMainDiv img.smallImg {
    width: 100%;
    height: 290px;
    border-radius: 30px;
    cursor: pointer;
    object-fit: cover;
}

.shopMainDiv .tabs.MuiTabs-flexContainer {
  gap: 0.625rem;
}

.shopMainDiv .photoContainer:last-child {
  margin-bottom: 50px;
}

.loaderCcontainer {
  text-align: center;
  padding-top: 5rem;
}

.loaderCcontainer img {
  width: 5%;
}

.shopMainDiv .tabs button {
  font-size: 0.938rem;
  padding: 0.625rem;
  text-transform: capitalize;
}
.shopMainDiv .MainTitleGrid {
  /*margin-bottom: 0.938rem; */
}
.shopMainDiv .MainTitleGrid h4 {
  margin-right: 0px;
  margin-top: 0px;
}
.shopMainDiv .titleBarRating {
  font-size: 1.588rem;
}
.shopMainDiv .shopTime, .shopMainDiv .shopAddress {
  display: flex;
  align-items: center;
}

.shopMainDiv .shopClosed {
  color: #231f20;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
}
.shopMainDiv .shopOpened {
  color: #231f20;
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
}
.shopMainDiv .shopOpensAt {
  color: var(--Black, #231f20);
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 0px;
  margin-left: 0px;
}

.shopMainDiv .dot {
  margin-top: 0px;
}
.shopMainDiv .locationIcon {
  margin-top: 0px;
}

.google-maps-link {
  text-decoration: none;
  color: inherit;
}

.google-maps-link:hover {
  text-decoration: none;
}

.shopMainDiv .serviceSection {
  padding-left: 0px;
  /* min-height: 60rem; */
}

.shopMainDiv .services {
  color: #231f20;
  font-size: 1.875rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 43.2px;
  word-wrap: break-word;
}
.shopMainDiv .tabs .packageService.activeService {
  border-radius: 60px;
  background: black;
  color: #fff !important;
  font-family: Poppins;
  font-size: 0.838rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 24px */
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.shopMainDiv .serviceName p.serviceTitle {
  color: #231f20;
  font-size: 1rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 20.4px;
  align-self: center;
}
.shopMainDiv .serviceName p.serviceDuration {
  color: #231f20;
  font-size: 0.8333rem;
  font-family: Poppins;
  font-weight: 300;
  line-height: 19.2px;  
  opacity: 0.6;
  align-self: center;
}
.shopMainDiv .servicePrice {
  right: 8rem;
  color: #231f20;
  font-size: 1.1rem;
  font-family: Poppins;
  font-weight: 700;
  line-height: 2.1rem;
  align-self: center;
}

.shopMainDiv .bookButton button {
  width: 100%;
  height: 100%;
  font-size: 0.875rem;
  background-color: #4e297b;
  border-radius: 0.625rem;

  padding: 0;
}
.shopMainDiv .bookButton button:hover {
  background-color: #1a1a1a;
}
.shopMainDiv .bookButton button p {
  color: white;
  font-size: 0.875rem;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.65rem;
  text-transform: capitalize;
  padding: 5px;
}

.shopMainDiv .Salon_Type {
  padding: 7px 10px;
  background: #482e77;
  border-radius: 30px;
  text-align: center;
  position: relative;
  margin: 10px 0px;
  max-width: fit-content;
}

.shopMainDiv .actualPrice{
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-decoration: line-through;
  opacity: 60%;
  display: block;
  text-align: right;
  color: #231f20;
}

.shopMainDiv .callMe {
  height: auto;
  width: 100%;
}

.shopMainDiv .servicePrice.pushToRight {
/*  position: absolute; */
  right: 20px;
}
.shopMainDiv .noServiceFound{
  font-weight: 500;
  font-size: 16px;
  margin: 0px auto;
}

.shopMainDiv .tabs button.activeServicePurple {
  border-radius: 60px;
  background: var(--Black, #4e297b);
  color: #fff !important;
  font-family: Poppins;
  font-size: 0.838rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 24px */
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.shopMainDiv .tabs button.activeServicePurple:hover{
  background-color: #2e144e
}
.shopMainDiv .tabs button.activeServiceBlack {
  border-radius: 60px;
  background: var(--Black, #000000);
  color: #fff !important;
  font-family: Poppins;
  font-size: 0.838rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 24px */
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.shopMainDiv .servicesTabs {
  color: var(--Black, #231f20);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
}

.shopMainDiv .servicesTabs.Mui-selected {
  color: black;
}
.shopMainDiv .services button:not('servicesTabs').Mui-selected {
  background-color: #482e77;
}

.shopMainDiv .bookButton button:hover {
  background-color: #2e144e;
}

.shopMainDiv .moreServiceLink {
  color: var(--Primary, #0178fa);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
}

.tabsvendorService {
  display: flex;
  overflow-x: auto; /* Allow horizontal scrolling */
}

.shopMainDiv .vendorService .MuiTabs-indicator {
  background-color: transparent;
}

.servicesTabs {
  min-width: 100px; /* Adjust as needed */
  white-space: nowrap;
}

.divider {
  margin-top: 2.5rem;
  height: 1px;
  border: 1px solid #d3d3d3;
  width: 100%;
}

.heading {
  margin-top: 2rem;
  font-weight: 600;
  color: #4a4a4a;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 1.25rem;
}
.shopMainDiv .like {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
}
.shopMainDiv .like span {
  align-items: flex-start;
}
.like svg {
  width: 2.4rem;
  height: 2.4rem;
  position: relative;
  bottom: 0.75rem;
}
.shopMainDiv .like span:hover {
  background: none;
}
.like img {
  width: 1.8rem;
  height: 1.8rem;
}

.membership-text {
  color: black;
  margin-top: 1.875rem;
}
.membership-text .description {
  opacity: 60%;
  text-align: left;
}

.shopMainDiv .shopTitleRating {
  font-size: 1.25rem;
}

.nearBy {
  max-width: 100%;
  width: 100%;
  padding: 0px;
  margin-bottom: 3.25rem;
}

.shopMainDiv .prevBtn.Mui-disabled img{
  background: #ccc;
}
.shopMainDiv .nextBtn.Mui-disabled img{
  background: #ccc;
}
.shopMainDiv .prevBtn img{
  background: #4e297b;
  padding: 12px 10px;
  border-radius: 50%;
}
.shopMainDiv .nextBtn img{
  background: #4e297b;
  padding: 12px 10px;
  border-radius: 50%;
}


.reviews-section .see-all-button {
  color: white;
  background: #4e297b;
  border-radius: 30px;  
  font-size: 1.25rem;
  padding: 20px 25px;
  text-transform: capitalize;
}
.reviews-section .see-all-button:hover {
  background: #4e297b;
}

.subheading {
  font-weight: 600;
}

.appointmentOptionContainer:hover {
  cursor: pointer;
}

.appointmentOptionContainer p {
  text-align: center;
}

.MuiGrid-container .apptModal .MuiGrid-grid-md-9 {
  padding-left: 10%;
}

.description {
  margin-top: 1rem;
  font-weight: 300;
  font-size: 1.25rem;
}

.MuiGrid-container .buy-button {
  padding: 0.313rem 0.313rem;
  font-size: 1rem;
  font-weight: 600;
  background-color: #231f20;
  border-radius: 3.125rem;
  color: white;
  text-transform: capitalize;
  margin-top: 3.75rem;
}

@media (max-width: 768px) {
  .buy-button {
    padding: 1rem 1.25rem;
  }
}

h4.membership {
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-weight: 600;
  color: #231f20;
}

.photos .css-10sprxt {
  margin-top: 3.125rem;
  width: auto;
}

.salon-title {
  margin-top: 2rem;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.25;
  color: #2d3748;
}

.nearBy .salon-container {
  margin-top: 0.625rem;
  width: 100%;
  margin-right: 1.25rem;
  cursor: pointer;
}
.nearBy .salon-container img.salon-image {
  border-radius: 0px;
}

.salon-list {
  display: flex;
  gap: 1.25rem;
}
h2.leading-tight {
  font-size: 2.188rem;
  line-height: 2.75rem;
  font-weight: 600;
}

.containerWrap .serviceTop {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}
.containerWrap .serviceTop .serviceTitle h2 {
  font-size: 36px;
  line-height: 43px;
  font-weight: 600;
}
.containerWrap .serviceTop .serviceTabs {
  width: 100%;
}
.containerWrap .serviceTop .serviceTabs .tabContainer .servicesTabs {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  text-transform: capitalize;
  color: #231f20;
}

.containerWrap .serviceTop .serviceTabs .MuiTabs-scroller span {
  display: none;
}

.containerWrap .serviceTop .serviceTabs .activeServicePurple {
  background-color: #4e297b;
  color: #fff;
  border-radius: 50px;
  font-size: 20px;
  border-bottom: none;
  text-transform: capitalize;
}

.containerWrap .serviceTop .activeServiceBlack {
  background-color: black;
  color: #fff;
  border-radius: 50px;
  border-bottom: none;
  text-transform: capitalize;
  font-size: 20px;
}
.containerWrap .mainCardBackground {
  padding: 10px 15px;
  border: 1px solid #231f20;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.containerWrap .mainCardBackground .bookButton button {
 /* background-color: #4e297b; */
  padding: 10px 15px;
  border-radius: 10px;
}
.containerWrap .mainCardBackground .bookButton:hover{
  background-color: #2e144e;
  border-radius: 10px;
}

.containerWrap .mainCardBackground .bookButton button p {
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding: 0;
}
.containerWrap .mainCardBackground .servicePrice {
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
}
.containerWrap .mainCardBackground .serviceName {
  width: calc(100% - 220px);
}
.containerWrap .mainCardBackground .serviceName .serviceTitleWrap {
  display: flex;
} 
.containerWrap .mainCardBackground .serviceName .serviceTitleWrap img {
  width: 14px;
  margin-left: 5px;
  cursor: pointer;
} 

.containerWrap .mainCardBackground .serviceName p.serviceTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.containerWrap .mainCardBackground .serviceName p.serviceDuration {
  margin-top: 5px;
  font-size: 14px;
  color: #000;
  opacity: 60%;
  font-weight: 300;
}
.containerWrap .mainCardBackground .amtWrap {
  display: flex;
  align-items: center;
  gap: 20px;
}
.shopMainDiv .review-card .card .date {
  font-size: 14px;
  line-height: 16px;
}




@media screen and (max-width: 767px) {
  #root .shopMainDiv {
    padding: 3rem 0rem 0rem 2.2rem;
    width: 100%;
  }

  .shopMainDiv .photos .MuiGrid-item {
    padding-left: 0px;
    width: 9%;
  }

  .shopMainDiv .MainTitleGrid .TitleImage {
    display: block;
  }
  .shopMainDiv .shopTitle {
    font-size: 1.375rem;
    margin-bottom: 0;
    float: left;
  }
  .shopMainDiv .Salon_Type {
    margin: 35px 0px 10px 0px;
  }
  .shopMainDiv .serviceSection {
    padding-left: 0px !important;
  }
  .shopMainDiv .shopTitleRating {
    font-size: 1rem;    
  }
  .shopMainDiv .MuiGrid-grid-lg-8,
  .shopMainDiv .MuiGrid-grid-lg-4 {
    max-width: 100%;
    flex-basis: 100%;
  }

  .sidebar .status {
    width: 100%;
  }

  .shopMainDiv .services {
    font-size: 0.078rem;
  }
  .shopMainDiv .activeService {
    font-size: 0.75rem;
    margin-left: 2.5rem;
  }
  .shopMainDiv .moreServiceLink {
    font-size: 0.75rem;
  }
  .membership-text .description {
    font-size: 1.25rem;
  }
  .shopMainDiv .dot {
    margin-top: 0px;
    height: 0.313rem;
  }
  .shopMainDiv .shopOpensAt {
    font-size: 0.75rem;
    padding: 0px;
    margin: 0px 0.313rem 0px 0px;    
    margin-left: 0px;
    margin-right: 0px;
  }
  .shopMainDiv .shopOpened {
    font-size: 0.813rem;
    padding: 0px;
    margin: 0px;
    width: 40%;
    display: block;
    float: left;
  }
  .shopMainDiv .MuiGrid-grid-lg-4 .sidebar {
    margin-left: 0px;
  }
  .profile-card .sidebarRating span {
    font-size: 0.938rem;
  }
  .shopMainDiv .titleBarRating {
    font-size: 1.25rem;
    float: left;
    position: relative;
    top: 20px;
  }
  .hours p,
  .days p {
    font-size: 1.563rem;
  }
  .profile-card .sidebarRating {
    max-width: 100%;
  }
  .social-accounts .MuiGrid-grid-xs-12 {
    max-width: 20%;
  }
  .shopMainDiv .photos > div {
    margin-top: 0.875rem;
  }
  .shopMainDiv .photos img {
    width: 100%;
    margin-left: 1rem;
  }
  .profile-card .shopOpensAt {
    margin-left: 0px;
  }

  #root .shopMainDiv .video-item {
    padding-left: 1rem;
    width: 100%;
  }

  .shopMainDiv .serviceContent p {
    font-size: 0.925rem;
  }
  .shopMainDiv button.buy-button {
    position: relative;
    right: 2.5rem;
    padding: 0.313rem 0.313rem;
    margin-top: 1.25rem;
    margin-left: 15%;
  }
}

.nearBy .nearByContainer {
  /* margin-left: 3.25rem;
  width: 95%; */
  margin-bottom: 2rem;
  overflow: hidden;
}
.nearBy .nearByContainer.count1 {
  margin-left: 0rem;
}

.nearBy .count1 .MuiGrid-grid-md-3 {
  max-width: 100%;
  flex-basis: 100%;
  padding-left: 0px;
}
.nearBy .prevBtn {
  left: -80px;
  z-index: 10;
}
.nearBy .nextBtn {
  right: -60px;
  z-index: 10;
}
.nearBy .prevBtn:hover, .nearBy .nextBtn:hover {
  background: transparent;
}

/* For screens wider than 1600px */
@media (max-width: 1600px) {
  .shopMainDiv .shopTitle span {
    font-size: 2.0833rem;    
  }

  .shopMainDiv .dot {
    margin-top: 0px;
  }
  /* .shopMainDiv .serviceSection.MuiGrid-item {
    min-height: 63rem;
  } */
  .shopMainDiv .shopClosed,
  .shopMainDiv .shopOpened,
  .shopMainDiv .shopOpensAt {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0px;
  }
  .shopMainDiv .locationIcon {
    position: relative;
    top: auto;
  }
  .shopMainDiv .services,
  .shopMainDiv .about-section .about-title,
  .shopMainDiv h4.membership,
  .shopMainDiv .academy-videos-container h4,
  .photos h4,
  .shopMainDiv .reviews-title h2,
  .nearBy h2, .teamContainer h2 {
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 1.25;
  }
  .shopMainDiv .tabs button.activeService {
    font-size: 1.0417rem;
    font-weight: 500;
  }
  .shopMainDiv .tabs button {
    font-size: 1.0417rem;    
  }
  /* .shopMainDiv .team p{
    font-size: 1.2500rem;
    font-weight: 417;
  } */
  .shopMainDiv .about-section .about-description {
    font-size: 1.1458rem;
    font-weight: 400;
  }
  .about-section .about-address {
    font-size: 0.9375rem;    
  }
  .about-section .about-nearby {
    font-size: 0.9375rem;
  }
  .membership-text .subheading {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .membership-text .description {
    font-size: 1.0417rem;
    font-weight: 250;
  }
  .shopMainDiv .buy-button,
  .reviews-section .see-all-button {
    font-size: 1.0417rem;
    font-weight: 500;
    text-transform: capitalize;
  }
  .reviews-section .reviews-title h5,
  .shopMainDiv .review-card .card .name {
    font-size: 1.25rem;    
  }
  .shopMainDiv .review-card .card .avatar {
    width: 50px;
    height: 50px;
  }
  .reviews-section .reviews-title span {
    font-size: 1.5rem;
    position: relative;
    top: 0.1rem;
    left: 0.1rem;
  }  
  .review-card .header span {
    font-size: 1.5rem;
  }
  .video-item {
    margin-right: 2.3rem;
  }
  .nearBy .salonname {
    min-height: 40px;
  }
  p.teamNotFound {
    font-size: 1.1458rem;
    font-weight: 400;
  }
}

@media (max-width: 1440px) {
  .shopMainDiv {
    padding: 3rem 64px 0rem 80px;
  }
  .shopMainDiv .shopTitle span {
    font-size: 1.25rem;    
  }
  .shopMainDiv .dot {
    margin-top: 0px;
  }
  .shopMainDiv .locationIcon {
    top: 0.2rem;
  }
  .shopMainDiv .gallerySection {
    margin-top: 0px;
  }
  .shopMainDiv .MainTitleGrid {
    margin-bottom: 0px;
  }

  .shopMainDiv .callMe {
    height: 30px;
    width: 80px;
    margin-top: -2px;
  }
  .shopMainDiv .slIcon {
    padding-right: 10px;
    padding-left: 0px !important;
  }
  .shopMainDiv img.socialIcon {
    width: 25px;
    height: 25px;
  }

  .shopMainDiv .shopOpensAt {
    margin-right: 6px;
    margin-left: 7px;
  }
  .shopMainDiv .dot {
    margin-right: 0px;
  }
  .shopMainDiv .shopOpensAt,
  .shopMainDiv .shopClosed,
  .shopMainDiv .shopOpened {
    font-size: 16px;
  }
  .containerWrap .mainCardBackground {
    padding: 10px;
  }

  .shopMainDiv .services,
  .shopMainDiv .about-section .about-title,
  .shopMainDiv h4.membership,
  .shopMainDiv .academy-videos-container h4,
  .photos h4,
  .shopMainDiv .reviews-title h2,
  .nearBy h2, .teamContainer h2 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .shopMainDiv .tabs button.activeService {
    font-size: 0.8333rem;
    font-weight: 400;
    height: 2.5rem;
    min-height: 2.5rem;
  }
  /* .shopMainDiv .serviceContent {
    display: flex;
  } */

  .shopMainDiv .serviceName p.serviceTitle {
    font-size: 0.8rem;        
  }
  .shopMainDiv .servicePrice {
    right: 7rem;
    font-size: 0.8rem;
  }
  .shopMainDiv .bookButton button p {
    font-size: 0.7rem;
  }

  .shopMainDiv .tabs button {
    font-size: 0.8333rem;    
  }
  /* .shopMainDiv .team p{
    font-size: 0.9rem;
    font-weight: 433;
  } */
  .shopMainDiv .about-section .about-description {
    font-size: 14px;
    line-height: 20px;    
  }
  .about-section .about-address {
    font-size: 0.75rem;    
  }
  .about-section .about-nearby {
    font-size: 0.75rem;    
  }
  .membership-text .subheading {
    font-size: 1rem;    
  }
  .membership-text .description {
    font-size: 0.8893rem;    
  }
  .shopMainDiv .buy-button,
  .reviews-section .see-all-button {
    font-size: 0.8333rem;
    font-weight: 400;
  }
  .reviews-section .reviews-title h5 {
    font-size: 1.0672rem;    
  }
  .reviews-section .reviews-title h5,
  .shopMainDiv .review-card .card .name {
    font-size: 1rem;    
  }
  .reviews-section .reviews-title span {
    font-size: 1.5rem;
    position: relative;
    top: 0.1rem;
    left: 0.1rem;
  }
  .shopMainDiv .reviews-section .reviews-list {
    margin-top: 0px;
  }  
  .shopMainDiv .review-card .card .avatar {
    width: 40px;
    height: 40px;
  }
  .shopMainDiv .review-card .card .date {
    font-size: 12px;
    line-height: 14px;  
  }
  .review-card .header span {
    font-size: 1.01rem;
  }
  .containerWrap .mainCardBackground .amtWrap {
    gap: 10px;
  }
  .containerWrap .mainCardBackground .bookButton button {
    padding: 10px;
  }
  .containerWrap .mainCardBackground .bookButton button p {
    font-size: 14px;
    line-height: 18px;
  }
  .containerWrap .mainCardBackground .servicePrice {
    font-size: 16px;
    line-height: 18px;
  }
  .containerWrap .mainCardBackground .serviceName {
    width: calc(100% - 185px);
  }
  .containerWrap .mainCardBackground .serviceName .serviceTitle {
    font-size: 14px !important;
  }
  .containerWrap .mainCardBackground .serviceName p.serviceDuration {
    font-size: 12px;
    line-height: 16px;
    margin-top: 0;
  }
  .nearBy .prevBtn {
    left: -65px;   
  }
  .nearBy .nextBtn {
    right: -50px;
    z-index: 10;
  }
  #root .shopMainDiv .callNow a {
    margin-top: 0 !important;
  }
  img.coverImage {
    height: 500px;
  }
  .shopMainDiv img.smallImg {
    height: 240px;
  }
  
}



@media (max-width: 1199px) {
  .shopMainDiv .shopTitle span {
    font-size: 1.3333rem;    
    top: auto;
  }
  .shopMainDiv .businessListImage {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .shopMainDiv .crown {
    width: 45px;
    height: 45px;
  }
  .shopMainDiv .like span {
    padding: 9px 0px 9px 9px;
  }
  .shopMainDiv .services,
  .shopMainDiv .about-section .about-title,
  .shopMainDiv h4.membership,
  .shopMainDiv .academy-videos-container h4,
  .photos h4,
  .reviews-title h2,
  .nearBy h2, .teamContainer h2 {
    font-size: 1.2rem;    
    margin-right: 0;
  }

  .shopMainDiv .tabs button.activeService {
    font-size: 0.6667rem;    
  }
  .shopMainDiv .serviceContent button {
    width: auto;
    height: auto;
    right: 0px;
  }  
  /* .shopMainDiv .team p{
    font-size: 0.8000rem;
    font-weight: 267;
  } */
  .shopMainDiv .shopOtherDetails {
    flex-wrap: wrap;
  }  
  .shopMainDiv .shopTime, .shopMainDiv .shopAddress {    
    
  }  
  .about-section .about-nearby {
    font-size: 0.6rem;    
  }
  .membership-text .subheading {
    font-size: 0.9375rem;    
  }
  .membership-text .description {
    font-size: 0.6667rem;
    font-weight: 300;
  }
  .shopMainDiv .buy-button,
  .reviews-section .see-all-button {
    font-size: 0.6667rem;    
  }
  .reviews-section .reviews-title h5,
  .shopMainDiv .review-card .card .name {
    font-size: 0.8rem;    
  }
  .reviews-section .reviews-title span {
    font-size: 1.2rem;
    position: relative;
    top: 0.05rem;
    left: 0.1rem;
  }
  .review-card .avatar img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }  
  .review-card .header span {
    font-size: 1rem;
  }
  .shopMainDiv .reviews-section .reviews-list {
    margin-top: 0.5rem;
  }
  .shopMainDiv .nearBy .salon-rating img {
    width: 0.4rem;
  }
  .nearBy .salon-type {
    height: 1rem;
  }
  .nearBy .salon-details {
    margin-left: 0.5rem;
  }
  .nearBy .salonname {
    min-height: 25px;
  }
  img.coverImage {
    height: 400px;
    border-radius: 20px;
  }
  .shopMainDiv img.smallImg {
    height: 190px;
    border-radius: 20px;
  }
}
@media (max-width: 899px) {
  .shopMainDiv .shopTitleRating {
    font-size: 16px;
  }

  .shopMainDiv .nearBy .prevBtn img{
    padding: 6px 8px;
  }

  .shopMainDiv .nearBy .nextBtn img{
    padding: 6px 8px;
  }

  .vendorService .MuiTabs-scrollButtons {
    width: 20px;
  }
  .about-section .about-address-container {
    margin-top: 10px;
  }
  .serviceSection .academy-videos-container {
    margin-top: 0;
  } 
  .about-section .about-map > div {
    height: 300px !important;
  }
  .nearBy .prevBtn img, .nearBy .nextBtn img {
    width: 30px;
    height: 30px;
  }
  .reviews-header .see-all-button {
    font-size: 14px;
    line-height: 18px;
  }
  
}
@media (max-width: 600px) {
  .shopMainDiv .TitleImage h2 span {
    bottom: 0px;
  }
  .homeShop.onlyMobileSlider .slick-slide img {
    height: 250px !important;
  }
  .shopMainDiv .shopOtherDetails {
    display: block;
  }
  .shopMainDiv .onlyMobileSlider.timeSlot {
    display: none;
  }
  .shopMainDiv .shopClosed,
  .shopMainDiv .shopOpened,
  .shopMainDiv .shopOpensAt {
    font-size: 1rem;
  }
  .shopMainDiv .dot {
    margin-left: 0.3rem;
  } 
  /* .shopMainDiv .onlyDesktop {
    display: none;
  } */
  /* .shopMainDiv .onlyMobile {
    display: contents;
  } */

  .shopMainDiv .shopTime h4, .shopMainDiv .shopTime h2, .shopMainDiv .shopAddress h2 {
    font-size: 15px !important;
  }
  .shopMainDiv .shopAddress {
    margin-top: 5px;
  }
  .shopMainDiv .shopAddress .dot {
    display: none;
  }

  .shopMainDiv .shopAddress a:last-child {
     order: -1; 
  }
  .shopMainDiv .locationIcon {
    top: 0;
    width: 20px;
    height: 20px;
  }
  .shopMainDiv .like {
    margin-top: 0;
  }

  .shopMainDiv .onlyMobileSlider {
    display: block;
  }
  .shopMainDiv .gallerySection {
    margin-bottom: 0px;
    display: none;
  }
  .shopMainDiv img.crown {
    width: 44px;
    height: 44px;
  }
  .shopMainDiv .mobileRating {
    width: 15px;
    margin-right: 5px;
  }
  .shopMainDiv .onlyMobile span.titleWithCrown {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    position: relative;
    top: -10px;
  }
  .shopMainDiv .onlyMobile .MuiGrid-grid-xs-2 {
    position: relative;
    top: 8px;
  }
  .shopMainDiv .onlyMobile .MuiGrid-grid-xs-2 span {
    font-size: 13px;
  }

  .shopMainDiv .onlyMobile h4.shopClosed {
    width: 50%;
    float: left;
    padding-right: 0px;
    margin-right: 0px;
  }
  .shopMainDiv .onlyMobile h2 {
    width: 100%;
    margin-top: 10px;
  }
  .shopMainDiv .onlyMobile h3.shopOpensAt {
    width: 50%;
    float: right;
    margin-left: 0px;
    margin-right: 0px;
  }
  .shopMainDiv .onlyMobile a h4 {
    margin-left: 0px;
    padding-left: 0px;
  }
  .shopMainDiv .tabs .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 1;
  }
  .video-wrapper {
    width: 100%;
  }
  .shopMainDiv .serviceContent button p {
    font-size: 0.725rem;
    padding: 5px 9px;
  }
  .team p {
    font-size: 13px;
  }
  .shopMainDiv .serviceContent p {
    font-size: 0.725rem;
    margin-top: 0px;
  }
  .homeShop .slick-slider .slick-list {
    width: 100%;
    margin-left: 0px;
  }
  
  .shopMainDiv .Salon_Type {
    margin: 15px 0;
    /* max-width: 100%; */
  }
  .shopMainDiv .slick-dots {
    position: relative;
    bottom: 15px;
  }  
  .shopMainDiv .tabs button.activeServiceBlack, .shopMainDiv .tabs button.activeServicePurple {
    min-height: 36px;
    height: 36px;
  }
  .vendorService .MuiTabs-flexContainer {
    align-items: center;
  }
  .shopMainDiv .btnMoreServices {
    background-color: #4e297b;
    color: #fff;
    text-transform: capitalize;
    border-radius: 50px;
    padding: 10px 15px;
    margin: 20px auto;
    text-align: center;
    width: 100%;
  }
  .nearBy .nextBtn, .nearBy .prevBtn {
    display: none;
  }
  #root .shopMainDiv {
    overflow-x: hidden;
  }
  .containerWrap .mainCardBackground .bookButton button p {
    font-size: 12px;
    line-height: 14px;
  }
  .shopMainDiv .shopTitle {
    float: none;
  }
  .shopMainDiv .titleBarRating,
  .shopMainDiv .shopTitle {
    top: 0;
  }
  .shopMainDiv .shopTitleRating.ratingWithoutCrown {
    top: 0;
  }
  .shopMainDiv .like svg {
    bottom: 0px;
  }
  .shopMainDiv .dot {
    height: 12px;
  }
  .profile-card .shopOpened, .profile-card .shopClosed, .profile-card .shopOpensAt, .profile-card p.address-text, .profile-card .shopTitleRating {
    font-size: 14px;
  }
  .hours p, .days p {
    margin-left: 0;
  }

  .appt section .MuiCard-root {
    box-shadow: none;
  }
  .appt section .appointmentOptionContainer {
    width: 90%;
  }
  .appt .appointmentOptionContainer p {
    font-size: 0.8rem;
  }
  .appt .appointmentOptionContainer h5 {
    font-size: 1.15rem;
  }
  .appt .apptModal .MuiGrid-grid-xs-11 {
    padding-left: 0px;
  }
  .appt .apptModal .MuiGrid-grid-xs-1 {
    padding-left: 0px;
  }
  .appt .top h1 {
    font-size: 1rem;
  }
  .appt .top .Appointment.booking.icon {
    width: 15px;
    height: 15px;
    margin-top: 25px;
  }
  .appt .MuiDialog-paper {
    width: 100%;
  }
  .containerWrap .mainCardBackground .serviceName {
    width: calc(100% - 175px);
  }
  .vendorService .MuiTabs-scrollButtons {
    display: none;
  }
  .shopMainDiv .serviceContent .mainCardBackground {
    flex-wrap: wrap;
  }
  .shopMainDiv .bookButton {
    width: auto;
    height: auto;
  }  
  .packagePopup .popupContent {
    width: 90%;    
    height: 90%;
    padding: 20px;
  }
}
.popupContent{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 500px;
  background-color: white;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  outline: 0px;
  border-radius: 10px;
  overflow-y: auto;
}

.popupContent::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.popupContent::-webkit-scrollbar-track {
  background: #f9f9f9; /* Track background */
  border-radius: 10px; /* Rounded corners for the track */
}
.popupContent::-webkit-scrollbar-thumb {
  background: #4e297b; /* Scrollbar handle color */
  border-radius: 10px; /* Rounded corners for the handle */
  border: 2px solid #4e297b; /* Adds a gap between handle and track */
}

.popupContent::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

  .popupContent .topSection{
  display: flex;
  justify-content: space-between;
}
.popupContent #modalModalTitle{
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
}
.popupContent #modalModalClose img{
  width: 30px;
}
p#modalModalClose {
  cursor: pointer;
}
p#modalModalClose svg{
  background: #4e297b;
  border-radius: 50%;
  padding: 7px;
  height: 40px;
  width: 40px;
}
p#modalModalClose svg path {
  fill: white;
}

.popupContent .processTime{
  font-weight: 500;
  color: rgb(0,0,0,0.5);
}
.popupContent .processPrice .discountPrice{
  font-weight: 600;
  color: rgb(0,0,0);
  font-size: 26px;
  line-height: 31.2px;
}
.popupContent .processPrice .actualPrice{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-decoration: line-through;
  padding-left: 10px;
  opacity: 50%;
}


.popupContent .whatsIncluded{
  margin-top: 16px;
  font-weight: 600;
  font-size: 20px;
}
.popupContent .serviceName{
  font-weight: 700;
  color: rgb(0,0,0);
  font-size: 16px;
}
.popupContent .serviceHour{
  font-weight: 400;
  color: rgb(0,0,0);
  font-size: 14px;
}
