.address-main {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: white;
}

.address-main .address-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 50px;
}

.address-main .address-box {
  padding: 0px 20px 20px 20px;
  background-color: white;
  border-radius: 24px;
  max-width: 850px;
  width: 100%;
}

.address-main .header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.address-main .address-image {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.address-main .button-box {
  display: flex;
  gap: 16px;
  margin-top: 20px;
}

.address-main .address-divider {
  margin: 20px 0;
}

.address-box .Mui-error {
  margin-left: 0px;
}

.address-box h1 {
  font-size: 22px;
  font-weight: bold;
  margin: 0px auto;
}

.address-box .input-field.class2,
.address-box .input-field.class3,
.address-box .input-field.class4,
.address-box .input-field.class5,
.address-box .input-field.class6,
.address-box .input-field.class7 {
  width: 48%;
}

.address-box .input-field.class3,
.address-box .input-field.class5,
.address-box .input-field.class7 {
  margin-left: 20px;
}

.button-box button.cancel {
  height: 3.5rem;
  width: 100%;
  color: #4E297B;
  border: 1px solid #4E297B;
  text-transform: capitalize;
}
.button-box button.continue {
  margin-top: 0px;
  width: 100%;
}

@media (max-width: 768px) {
  .address-main .address-container {
    padding: 10px;
  }

  .address-main .address-box {
    padding: 10px;
  }

  .address-main .header-box h1 {
    font-size: 1.5rem;
  }
}
