.homepageClasses .appDownload{
  padding: 120px 100px;
  display: flex;
  align-items: center;
  background-color: #f6f6f6;  
}
.homepageClasses .appDownload .imageWrap{
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 41px;
}
.homepageClasses .appDownload .group-wrapper{
  display: flex;
  gap:15px;
}
.homepageClasses .appDownload .group-wrapper img {
  width: 30px;
  height: 30px;
}
.homepageClasses .appDownload .group-wrapper p{
  font-weight: 500;
  font-size: 24px;
  line-height: 28.8px;
  padding-top: 50px;
  padding-bottom: 38px;
  margin: 0;
}

.homepageClasses .appDownload .storeImage img{
  width: 40px;
  margin-right: 13px;
}

.homepageClasses .appDownload p.download-the{
  font-weight: 600;
  font-size: 70px;
  line-height: 84px;
  padding-bottom: 30px;
}
.homepageClasses .appDownload p.download-the span {
  display: block;
}
.homepageClasses .appDownload p.manage-your-schedule {
  font-weight: 400;
  font-size: 20px;  
  line-height: 30px;
}
@media (max-width: 1280px) {
  .homepageClasses .appDownload p.download-the {
    font-size: 60px;
    line-height: 74px;
  }
}
@media (max-width: 1199px) {
  .homepageClasses .appDownload {
    padding: 70px;
  }
  .homepageClasses .appDownload .group-wrapper {
    gap: 12px;
  }
  .homepageClasses .appDownload p.download-the {
    font-size: 40px;
    line-height: 50px;
    padding-bottom: 10px;
  }
  .homepageClasses .appDownload p.download-the span {
    display: inline;
  }
  .homepageClasses .appDownload p.manage-your-schedule {
    font-size: 18px;
    line-height: 24px;
  }
  .homepageClasses .appDownload .group-wrapper p {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 20px;
  }
  .homepageClasses .appDownload .group-wrapper img {
    width: 22px;
    height: 22px;
  }
  .homepageClasses .appDownload .imageWrap {
    margin-top: 30px;
    justify-content: flex-start;
  }
}
@media (max-width: 899px) {
  .homepageClasses .appDownload {
    padding: 50px;
  }
  .homepageClasses .appDownload .group-wrapper p {
    padding-bottom: 10px;
  }
  .homepageClasses .appDownload p.download-the {
      font-size: 30px;
      line-height: 40px;
  }
  .homepageClasses .appDownload p.manage-your-schedule {
    font-size: 16px;
    line-height: 22px;
  }
  .ios img {
    width: 185px;
  }
  .android img {  
    width: 160px;
  }
  .homepageClasses .appDownload .imageWrap {
    gap: 20px;
  }
}
@media (max-width: 600px) {
  .homepageClasses .appDownload {
    padding: 50px 20px 50px 20px;
  }
  .homepageClasses .appDownload .group-wrapper p {
    font-size: 18px;
  }
  .homepageClasses .appDownload p.download-the {
    font-size: 24px;
    line-height: 30px;
}
.homepageClasses .appDownload p.manage-your-schedule {
  font-size: 14px;
  line-height: 20px;
}
.ios img {
  width: 175px;
}
.android img {
  width: 150px;
}

}
