.search .grid-container .scrollableLeft .appointmentCard{
  margin-top: 20px;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
  width: 100%;
  border-radius: 20px;
  cursor: pointer;
  justify-content: space-around;
}
.search .grid-container .scrollableLeft .appointmentCard .cardContainer{
  display: flex;
}
.search .grid-container .scrollableLeft .appointmentCard .imageContainer{
  width: 160px;
  height: 160px;
}
.search .grid-container .scrollableLeft .appointmentCard .imageContainer img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.search .grid-container .scrollableLeft .appointmentCard .contentContainer{
  width: calc(100% - 160px);
  padding: 20px;
}
.search .grid-container .scrollableLeft .appointmentCard .contentContainer .contentFirst{
  display: flex;
  justify-content: space-between;
}
.search .onlyBottom button{
  background-color: #4E297B;
  color: #fff;
}
.search .onlyBottom button.Mui-disabled{
  background-color: #ccc;
  color: #000;
}

.search .onlyBottom button:hover{
  background-color: #2e144e;
}

.search .grid-container .scrollableLeft .appointmentCard .contentContainer .contentAddress{
  display: flex;
  justify-content: space-between;
}
.search .grid-container .scrollableLeft .appointmentCard .contentContainer .contentTitle {
  width: calc(100% - 75px);
}
.search .grid-container .scrollableLeft .appointmentCard .contentContainer .title{
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.search .grid-container .scrollableLeft .appointmentCard .contentContainer .price{
  font-size: 14px;
  font-weight: 400;
  opacity: 40%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.search .grid-container .scrollableLeft .appointmentCard .contentContainer .location{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  align-items: flex-start;
  padding-right: 15px;
}

.search .grid-container .scrollableLeft .appointmentCard .contentContainer .location img.locationIcon{
  vertical-align: middle;
  margin-right: 8px;
}
.search .grid-container .scrollableLeft .appointmentCard .contentContainer .location span{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.search .grid-container .scrollableLeft .appointmentCard .contentContainer .ratingText{
  display: flex;
  justify-content: space-between;
}
.search .grid-container .scrollableLeft .appointmentCard .contentContainer .ratingText span{
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding-top: 4px;
  padding-right:10px ;
}
.search .grid-container .scrollableLeft .appointmentCard .contentContainer .ratingText img{
  width: 25px;
}
@media (max-width: 1366px) {
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .title {
    font-size: 15px;
    line-height: 18px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .imageContainer {
    width: 125px;
    height: 125px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer {
    width: calc(100% - 125px);
    padding: 10px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .price {
    font-size: 12px;   
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .location {
    font-size: 13px;
    padding-right: 5px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .ratingText img {
    width: 15px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .location img.locationIcon {
    width: 20px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .nextIcon img {
    width: 20px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .ratingText span {
    padding-right: 5px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .contentTitle {
    width: calc(100% - 60px);
}

}
@media (max-width: 1199px) {
  .onlyBottom p{
    font-size: 12px;
    margin: 0px 12px;
  }  
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .title{
    font-size: 14px;
    line-height: 16px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .imageContainer{
    width: 120px;
    height: 120px;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer {
    width: calc(100% - 120px);
  }
}
@media (max-width: 899px) {
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .contentTitle {
    width: calc(100% - 70px);
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer {
    padding: 15px;
  }  
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .price {
    padding-top: 10px;
    padding-bottom: 10px;
  }  
}
@media (max-width: 600px) {
  .search .grid-container .scrollableLeft .appointmentCard .cardContainer{
    display: flex;
    flex-direction: column;
  }
  .search .grid-container .scrollableLeft .appointmentCard .imageContainer img{
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .search .grid-container .scrollableLeft .appointmentCard .imageContainer {
    width: 100%;
    height: 100%;
  }
  .search .grid-container .scrollableLeft .appointmentCard .contentContainer .price {
    font-size: 14px;
  }
}