body {
  background-color: #f6f6f6;
}

.logoMainTitle {
  color: var(--Black, #231f20);
  font-family: Poppins;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 150;
  line-height: normal;
}

.logoSubTitle {
  color: var(--Black, #231f20);
  font-family: Poppins;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.mainHeader button.mobileOnly:hover {
  background-color: #2e144e;
}

header .mobileOnly {
  display: block;
}
header .hamburger {
  display: none;
}

.MuiTypography-root.menuTitle {
  color: var(--Black, #231f20);
  /* H3 */
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  /* 24px */
}

/* Customer Words */
.MuiTypography-root.customerReviewText {
  color: var(--Black, #231f20);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 184%;
  /* 29.44px */
}
.mainHeader .header img.profileImage {
  height: 55px;
  width: 55px;
  margin-left: 1rem;
  border-radius: 50px;
}
hr.headerDivider {
  position: relative;
  height: 2px;
  background-color: gray;
  margin: 0px;
  width: 93%;
  margin: 0px auto;
}

.mainHeader .header {
  padding-left: 0px;
  padding-bottom: 1.2rem;
  padding-right: 0px;
}

.mainHeader.shopHeader .header {
  border-bottom: none;
}

.mainHeader .header img {
  width: 162px;
}

.mainHeader .profile-avatar {
  height: 50px;
  width: 50px;
  background-color: #4e297b;
}
.mainHeader button:hover {
  background-color: transparent;
}

.mainHeader a {
  text-decoration: none;
}
.mainHeader a:last-child {
  margin-right: 0px;
}
.mainHeader a:nth-child(4) {
  margin-right: 1.5rem;
  margin-left: 0rem;
}
/* .mainHeader a:nth-child(1){
  margin-right: 0.3rem;
} */

.mainHeader header {
  position: relative;
}

.menu-container {
  position: relative;
  display: inline-block;
}

.menu-button {
  border: none;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  background: transparent;
  cursor: pointer;
  padding: 0 40px;
  font-weight: 400;
  color: #231f20;
}

.menu-button:hover {
  background-color: #0056b3;
}

.menu-dropdown {
  position: absolute;
  width: calc(100% + 70px);
  top: 100%;
  left: 40px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
}

.menu-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-dropdown li {
  padding: 8px 20px;
  cursor: pointer;
  color: #231f20;
}
.menu-dropdown li a {
  color: #4e297b;
  font-size: 15px;
  line-height: 20px;
}

.menu-dropdown li:hover {
  background-color: #f0f0f0;
}

.mainHeader header h4:hover {
  cursor: pointer;
}
.mainHeader .loginText {
  display: flex;
  align-items: center;
}
.mainHeader .loginText .line {
  margin: 0 5px;
  font-size: 20px;
  line-height: 24px;
}
.mainHeader .mobileOnly p:hover {
  color: #4e297b;
}

hr.headerDivider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 20px; /* Adjust margin */
  right: 20px; /* Adjust margin */
  height: 2px;
  background-color: gray; /* Match divider color */
  z-index: -1;
}
#root .mainHeader {
  padding: 1.5rem 100px 0rem 100px;
}

#root .shopHeader {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
}
.signupText {
  margin-right: 40px !important;
}

#account-menu ul {
  border-radius: 20px;
  border: 1px solid #231f20;
  width: 250px;
  background-color: #fff;
  margin-left: auto;
}
#account-menu .MuiPopover-paper {
  border-radius: 20px;
  right: 35px;
  left: auto;
  width: 20%;
  background: transparent;
}

#account-menu .MuiPopover-paper {
  width: 100%;
  padding: 0px 100px;
}

#account-menu ul li a {
  text-decoration: none;
  color: #231f20;
}
#account-menu ul li:hover {
  background: transparent;
}
#account-menu .profileName {
  font-size: 1.2rem;
  font-weight: 600;
  white-space: normal;
}
#account-menu .logoutDivider {
  width: 87%;
  margin: 0px auto;
  padding: 5px;
}

/* For screens wider than 1920 */
@media (max-width: 1920) {
  .logoMainTitle {
    font-size: 3.75rem;
    font-weight: 250;
  }

  .logoSubTitle {
    font-size: 3.75rem;
    font-weight: 800;
  }

  .MuiTypography-root.menuTitle {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .MuiTypography-root.customerReviewText {
    font-size: 1rem;
    font-weight: 400;
  }
}

/* For screens wider than 1600px */
@media (max-width: 1600px) {
  .logoMainTitle {
    font-size: 3.125rem;
    font-weight: 208;
  }
  /* #root .mainHeader{
    padding: 2rem 1.5rem 0rem 1.5rem;
  } */

  .logoSubTitle {
    font-size: 3.125rem;
    font-weight: 667;
  }

  .MuiTypography-root.menuTitle {
    font-size: 1.0917rem;
    font-weight: 333;
  }

  .MuiTypography-root.customerReviewText {
    font-size: 1.033rem;
    font-weight: 400;
  }
  hr.headerDivider {
    width: 94%;
  }
}

/* For screens wider than 1440px */
@media (max-width: 1440px) {
  .logoMainTitle {
    font-size: 2.8125rem;
    font-weight: 188;
  }

  .logoSubTitle {
    font-size: 2.8125rem;
    font-weight: 600;
  }

  .MuiTypography-root.menuTitle {
    font-size: 1.1575rem;
    font-weight: 300;
  }

  .MuiTypography-root.customerReviewText {
    font-size: 0.75rem;
    font-weight: 300;
  }
  hr.headerDivider {
    width: 91%;
  }
}

/* For screens wider than 1366px */
@media (max-width: 1366px) {
  .logoMainTitle {
    font-size: 2.668rem;
    font-weight: 178;
  }

  .logoSubTitle {
    font-size: 2.668rem;
    font-weight: 569;
  }

  .MuiTypography-root.menuTitle {
    font-size: 1.2993rem;
    font-weight: 300;
  }
  .MuiTypography-root.customerReviewText {
    font-size: 0.7515rem;
    font-weight: 285;
  }
  hr.headerDivider {
    width: 90%;
  }
}

/* For screens wider than 1280px */
@media (max-width: 1280px) {
  .logoMainTitle {
    font-size: 2.5rem;
    font-weight: 167;
  }

  .logoSubTitle {
    font-size: 2.7rem;
    font-weight: bolder;
  }

  .MuiTypography-root.menuTitle {
    font-size: 0.9999rem;
    font-weight: 307;
  }

  .MuiTypography-root.customerReviewText {
    font-size: 0.8667rem;
    font-weight: 267;
  }
  hr.headerDivider {
    width: 92%;
  }
}

/* For screens wider than 1200px */
@media (max-width: 1200px) {
  .logoMainTitle {
    font-size: 2.3438rem;
    font-weight: 156;
  }

  .logoSubTitle {
    font-size: 2.3438rem;
    font-weight: 500;
  }

  .MuiTypography-root.menuTitle {
    font-size: 0.9813rem;
    font-weight: 350;
  }

  .MuiTypography-root.customerReviewText {
    font-size: 0.695rem;
    font-weight: 350;
  }
}

/* For screens wider than 1024px */
@media (max-width: 1199px) {
  #root .mainHeader {
    padding-left: 70px;
    padding-right: 70px;
  }
  #account-menu .MuiPopover-paper {
    padding-left: 60px;
    padding-right: 60px;
  }
  .mainHeader .loginText .line {
    margin: 0 5px;
    font-size: 16px;
    line-height: 21px;
  }
  .signupText {
    margin-right: 30px !important;
  }
}

@media (max-width: 899px) {
  #root .mainHeader {
    padding: 10px 50px;
  }
  #account-menu .MuiPopover-paper {
    padding-left: 40px;
    padding-right: 40px;
  }
  .mainHeader .header img {
    width: 140px;
  }
  .mainHeader .header {
    padding-bottom: 0;
  }
  header .mobileOnly,
  .mainHeader .loginText .line {
    display: none;
  }
  header .hamburger {
    display: block;
  }
  .mainHeader .header img.hamburger {
    width: 36px;
  }
  .mobileMenu {
    width: 100% !important;
  }
  .mobileMenu .MuiPaper-root.MuiPaper-elevation {
    width: 100% !important;
    left: 50px !important;
    right: 0 !important;
    max-width: calc(100% - 100px);
    top: 73px !important;
  }
}

/* For screens wider than 768px */
@media (max-width: 768px) {
  .logoMainTitle {
    font-size: 1.5rem;
    font-weight: 200;
  }

  .logoSubTitle {
    font-size: 1.5rem;
    font-weight: 320;
  }

  .MuiTypography-root.menuTitle {
    font-size: 0.7rem;
    font-weight: 360;
  }

  .MuiTypography-root.customerReviewText {
    font-size: 0.9rem;
    font-weight: 400;
  }
}

@media (max-width: 600px) {
  #root .mainHeader {
    padding: 10px 20px;
  }
  #account-menu .MuiPopover-paper {
    padding-left: 10px;
    padding-right: 10px;
  }
  .mainHeader .header img {
    width: 100px;
  }
  .mainHeader .header {
    min-height: auto;
  }
  .mainHeader .header img.hamburger {
    width: 24px;
  }
  .mobileMenu .MuiPaper-root.MuiPaper-elevation {
    left: 20px !important;
    max-width: calc(100% - 40px);
    top: 53px !important;
  }
  .mainHeader .profile-avatar {
    height: 36px;
    width: 36px;
    font-size: 16px;
  }
}
