.hero-section {
  padding: 50px 100px;
}

.aboutContainer{
  padding: 1.5rem 4rem 0rem 4rem
}
.aboutContainer img{
  height: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.banner{
  width: 95%;
}

@media (max-width: 480px) {
  .aboutContainer{
    padding: 1rem 1.25rem 0rem 1.25rem
  }
  .banner{
    border-radius: 15px;
  }
  .aboutFeatures img{
    width: 100%;
  }
  .banner h2{
    font-size: 24px;
    line-height: 40px;
  }
  .appSection img{
    width: 90%;
  }
  .aboutContainer img{
    width: 100%;
    margin-top: 30px;
  }
  .aboutContainer .aboutButton{
    margin-bottom: 30px;
  }
}