.main-content {
  margin-top: 40px;
  padding-top: 60px;
}

.progress-review {
  padding: 16px;
  margin-top: 160px;
  padding-top: 60px;
}

.booking-summary {
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.review.css-1oqqzyl-MuiContainer-root {
  max-width: 100%;
  padding: 0px 48px;
}

@media (max-width: 600px) {
  .app-container {
    padding: 8px;
  }

  .progress-review,
  .booking-summary {
    padding: 12px;
  }
}
