@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
  height: 100%;
  margin: 0;
  overflow: auto;
}

.home-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  /* white */
}
.mainTitle {
  padding: 80px 0 60px;
  border-top: 1px solid #231f20;
  margin: 0px 100px 0 !important;
}

.home_title {
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  margin: 0 0 20px;
}

.home_subtitle {
  display: block;
  font-size: 20px;
  line-height: 30px;
}

.testimonial .userDetails {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.homeShop .slick-prev {
  z-index: 1;
  left: -80px;
}
.homeShop .slick-next {
  right: -60px !important;
}
.MuiSlider-track {
  color: #4e297b;
  border: 1px solid#000;
  background-color: #4e297b;
}
.MuiSlider-thumb {
  color: #4e297b !important;
}
.MuiSlider-thumb {
  color: #4e297b !important;
}
.filterCategory {
  color: #000;
  background-color: #fff;
}

.filterCategory .MuiSelect-iconOpen {
  transform: none !important; /* Override the flipping */
}

em {
  font-style: normal;
}
input::placeholder {
  color: #000 !important;
  font-weight: 300;
  opacity: 100 !important;
}

.box .group .MuiPaper-rounded {
  box-shadow: none;
}

.serviceList img {
  width: 55px;
  text-align: center;
  display: flex;
  margin: 0px auto;
  border-radius: 50%;
  overflow: visible;
  height: 55px;
  cursor: pointer;
}

.serviceList p {
  text-align: center;
  font-weight: 400;
  cursor: pointer;
}

.homepageClasses .seeAll {
  cursor: pointer;
}

.homepageClasses .toggleButton {
  border: none;
}

.homepageClasses .toggleButton:hover {
  background-color: none;
}

.homepageClasses .catSlider.slick-slider {
  padding: 60px 0 80px;
  margin: 0 100px;
}

.homeShop .slick-slider .slick-list {
  width: 100%;
}
.homepageClasses .customerWrap {
  width: calc(100% - 55px);
}
.homepageClasses .customerName {
  text-align: left;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #414141;
  padding-bottom: 4px;
  width: 100%;
}

.homepageClasses .customerLocation {
  font-size: 14px;
  text-align: left;
  line-height: 18px;
  color: #868686;
  font-weight: 400;
}

.homepageClasses .offer {
  padding: 30px 100px 0;
  background-color: #fff;
}
.homepageClasses .titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.homepageClasses .titleWrap h2 {
  margin: 0;
  font-size: 35px;
  line-height: 52px;
  font-weight: 600;
  text-align: left;
}
.homepageClasses .titleWrap .seeAll {
  font-size: 15px;
  line-height: 22px;
  background: #4e297b;
  border: 1px solid #4e297b;
  border-radius: 100px;
  padding: 6px 40px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  font-family: Poppins, sans-serif;
  font-weight: 500;
}
.homepageClasses .titleWrap .seeAll:hover {
  color: #fff;
  background: #2e144e;
}

#root .userProfile {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: auto;
  background-color: white;
  border-radius: 24px;
  border: 1px solid #525252;
  max-width: 100%;
  float: right;
  position: absolute;
  right: 1.975rem;
  z-index: 9999999999;
  margin-top: 1.75rem;
}

#root .Home .sliderContainer {
  padding-bottom: 10px;
  margin-top: 0px;
  padding-left: 0px;
}

.userProfile hr {
  width: 100%;
}

.userProfile .userName {
  text-align: center;
  color: black;
  font-weight: 600;
  font-size: 1.375rem;
  padding: 15px 15px 5px 15px;
}

.userProfile h4 {
  margin: 0px auto;
  padding: 0.625rem 0px;
}

.userProfile .navLink {
  margin-top: 0.625rem;
  font-size: 0.938rem;
  color: black;
  text-decoration: none;
  cursor: pointer;
  padding: 0px 1.25rem;
}

.userProfile .navLink:nth-child(0) {
  margin-top: 0px;
}

.userProfile .navLink:hover,
.userProfile .logoutButton:hover {
  color: #0178fa;
}

.userProfile .fullWidth {
  align-self: stretch;
}

.userProfile .logoutButton {
  margin-top: 0.625rem;
  color: black;
  font-size: 0.938rem;
  cursor: pointer;
  padding: 0px 1.25rem 1.25rem 1.25rem;
}

.Home .searchBy .MuiGrid-item {
  background-color: #f6f6f6;
}

.Home .searchBy img {
  height: 1.25rem;
}

.header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0.75rem 20pt;
  background-color: #f0f0f0;
  /* neutral-100 */
  margin-bottom: 2.25rem;
}

.divider {
  height: 1px;
  background-color: #4a4a4a;
  /* stone-800 */
  width: 100%;
  max-width: 107.5rem;
  margin-top: 0.75rem;
}

.main-title {
  margin-top: 2.25rem;
  font-size: 3.75rem;
  /* 6xl */
  font-weight: bold;
  color: #4a4a4a;
  /* stone-800 */
  text-align: center;
}

.font-light {
  font-weight: 250;
}

.appointment-count {
  margin: 1.75rem 0 0.438rem;
  font-size: 1.25rem;
  /* text-xl */
  line-height: 1.5;
  text-align: center;
  color: #4a4a4a;
  /* stone-800 */
}

.testimonial .css-gwtvsh {
  left: 0px;
}

.group-wrapper h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
}

.img-wrapper img {
  width: 30px;
}

.testimonial .css-lyjrdm-MuiTypography-root {
  left: 0px;
  padding: 0px 1.25rem;
  text-align: justify;
  width: 100%;
}

.pac-container {
  margin-top: 0px !important; /* Removes any top margin */
  z-index: 1050; /* Ensures it appears above other elements */
}

.testimonial p.jack {
  /*margin-left: 3.75rem;*/
  margin-top: 0.625rem;
}

.testimonial p.newYorkUsa {
  margin-left: 3.75rem;
}

.testimonial .MuiGrid-container {
  width: 97%;
  position: relative;
  left: 3%;
}

.featured-services {
  display: flex;
  gap: 0.313rem;
  margin-top: 1.25rem;
  max-width: 107rem;
}

.featured-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  /* or 'cover' based on your requirement */
  border-radius: 0;
  /* or add any other styling */
}

.homepageClasses .Home {
  width: 100%;
  /*padding-left: 2.5rem;
  padding-right: 2.5rem; */
  margin-left: 0px;
  margin: 0px auto;
  padding: 1.5rem 6.25rem 0rem 6.25rem;
}

.homepageClasses .filterContainer {
  width: 100%;
  margin-left: 0px;
}
.slick-slide img {
  display: inline !important;
}

/* .testimonialDesc {
  min-height: auto !important;
} */

@media (max-width: 768px) {
  .main-title {
    font-size: 2.25rem;
    /* 4xl */
    margin-top: 0.625rem;
  }

  .featured-services {
    flex-direction: column;
    /* Stack images vertically on smaller screens */
    margin-top: 0.625rem;
  }
}

.homepageClasses .searchTitle {
  color: #231f20;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 2.8125rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  span {
    color: #231f20;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 2.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.searchBy {
  padding: 0px 6.25rem;
  /* box-shadow: 0px 0px 15px 0px rgba(116, 116, 116, 0.1); */
  .MuiGrid-item:first-of-type div {
    border-radius: 37.5rem 0px 0px 37.5rem;
    background-color: #fff;
  }
  .MuiGrid-item:last-of-type div {
    border-radius: 0px 37.5rem 37.5rem 0px;
  }
}
.searchBy .MuiGrid-item:first-of-type div.minPrice,
.searchBy .MuiGrid-item:first-of-type .MuiBox-root {
  background: none !important;
}

.minPrice .MuiSlider-mark {
  display: none;
}
.searchBy .priceRangeBox {
  position: relative;
}
.searchBy .minPrice {
  max-width: 90%;
  margin: 0 auto;
}
.searchBy .priceRangeBox .priceRangeError {
  position: absolute;
  top: 100%;
}

.searchBy img {
  height: 1.25rem;
}

/* .searchBy .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
  box-shadow: 0px 0px 15px 0px rgba(116, 116, 116, 0.1);
}
*/
.searchBy .MuiOutlinedInput-notchedOutline {
  border: none;
  box-shadow: 0px 0px 15px 0px rgba(116, 116, 116, 0.1);
}

.searchButton {
  width: 10.5rem;
  height: 3.158rem;
  flex-shrink: 0;
  border-radius: 60px !important;
  background-color: #4e297b !important;

  h2 {
    color: #fff;

    /* H2 */
    font-family: Poppins;
    font-size: 1.063rem;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    text-transform: capitalize;
    /* 30px */
  }
}
.searchButton:hover {
   background-color: #2e144e !important;
}

.clearSearchButton {
  width: 11.75rem;
  height: 3.158rem;
  flex-shrink: 0;
  border-radius: 3.75rem !important;
  margin-left: 0.938rem !important;
  background: var(
    --Button-Gradient,
    linear-gradient(180deg, #2e2d3a 0%, #1f1f22 100%)
  );

  h2 {
    color: #fff;

    /* H2 */
    font-family: Poppins;
    font-size: 1.063rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: capitalize;
    /* 30px */
  }
}

.salonBoxTitle {
  flex-shrink: 0;
  border-radius: 31.25rem;
  /*border: 1px solid #231f20;*/

  h3 {
    color: #231f20;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 38.4px */
    text-transform: uppercase;
  }
}

h3.salonBoxTitle {
  font-size: 32px;
  padding-top: 0.5rem;
}

/* .seeAll:hover {
  color: black;
} */

.recommendedSection .contentSection p.boxTitle {
  color: #231f20;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 21.6px */
  opacity: 100%;
  margin-bottom: 0.313rem;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.categoryBoxTitle {
  width: auto;
  height: auto;
  flex-shrink: 0;
  border-radius: 3.75rem;
  background: #f1f1f1;
  padding: 0.625rem 0.938rem;
}

.searchBy .salonType .MuiInputBase-formControl {
  border-radius: 0px 3.125rem 3.125rem 0px;
}

.searchBy .filter {
  width: 100%;
  height: 100%;
  border-radius: 3.125rem;
  background: #4e297b1a;
  box-shadow: 0px 0px 15px 0px rgba(116, 116, 116, 0.1);
  cursor: pointer;
}

.searchBy .filter span {
  font-family: poppins;
  font-size: 0.938rem;
  text-transform: capitalize;
  margin-left: 15px;
  color: #4e297b;
}

.searchBy .filter img {
  position: relative;
  top: 4px;
  left: 0.625rem;
  height: 0.938rem;
}

.searchBy.advance {
  margin-top: 1.875rem;
}

.MuiGrid-container .recommendedSection {
  display: -webkit-box;
  /* overflow-x: scroll; */
  padding-bottom: 1.563rem;
  flex-wrap: nowrap;
}

.recommendedSection img.featureImage {
  height: 22.75rem;
  cursor: pointer;
  border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
}

.recommendedSection img.NoImage {
  /* object-fit: none; */
  background-color: #24242a;
}

.recommendedSection .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 img {
  border-radius: 1.25rem 1.25rem 0rem 0rem;
}

.recommendedSection .contentSaloon {
  border-radius: 0rem 0rem 1.25rem 1.25rem;
  background: white;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.12);
  height: 100%;
}
.recommendedSection .ShopContainer {
  height: 100%;
  display: flex;
  flex-direction: column;  
} 
.recommendedSection .imageSaloon {
  position: relative;
  width: 100%;
  height: 250px; /* This height limits the image container */
  /* overflow: hidden;  */
}

.recommendedSection .imageSaloon img {
  width: 100%;
  height: 250px; /* Ensures the image fits the container */
  object-fit: cover; /* Ensures the image covers the div proportionally */
}

.recommendedSection .rating {
  width: 1rem;
  top: 3px;
  position: relative;
}

.recommendedSection .contentSection {
  background: white;
  box-shadow: 0px 6px 13px 0px #0000001f;
  border-radius: 0px 0px 1.25rem 1.25rem;
}

.recommendedSection .contentSection p {
  color: #231f20;
  font-family: Poppins;
  font-size: 0.875rem;
  /*opacity: 30%;*/
  text-align: left;
}

.homepageClasses .Home {
  padding-bottom: 0;
  padding-top: 30px;
  background: #fff;
}
.homepageClasses .Home.professional {
  padding-bottom: 100px;
}

.homepageClasses .Home.serviceList {
  padding-top: 0px;
}

.Home h3 {
  margin-top: 0px;
  font-weight: 400;
}

.customerWords {
  height: 60px;
  /*width: 13rem;*/
}

.recommendedSection .contentSection p.categoryBoxTitle {
  color: black;
  opacity: 100%;
}

.Home .slick-prev,
.Home .slick-next {
  width: 2.7rem;
  height: 2.7rem;
}
.testimonialGrid {
  height: 100%;
}
.homepageClasses .slick-track {
  float: left;
  display: flex !important;
}

/* .Home .slick-list {
  margin-left: 1.7rem;
  margin-right: 1.3rem;
} */

.recommendedSection .slick-clone {
  display: none;
}

.recommendedSection .contentSection p:nth-child(2) {
  /*color: #231f20;*/
  /*opacity: 100%;*/
  font-weight: 500;
}

.slick-slide:not(.slick-active) .recommendedSection {
  display: none;
}

.homepageClasses .recommendedSection {
  color: #231f20;
  font-family: Poppins;
  font-size: 3.125rem;
  text-align: left;
  padding-bottom: 1.875rem;
  /* margin-left: 3.125rem;
  margin-right: 3.125rem; */
  width: 100%;
}

.homepageClasses .subscription {
  display: none;
}

.homepageClasses .subscription .MuiFormGroup-root {
  flex-direction: row;
}

.homepageClasses .subscription .MuiFormGroup-root .MuiGrid-grid-xs-6 {
  padding-right: 0.938rem;
}

.homepageClasses .subscribeButton {
  display: flex;
  width: 28.125rem;
  height: 3.125rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #0178fa !important;
  color: white !important;
  border-radius: 0.625rem;
  text-transform: capitalize;
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
}

.downloadAPPBox {
  border-radius: 1.875rem;
  background: var(
    --Button-Gradient,
    linear-gradient(180deg, #2e2d3a 0%, #1f1f22 100%)
  );
}
.QR-code-for-mobile-wrapper {
  left: 15.625rem;
}
.QR-code-for-mobile-wrapper img {
  height: 8.25rem;
  width: 8.25rem;
}

.customerWords p {
  /*font-size: 1.2rem;*/
  /* padding-top: 120px; */
}

.recommendedSection .slick-next {
  right: -5px !important;
}
.recommendedSection .slick-prev {
  left: -4px !important;
}

.Home .slick-prev,
.Home .slick-next {
  width: 40px;
  height: 40px;
}

.testimonialContainer {
  background: #fff;
}
.testimonialTitle {
  font-size: 32px !important;
  line-height: 48px !important;
  font-weight: 700 !important;
}
.testimonial .userDetails {
  left: 9rem;
  top: 15rem;
}

.reviewSection {
  .jack {
    width: 2.625rem;
    position: absolute;
    font-size: 1.125rem;
    line-height: 184%;
    font-family: Poppins;
    color: #231f20;
    text-align: left;
    display: inline-block;
    top: 1.25rem;
    left: 4.688rem;
  }

  .newYorkUsa {
    width: 6.938rem;
    position: relative;
    font-size: 1rem;
    line-height: 184%;
    font-family: Poppins;
    color: #231f20;
    text-align: left;
    display: inline-block;
    opacity: 0.6;
    top: 3.125rem;
    left: 4.688rem;
  }

  .groupInner {
    top: 1.438rem !important;
    width: 3.125rem;
    height: 3.125rem;
    flex-shrink: 0;
    border-radius: 3.125rem;
  }
}

.testimonial .slick-slider .MuiGrid-grid-md-3 {
  max-width: 100%;
  flex-basis: 100%;
}

.testimonial .slick-slider .slick-prev {
  left: calc(50% - 50px);
  width: 2.5rem;
  height: 2.5rem;
  top: inherit;
  bottom: -45px;
}

.testimonial .slick-slider .slick-next {
  right: calc(50% - 50px);
  width: 2.5rem;
  height: 2.5rem;
  top: inherit;
  bottom: -45px;
}

.testimonial .slick-slider .MuiPaper-rounded img {
  display: inline;
  width: 3.15rem;
}

.testimonialContainer .slick-slide {
  padding: 0 10px;
  height: inherit !important;
  display: flex !important;
}
.testimonialContainer .slick-list {
  margin: 0 -10px;
  display: flex;
}
.testimonialContainer .testimonialArea {
  box-shadow: none;
  height: 100%;
  align-items: flex-start;
}
.testimonialContainer .testimonialArea > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.testimonial .testimonialDesc {
  min-height: auto;
}

.testimonialBottom {
  width: 100%;
  margin-top: auto;
}

.searchVanue input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-weight: 400;
}
.searchVanue input::-webkit-input-placeholder, .locationSearch input::-webkit-input-placeholder {
  color: #231f20;
  font-weight: 400;
}
.searchVanue input::-moz-placeholder, .locationSearch input::-moz-placeholder {
  color: #231f20;
  font-weight: 400;
}
.searchVanue input:-ms-input-placeholder, .locationSearch input:-ms-input-placeholder {
  color: #231f20;
  font-weight: 400;
}
.searchVanue input:-moz-placeholder, .locationSearch input:-moz-placeholder { 
  color: #231f20;
  font-weight: 400;
}
/* === Only Two Filter CSS  === */
.searchByWrap {
  display: flex;
  justify-content: center;
  align-items: center;  
}
.searchByWrap .searchBtn {
  margin-top: 15px !important;
  margin-left: 15px;
}
.searchByWrap .filterCategory {
  border-radius: 0px 37.5rem 37.5rem 0;
}
.searchByWrap .filterCategory .MuiInputBase-root {
  border-radius: 0px 37.5rem 37.5rem 0;
}


@media screen and (min-width: 1201px) and (max-width: 1366px) {
  .testimonialbackground {
    min-height: 42.5rem;
  }

  .searchBy .filter {
    width: 100%;
  }

  .searchBy .filter img {
    top: 0px;
    left: 0.125rem;
    height: 0.813rem;
  }

  .searchBy .filter span {
    font-size: 0.813rem;
  }

  .subscription {
    padding: 3.125rem 6.25rem 0px 6.25rem;
  }

  .box .group .overlap-wrapper {
    left: 50%;
  }

  .box .group .overlap-group-wrapper {
    left: 65%;
  }

  .box .group .download-the {
    font-size: 2.5rem;
  }

  .box .group .manage-your-schedule {
    font-size: 0.938rem;
  }

  .recommendedSection .contentSection p.boxTitle {
    font-size: 0.938rem;
  }

  .recommendedSection .contentSection > div {
    padding: 0.938rem;
  }
  .recommendedSection .contentSection p.categoryBoxTitle {
    font-size: 0.563rem;
    width: auto;
    padding: 0.313rem 0.313rem;
    height: auto;
  }

  .testimonial .slick-slider .slick-next {
    right: 45%;
  }

  .testimonial .slick-slider .slick-prev {
    left: 45%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .testimonialbackground {
    min-height: 42.5rem;
  }

  .header button.mobileOnly {
    display: flex;
  }

  .searchBy .filter span {
    font-size: 0.625rem;
  }

  .filterContainer .MuiGrid-grid-xs-12 {
    padding-left: 1.25rem;
  }

  .searchBy .filter img {
    top: 2px;
    left: 1px;
    height: 12px;
  }

  /* .homepageClasses .Home {
    margin-bottom: 1.875rem;
    margin-top: 1.875rem;
  } */

  .homepageClasses .searchTitle,
  .homepageClasses .searchTitle span {
    font-size: 2.5rem;
  }

  .homepageClasses .subscription.box {
    padding: 3.125rem 3.125rem 0px 3.125rem;
  }

  .homepageClasses .subscription.box p {
    font-size: 0.875rem;
  }

  .testimonial p.newYorkUsa {
    font-size: 0.75rem;
  }

  .testimonial p.jack {
    font-size: 0.875rem;
  }

  .box .group .overlap-wrapper {
    left: 45%;
  }

  .box .group .overlap-group-wrapper {
    left: 57%;
  }

  .box .group .download-the {
    font-size: 2.5rem;
  }

  .box .group .manage-your-schedule {
    font-size: 0.75rem;
  }

  .box .group .QR-code-for-mobile-wrapper {
    left: 15rem;
    height: 8.125rem;
  }

  .recommendedSection .contentSection p.boxTitle {
    font-size: 0.5rem;
  }

  .recommendedSection .contentSection > div {
    padding: 0.313rem;
  }

  .recommendedSection .contentSection p.categoryBoxTitle {
    font-size: 0.563rem;
    width: auto;
    padding: 0.313rem 0.313rem;
    height: auto;
  }

  .recommendedSection .contentSection img.searchImage {
    width: 1.875rem;
    height: 1.875rem;
  }

  .recommendedSection .contentSection p.location {
    font-size: 0.688rem;
  }

  .testimonial .slick-slider .slick-next {
    right: 23.125rem;
  }

  .testimonial .slick-slider .slick-prev {
    left: 23.125rem;
  }
}

@media screen and (min-width: 900px) and (max-width: 1024px) {
  .mainHeader .header {
    padding-left: 0px;
    padding-right: 0px;
  }

  #root .userProfile {
    right: 2.5rem;
  }

  .homepageClasses .searchTitle,
  .homepageClasses .searchTitle span {
    font-size: 2.5rem;
  }

  .Home h3 {
    margin-top: 1.25rem;
  }

  .filterContainer .MuiGrid-grid-xs-12 {
    padding-right: 0px;
  }

  .searchBy .filter span {
    font-size: 0.563rem;
  }

  .searchBy .filter img {
    position: relative;
    top: 4px;
    left: 1px;    
  }

  .homepageClasses .subscription {
    padding: 3.125rem 6.25rem 0px 6.25rem;
  }

  .mainHeader .header h1 {
    font-size: 2.188rem;
  }

  .mainHeader .header h4 {
    font-size: 1.063rem;
  }

  #root .headerDivider {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .testimonial .slick-slider .slick-next {
    right: 19.375rem;
  }

  .testimonial .slick-slider .slick-prev {
    left: 19.375rem;
  }

  .recommendedSection .contentSection p.boxTitle {
    font-size: 0.625rem;
  }

  .recommendedSection .contentSection p {
    font-size: 0.5rem;
  }

  .box .group .overlap-wrapper {
    left: 50%;
  }

  .box .group .overlap-group-wrapper {
    left: 55%;
  }
}

/* @media screen and (min-width: 600px) and (max-width: 900px) {
  .mainHeader .header {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mainHeader .header h1 {
    font-size: 2.188rem;
  }

  .headerDivider {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
} */

/* @media screen and (max-width: 600px) {
  .homepageClasses .testimonialContainer {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .testimonial .testBackground {
    min-height: auto;
  }

  .testimonial .slick-slider {
    margin-bottom: 1.875rem;
    overflow: hidden;
  }

  .homepageClasses .subscription .MuiFormGroup-root .MuiGrid-grid-xs-6 {
    max-width: 100%;
  }

  .homepageClasses .subscription .MuiFormGroup-root {
    flex-direction: column;
  }

  

  #root .headerDivider {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .homepageClasses .searchTitle,
  .homepageClasses .searchTitle span {
    font-size: 1.875rem;
  }

  .homepageClasses .searchBy {
    padding: 0px 20px;
  }

  .homepageClasses .filterContainer .MuiGrid-grid-xs-12 {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .homepageClasses .Home .MuiGrid-grid-xs-12 {
    padding-left: 0px;
  }

  .homepageClasses .searchBy .MuiGrid-item {
    max-width: 100%;
  }

  .homepageClasses .searchBy {
    flex-direction: column;
  }

  .searchBy .filter {
    height: 3.75rem;
  }

  .searchBy img {
    height: 1.563rem;
  }
} */

/* @media (max-width: 1201px) and (min-height: 430px) {
  .homepageClasses .searchTitle {
    font-size: 1.5rem;
  }

  .homepageClasses .searchTitle span {
    font-size: 1.5rem;
  }

  .searchButton {
    width: 8.5rem;
    height: 2.6rem;
  }

  .searchButton h2 {
    font-size: 1rem;
  }

  h3.salonBoxTitle {
    font-size: 1.2rem;
    height: 2.5rem;
    padding-top: 0.5rem;
  }

  .Home h3 {
    margin-top: 0px;
  }

  .subscription p {
    font-size: 1rem;
  }
} */

/* @media (max-width: 1280px) and (min-height: 800px) {
  .homepageClasses .searchTitle {
    font-size: 1.5rem;
  }

  .homepageClasses .searchTitle span {
    font-size: 1.5rem;
  }

  .searchButton {
    width: 8.5rem;
    height: 2.6rem;
  }

  .searchButton h2 {
    font-size: 1rem;
  }

  h3.salonBoxTitle {
    font-size: 1.2rem;
    height: 2.5rem;
    padding-top: 0.5rem;
  }

  .Home h3 {
    margin-top: 0px;
  }

  .subscription p {
    font-size: 1rem;
  }
  
} */

/* @media (max-width: 1366px) and (min-height: 768px) {
  .homepageClasses .searchTitle {
    font-size: 1.5rem;
  }

  .homepageClasses .searchTitle span {
    font-size: 1.5rem;
  }

  .searchButton {
    width: 8.5rem;
    height: 2.6rem;
  }

  .searchButton h2 {
    font-size: 1rem;
  }

  h3.salonBoxTitle {
    font-size: 1.2rem;
    height: 2.5rem;
    padding-top: 0.5rem;
  }

  .Home h3 {
    margin-top: 0px;
  }

  .subscription p {
    font-size: 1rem;
  }

 
}

@media (max-width: 1440px) and (min-height: 900px) {
  .searchBy .filter span {
    font-size: 0.648rem;
  }
} */

/* New CSS */
/* For screens wider than 1920 */
@media (max-width: 1920) {
  /* filter section */
  .homepageClasses .searchTitle {
    font-size: 3.75rem;
    font-weight: 300;
  }
  .homepageClasses .searchTitle span {
    font-size: 3.75rem;
    font-weight: 700;
  }
  .searchButton h2 {
    font-size: 1.0625rem;
    font-weight: 400;
  }

  .homepageClasses .searchBy input,
  .filterCategory em,
  .minPrice,
  .minPrice p,
  .maxPrice,
  .maxPrice p {
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    opacity: 100%;
  }

  .searchBy .filter span {
    font-size: 0.938rem;
    font-weight: 400;
  }
  .searchBy svg,
  .searchBy img {
    height: 0.9rem;
    width: 0.9rem;
  }
  /* filter section */
  /* recommended section */
  .recommendedSection .contentSection p.boxTitle {
    font-size: 1.125rem;
    font-weight: 600;
  }
  .recommendedSection .contentSection p {
    font-size: 0.875rem;
    font-weight: 400;
  }
  h3.salonBoxTitle {
    font-size: 2rem;
    font-weight: 400;
  }
  /* recommended section */
  /* subscription section */
  .subscription h5 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .homepageClasses .subscription.box p.subTitle {
    font-size: 1rem;
    font-weight: 400;
  }
  .homepageClasses .subscription.box p {
    font-size: 1rem;
    font-weight: 400;
  }

  .box h3 {
    font-size: 1.1rem;
    font-weight: 400;
  }
  .box .MuiRadio-root label {
    font-size: 1.1rem;
    font-weight: 400;
  }
  .homepageClasses .subscribeButton {
    font-size: 1.125rem;
    font-weight: 500;
  }
  /* subscription section */
  /* download section */
  .homepageClasses .box p.download-the {
    font-size: 2.5rem;
    font-weight: 600;
  }
  .box .group .manage-your-schedule {
    font-size: 1rem;
    font-weight: 400;
  }
  /* download section */
  /* testimonial section */
  .testimonialContainer .customerWords p {
    /*font-size: 1.0000rem;*/
    font-weight: 400;
  }
  .testimonial p.jack {
    font-size: 1rem;
    font-weight: 400;
  }
  .testimonial p.newYorkUsa {
    font-size: 1rem;
    font-weight: 400;
  }
  /* testimonial section */
}

/* For screens wider than 1600px */
@media (max-width: 1600px) {
  /* filter section */
  .homepageClasses .searchTitle {
    font-size: 3.125rem;
    font-weight: 350;
  }
  .homepageClasses .searchTitle span {
    font-size: 3.125rem;
    font-weight: 683;
  }
  .searchButton h2 {
    font-size: 1.0417rem;    
  }
  .homepageClasses .searchBy input,
  .filterCategory em,
  .minPrice,
  .minPrice p,
  .maxPrice,
  .maxPrice p {
    font-size: 1.0417rem;    
  }
  /* .searchBy .filter span {
    font-size: 0.9rem;
    font-weight: 333;
  } */
  .searchBy .filter img {
    left: 0.225rem;
  }
  /* filter section */
  /* recommended section */

  .recommendedSection .contentSection p.boxTitle {
    font-size: 0.9375rem;
    font-weight: 500;
  }
  .recommendedSection .contentSection p {
    font-size: 0.7292rem;
    font-weight: 333;
  }

  h3.salonBoxTitle {
    font-size: 1.5667rem;
    font-weight: 400;
    height: 3rem;
    width: 16.2rem;
  }
  /* recommended section */
  /* download section */
  .homepageClasses .box p.download-the {
    font-size: 3.6458rem;
    font-weight: 500;
  }
  .box .group .manage-your-schedule {
    font-size: 1.3021rem;
    font-weight: 333;
    margin-top: 3.825rem;
  }

  .homepageClasses .overlap-group-wrapper {
    left: 82%;
  }
  .recommendedSection .imageSaloon {
    height: 200px;
  }
  .recommendedSection .imageSaloon img {
    height: 200px;
  }

  /* download section */
  /* subscription section */
  .subscription h5 {
    font-size: 2.075rem;
    font-weight: 600;
  }
  .homepageClasses .subscription.box p.subTitle {
    font-size: 1.0417rem;
    font-weight: 333;
  }
  .homepageClasses .subscription.box p {
    font-size: 0.9333rem;
    font-weight: 373;
  }
  .box h3 {
    font-size: 0.9967rem;
    font-weight: 333;
  }
  .box .package span {
    font-size: 0.9333rem;
    font-weight: 333;
    padding-top: 0px;
  }
  .homepageClasses .subscribeButton {
    font-size: 1.175rem;
    font-weight: 317;
  }
  /* subscription section */
  /* testimonial section */
  .testimonialContainer .customerWords p {
    font-size: 1.6rem;
    font-weight: 700;
  }
  .testimonial p.jack {
    font-size: 0.8333rem;
    font-weight: 333;
  }
  .testimonial p.newYorkUsa {
    font-size: 0.8333rem;
    font-weight: 333;
  }
  .testimonial .slick-slider .slick-prev {
    left: 46%;
    width: 2.5rem;
    height: 2.5rem;
  }
  .testimonial .slick-slider .slick-next {
    right: 46%;
    width: 2.5rem;
    height: 2.5rem;
  }
  .testimonial .userDetails {
    left: 7rem;
    top: 16rem;
  }
  .testimonial p.jack {
    margin-top: 0.825rem;
  }
  /* .homepageClasses .customerName,
  .homepageClasses .customerLocation {
    font-size: 13px;
  } */
  /* testimonial section */
}

/* For screens wider than 1440px */
@media (max-width: 1440px) {
  /* filter section */
  .homepageClasses .searchTitle {
    font-size: 2.8125rem;
    font-weight: 225;
  }

  #root .Home .sliderContainer {
    padding-left: 0px;
  }

  .homepageClasses .searchTitle span {
    font-size: 2.8125rem;
    font-weight: 625;
  }
  .searchButton h2,
  .clearSearchButton h2 {
    font-size: 0.9975rem;    
  }
  .searchBy .filterCategory {
    height: 55px;
  }
  .homepageClasses .searchBy input,
  .filterCategory em,
  .minPrice,
  .minPrice p,
  .maxPrice,
  .maxPrice p {
    font-size: 0.9375rem;    
  }
  /* .searchBy .filter span {
    font-size: 0.7035rem;
    font-weight: 300;
  } */
  /* filter section */
  /* recommended section */

  .recommendedSection .contentSection p.boxTitle {
    font-size: 0.9438rem;
    font-weight: 450;
  }
  .recommendedSection .contentSection p.location {
    font-size: 0.7563rem;
    font-weight: 300;
  }
  h3.salonBoxTitle {
    font-size: 1.5rem;
    font-weight: 300;
    height: 2.7rem;
  }
  /* recommended section */
  /* download section */
  .homepageClasses .box p.download-the {
    font-size: 2.2813rem;
    font-weight: 450;
    top: 12.125rem;
  }

  .box .group .overlap-wrapper {
    left: 57%;
  }
  .box .group .overlap-group-wrapper {
    left: 80%;
  }
  .box .img-wrapper {
    top: 5.7rem;
  }
  .box .QR-code-for-mobile-wrapper {
    top: 30.313rem;
  }
  .box .group-wrapper {
    top: 6rem;
  }
  .box .group .manage-your-schedule {
    font-size: 1.1019rem;
    font-weight: 213;
    top: 16.5rem;
  }
  .box .glam-wrapper {
    height: 35.5rem;
    width: 16.9rem;
  }
  .box .overlap-group-wrapper .overlap-group {
    height: 30.75rem;
    width: 14.813rem;
  }
  /* download section */
  /* subscription section */
  .subscription h5 {
    font-size: 1.6875rem;
    font-weight: 600;
  }
  .homepageClasses .subscription.box p.subTitle {
    font-size: 1.0937rem;
    font-weight: 350;
  }
  .homepageClasses .subscription.box p {
    font-size: 0.85rem;
    font-weight: 400;
  }
  .box h3 {
    font-size: 0.995rem;
    font-weight: 300;
  }
  .box .package span {
    font-size: 0.85rem;
    font-weight: 300;
    padding-top: 0px;
  }
  .homepageClasses .subscribeButton {
    font-size: 0.9838rem;
    font-weight: 375;
  }
  /* subscription section */
  /* testimonial section */
  .testimonialContainer .customerWords p {
    font-size: 1;
    font-weight: 300;
  }
  .testimonial .userDetails {
    left: 5rem;
    top: 13rem;
  }
  .testimonial p.jack {
    font-size: 0.85rem;
    font-weight: 300;
    margin-top: 1rem;
  }
  .testimonial p.newYorkUsa {
    font-size: 0.85rem;
    font-weight: 300;
  }
  .testimonial .slick-slider .slick-prev {
    left: 46%;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 10;
  }
  .testimonial .slick-slider .slick-next {
    right: 46%;
    width: 2.5rem;
    height: 2.5rem;
  }
  /* .homepageClasses .customerName,
  .homepageClasses .customerLocation {
    font-size: 13px;
  } */
  /* testimonial section */
}

/* For screens wider than 1366px */
@media (max-width: 1366px) {
  /* filter section */
  .homepageClasses .searchTitle {
    font-size: 2.668rem;
    font-weight: 242;
  }  

  #root .Home .sliderContainer {
    padding-left: 0px;
  }

  .homepageClasses .searchTitle span {
    font-size: 2.668rem;
    font-weight: 700;
  }
  .searchButton h2,
  .clearSearchButton h2 {
    font-size: 0.9993rem;    
  }

  /* .homepageClasses .customerName,
  .homepageClasses .customerLocation {
    font-size: 13px;
  } */

  .homepageClasses .searchBy input,
  .filterCategory em,
  .minPrice,
  .minPrice p,
  .maxPrice,
  .maxPrice p {
    font-size: 0.8rem;    
  }
  .searchBy .filter span {
    font-size: 0.8893rem;    
  }
  /* filter section */
  /* recommended section */

  .recommendedSection .contentSection p.boxTitle {
    font-size: 0.8004rem;    
  }
  .recommendedSection .contentSection p.location {
    font-size: 0.7225rem;    
  }
  h3.salonBoxTitle {
    font-size: 1.4229rem;    
    height: 2.7rem;
    margin-top: 0px;
  }
  /* recommended section */
  /* download section */
  .homepageClasses .box p.download-the {
    font-size: 1.7786rem;    
  }
  .android img,
  .ios img {
    max-width: 100%;
  }
  .box .group .overlap-wrapper {
    left: 59%;
  }
  .box .group .overlap-group-wrapper {
    left: 80%;
  }
  .box .img-wrapper {
    top: 7.7rem;
  }
  .box .QR-code-for-mobile-wrapper {
    top: 28.313rem;
  }
  .box .group-wrapper {
    top: 8rem;
  }
  .box .group .manage-your-schedule {
    font-size: 1.007rem;
    font-weight: 213;
    top: 18.5rem;
    margin-top: 0px;
  }
  .box .glam-wrapper {
    height: 31.5rem;
    width: 14.9rem;
  }
  .box .overlap-group-wrapper .overlap-group {
    height: 28.75rem;
    width: 13.813rem;
  }
  /* download section */
  /* subscription section */
  .subscription h5 {
    font-size: 1.6008rem;
    font-weight: 600;
  }
  .homepageClasses .subscription.box p.subTitle {
    font-size: 0.9993rem;
    font-weight: 385;
  }
  .homepageClasses .subscription.box p {
    font-size: 0.8115rem;
    font-weight: 385;
  }
  .box h3 {
    font-size: 0.8826rem;
    font-weight: 285;
  }
  .box .package span {
    font-size: 0.8115rem;
    font-weight: 285;
    padding-top: 0px;
  }
  .homepageClasses .subscribeButton {
    font-size: 0.9004rem;
    font-weight: 356;
  }
  /* subscription section */
  /* testimonial section */

  .testimonialContainer .customerWords p {
    font-size: 1.129rem;
    font-weight: 600;
  }
  .testimonial p.jack {
    font-size: 0.8115rem;
    font-weight: 285;
    margin-top: 1rem;
  }
  .testimonial p.newYorkUsa {
    font-size: 0.8115rem;
    font-weight: 285;
  }
  .testimonial .slick-slider .slick-prev {
    left: 46%;
    width: 2.5rem;
    height: 2.5rem;
  }
  .testimonial .slick-slider .slick-next {
    right: 46%;
    width: 2.5rem;
    height: 2.5rem;
  }
  /* .testimonial .testimonialSecond{
    min-height: 32rem;
  } */
  .testimonial .slick-slider {
    margin-top: 15px;
  }
  /* testimonial section */
}

/* For screens wider than 1280px */
@media (max-width: 1280px) {
  /* filter section */
  .homepageClasses .searchTitle {
    font-size: 1.5rem;
    font-weight: 200;
    padding-top: 3.25rem;
    padding-bottom: 1.595rem;
  }
  .homepageClasses .searchTitle span {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .searchButton h2, .clearSearchButton h2 {
    font-size: 0.9rem;
  }
  .homepageClasses .searchBy input,
  .filterCategory em,
  .minPrice,
  .minPrice p,
  .maxPrice,
  .maxPrice p {
    font-size: 0.8rem;
    font-weight: 400;
    height: 10px;
  }

  
  .homepageClasses .customerCircle {
    margin-right: 10px;
  }

  .homepageClasses .testimonial .slick-slider {
    margin-top: 10px;
  }

  .testimonial .testtmonialArea {
    box-shadow: none;
  }

  .homepageClasses .titleWrap h2 {
    font-size: 30px;
    line-height: 45px;
  }
  #root .Home .sliderContainer {
    padding-left: 0px;
  }

  .serviceList img {
    width: 45px;
    height: 45px;
  }

  .Home.serviceList .MuiGrid-root {
    padding-left: 0px;
  }

  .searchBy .filter span {
    font-size: 0.8rem;    
  }
  .searchBy .filterCategory {
    height: 2.7rem;
    font-size: 0.8rem;
  }
  .searchBy svg,
  .searchBy img {
    width: 0.9rem;
  }
  .searchBy .priceRangeBox .priceRangeError {    
    top: calc(100% + 20px);
  }
  .searchBy .filter img {
    width: 0.9rem;
    position: relative;
    top: 0.2rem;
  }
  .homepageClasses .searchButton {
    width: 8.5rem;
    height: 45px;
  }
  .homepageClasses .clearSearchButton {
    width: 8.5rem;
    height: 2.158rem;
  }
  /* filter section */
  /* recommended section */

  h3.salonBoxTitle {
    width: 11.2rem;
  }

  .recommendedSection .contentSection p.boxTitle {
    font-size: 0.8rem;
    font-weight: 600;
  }
  .recommendedSection .contentSection p {
    font-size: 0.7833rem;
    font-weight: 250;
  }
  .recommendedSection .contentSection p.location {
    font-weight: 467;
  }
  .Home button img {
    width: 2.5rem;
  }
  h3.salonBoxTitle {
    font-size: 1rem;
    font-weight: 367;
    height: 1.8rem;
    margin-top: 0px;
    padding-top: 0.3rem;
  }
  /* recommended section */

  /* subscription section */
  .subscription h5 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .homepageClasses .subscription.box p.subTitle {
    font-size: 0.9933rem;
    font-weight: 397;
  }
  .homepageClasses .subscription.box p {
    font-size: 0.6667rem;
    font-weight: 367;
  }
  .box h3 {
    font-size: 0.8533rem;
    font-weight: 267;
  }
  .box .package span {
    font-size: 0.7767rem;
    font-weight: 267;
    padding-top: 0px;
  }
  .homepageClasses .subscribeButton {
    font-size: 0.8rem;
    font-weight: 333;
    height: 2.125rem;
  }
  /* subscription section */
  /* download section */
  .homepageClasses .box p.download-the {
    font-size: 1.6667rem;
    font-weight: 400;
    top: 14.125rem;
  }
  .box .group .manage-your-schedule {
    font-size: 0.6667rem;
    font-weight: 267;
  }
  .box .group .overlap-wrapper {
    left: 54%;
  }
  .box .group .overlap-group-wrapper {
    left: 78%;
  }
  .box .img-wrapper {
    top: 8.7rem;
  }
  .box .QR-code-for-mobile-wrapper {
    top: 28.313rem;
  }
  .box .group-wrapper {
    top: 9rem;
  }
  .box .group .manage-your-schedule {
    font-size: 0.9rem;
    font-weight: 213;
    top: 20.5rem;
  }
  .box .glam-wrapper {
    height: 32.5rem;
    width: 15.9rem;
  }
  .box .overlap-group-wrapper .overlap-group {
    height: 28.75rem;
    width: 13.813rem;
  }
  /* download section */
  /* testimonial section */

  .testimonialContainer .customerWords p {
    font-size: 1.6rem;
    font-weight: 700;
  }
  .testimonial .slick-slider .MuiPaper-rounded img {
    height: 2.525rem;
    width: 2.525rem;
  }
  /* .testimonial .testimonialSecond {
    min-height: 29.125rem;
  } */

  .testimonial .testimonialArea {
    box-shadow: none;
  }
  /* .testimonial .testimonialDesc {
    font-size: 12px;
    min-height: 110px;
  } */
  .testimonial .userDetails {
    left: 4rem;
    top: 13rem;
    margin-top: 0.8rem;
  }
  .testimonial p.jack {
    font-size: 0.8667rem;
    font-weight: 400;
    margin-left: 3.35rem;
  }
  .testimonial p.newYorkUsa {
    font-size: 0.8667rem;
    font-weight: 267;
    margin-left: 3.35rem;
  }
  .testimonial .slick-slider .slick-prev {
    left: 45%;
    width: 2.5rem;
    height: 2.5rem;
  }
  .testimonial .slick-slider .slick-next {
    right: 45%;
    width: 2.5rem;
    height: 2.5rem;
  }
  .testimonial .testimonialDesc {
    font-size: 14px;
    line-height: 24px;
  }
  .homepageClasses .customerName {
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 0;
  }
  

  /* testimonial section */
}

/* @media (min-width: 1200px) and (max-width: 1280px) {
  .searchVanue input {
    padding-right: 8px;
  }
} */

/* For screens wider than 1200px */
@media (max-width: 1200px) {
  /* filter section */
  .homepageClasses .searchTitle {
    font-size: 2.3438rem;    
  }
  .homepageClasses .searchTitle span {
    font-size: 2.3438rem;    
  }
  .filterCategory {
    height: 3.2rem;
  }
  .searchButton h2,
  .clearSearchButton h2 {
    font-size: 0.8813rem;    
  }
  /* .homepageClasses .searchBy input,
  .filterCategory em,
  .minPrice,
  .minPrice p,
  .maxPrice,
  .maxPrice p {
    font-size: 0.7rem;    
  } */
  /* .searchBy .filter span {
    font-size: 0.6813rem;
  } */
  /* filter section */
  /* recommended section */
  .homepageClasses .Home .slick-list {
    margin-left: 0;
  }

  .homeShop .slick-slider .slick-list {
    width: 100%;
  }

  .recommendedSection .contentSection p.boxTitle {
    font-size: 0.6631rem;
    font-weight: 575;
    margin-left: 0.2rem;
  }
  .recommendedSection .contentSection p.location {
    margin-left: 0.2rem;
  }
  .recommendedSection .contentSection p:nth-child(2) {
    margin-left: 0.2rem;
  }
  .recommendedSection .contentSection p {
    font-size: 0.5469rem;
    font-weight: 250;
  }
  h3.salonBoxTitle {
    font-size: 1.25rem;
    font-weight: 350;
    height: 2.4rem;
    padding-top: 0.4rem;
  }
  .recommendedSection .contentSection p:nth-child(2) {
    /*margin-top: 3rem;*/
    margin-bottom: 0.5rem;
  }

  /* recommended section */
  /* subscription section */
  .subscription h5 {
    font-size: 1.4063rem;
    font-weight: 600;
  }
  .homepageClasses .subscription.box p.subTitle {
    font-size: 0.9813rem;
    font-weight: 350;
  }
  .homepageClasses .subscription.box p {
    font-size: 0.705rem;
    font-weight: 370;
  }
  .box h3 {
    font-size: 0.8875rem;
    font-weight: 250;
  }
  .box .package span {
    font-size: 0.695rem;
    font-weight: 250;
    padding-top: 0px;
  }
  .homepageClasses .subscribeButton {
    font-size: 0.8431rem;
    font-weight: 313;
  }
  /* subscription section */
  /* download section */
  .homepageClasses .box p.download-the {
    font-size: 1.5625rem;
    font-weight: 375;
  }
  .box .group .manage-your-schedule {
    font-size: 0.625rem;
    font-weight: 250;
  }
  .box .group .overlap-wrapper {
    left: 54%;
  }
  .box .group .overlap-group-wrapper {
    left: 78%;
  }
  .box .img-wrapper {
    top: 9.7rem;
  }
  .box .QR-code-for-mobile-wrapper {
    top: 28.313rem;
  }
  .box .group-wrapper {
    top: 10rem;
  }
  .box .group .manage-your-schedule {
    font-size: 0.88rem;
    font-weight: 213;
    top: 20.5rem;
  }
  .box .glam-wrapper {
    height: 30.5rem;
    width: 14.9rem;
  }
  .box .overlap-group-wrapper .overlap-group {
    height: 28.75rem;
    width: 13.813rem;
  }
  /* download section */
  /* testimonial section */
  .testimonial .userDetails {
    left: 4rem;
    top: 12rem;
    margin-top: 0.8rem;
  }
  .testimonialContainer .customerWords p {
    font-size: 1.05rem;
    font-weight: 700;
  }
  .testimonial p.jack {
    font-size: 0.685rem;
    font-weight: 450;
    margin-top: 1rem;
  }
  .testimonial p.newYorkUsa {
    font-size: 0.685rem;
    font-weight: 250;
  }
  .testimonial .slick-slider .slick-prev {
    left: 45%;
    width: 2.5rem;
    height: 2.5rem;
  }
  .testimonial .slick-slider .slick-next {
    right: 45%;
    width: 2.5rem;
    height: 2.5rem;
  }
  .testimonial .customeerCircle {
    height: 40px;
    width: 40px;
    margin-right: 8px;
  }
  .testimonial .customeerCircle .customerInitials {
    font-size: 12px;
  }
  /* testimonial section */
}

/* For screens wider than 1024px */
@media (max-width: 1199px) {
  .mainTitle {
    padding: 40px 0;
    margin: 0 70px !important;
  }
  .home_title {
    font-size: 50px;
    line-height: 60px;
  }
  .home_subtitle {
    font-size: 18px;
    line-height: 24px;
  }
  .searchBy {
    padding: 0 70px;
  }
  .menu-button {
    font-size: 16px;
  }
  .homepageClasses .catSlider.slick-slider {
    margin: 0 70px;
    padding: 50px 0;
  }
  .catsliderText {
    font-size: 14px !important;
  }
  .homepageClasses .offer {
    padding: 0 70px;
  }
  .homepageClasses .Home.professional {
    padding-bottom: 70px;
  }
  .homepageClasses .titleWrap h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .homepageClasses .Home {
    padding: 0 70px;
  }
  .Home .slick-prev,
  .Home .slick-next {
    width: 30px;
    height: 30px;
  }
  .homeShop .slick-prev {
    left: -50px;
  }
  .homeShop .slick-next {
    right: -35px !important;
  }

  .recommendedSection .imageSaloon {
    height: 180px;
  }
  .recommendedSection .imageSaloon img {
    height: 180px;
  }
  .recommendedSection .contentSaloon img.searchImage {
    width: 30px;
    height: 30px;
  }
  .ios img {
    width: 200px;
  }
  .android img {
    width: 185px;
  }
  .homepageClasses .testimonialContainer {
    padding: 70px 50px;
  }
  .testimonialTitle {
    font-size: 24px !important;
    line-height: 30px !important;
  }
  .testimonial .slick-slider .slick-prev,
  .testimonial .slick-slider .slick-next {
    width: 30px;
    height: 30px;
    bottom: -15px;
  }
  .testimonial .slick-slider .slick-next {
    right: 46%;
  }
  .testimonial .slick-slider .slick-prev {
    left: 46%;
  }
  .searchBy .priceRangeBox .priceRangeError {
    top: calc(100% + 30px);
    font-size: 12px;
  }
}

@media (max-width: 899px) {
  .mainTitle {
    padding: 30px 0;
    margin: 0 50px !important;
  }
  .home_title {
    font-size: 36px;
    line-height: 40px;
  }
  .searchBy {
    padding: 0 50px;
  }
  .homepageClasses .catSlider.slick-slider {
    margin: 0 50px;
  }
  .homepageClasses .Home.professional {
    padding-bottom: 30px;
  }
  .homepageClasses .offer,
  .homepageClasses .Home {
    padding: 0 50px;
  }
  .testimonial .slick-slider .slick-next {
    right: 44%;
  }
  .testimonial .slick-slider .slick-prev {
    left: 44%;
  }
  .testimonialGrid {
    padding-top: 0;
  }
  .testimonialGrid {
    padding-top: 15px !important;
  }
  .homepageClasses .testimonialContainer {
    margin-top: 0;
    padding: 50px 30px;
  }
}
@media (max-width: 600px) {
  .mainTitle {
    padding: 20px 0;
    margin: 0 20px !important;
  }
  .home_title {
    font-size: 30px;
    line-height: 36px;
  }
  .home_subtitle {
    font-size: 16px;
    line-height: 20px;
  }
  .searchBy {
    padding: 0 20px;
  }
  .searchBy .minPrice {
    height: auto;
  }
  .searchBy .filter {
    height: 45px;
  }
  .searchBy.advance {
    margin-top: 0;
  }
  .searchBy .priceRangeBox .priceRangeError {
    top: inherit;
    font-size: 12px;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
}
  .homepageClasses .catSlider.slick-slider {
    padding: 20px 0;
    margin: 0 20px;
  }
  .homepageClasses .offer,
  .homepageClasses .Home {
    padding: 0 20px;
  }
  .homepageClasses .titleWrap h2 {
    font-size: 20px;
    line-height: 24px;
    width: calc(100% - 110px);
  }
  .homepageClasses .titleWrap .seeAll {
    font-size: 13px;
    line-height: 18px;
    padding: 6px 18px;
  }
  .homepageClasses .recommendedSection {
    padding-bottom: 20px;
  }
  .homepageClasses .testimonialContainer {
    padding: 50px 20px 20px;
  }
  .testimonialTitle {
    font-size: 20px !important;
    line-height: 24px !important;
  }
  .testimonial .testimonialArea {
    height: auto;
  }
  .testimonial .testimonialDesc {
    font-size: 14px;
    min-height: auto;
  }
  .testimonial .slick-slider .slick-prev {
    left: 35%;
  }
  .homepageClasses .searchBy input,
  .filterCategory em,
  .minPrice,
  .minPrice p,
  .maxPrice,
  .maxPrice p {
    font-size: 14px;
  }
  .searchBy .MuiGrid-item:first-of-type div,
  .searchBy .filterCategory,
  .searchBy .salonType .MuiInputBase-formControl,
  .searchBy
    .MuiAutocomplete-hasClearIcon.css-ka7ti6-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    border-radius: 37.5rem;
  }
  .filterContainer .css-zow5z4-MuiGrid-root > .MuiGrid-item {
    padding-left: 0 !important;
  }
  .homepageClasses .filterContainer {
    margin-left: -8px;
  }
  .searchByWrap .searchBtn {
    width: 100%;
  }
  .homepageClasses .searchButton {
    /* width: calc(100% - 45px); */
    width: 100%;
  }
}

/* @media (max-width: 480px) {
  .searchBy .MuiGrid-item:first-of-type div,
  .searchBy .salonType .MuiInputBase-formControl {
    border-radius: 0px;
  }

  .homepageClasses .filterContainer .locationSearch {
    padding-left: 0px;
  }

.homepageClasses .testimonialContainer{
  padding: 0px 20px 50px 20px;
  margin-left: 0px;
  margin-right: 0px
}

  .homepageClasses .searchTitle {
    font-size: 1.5rem;
    font-weight: 300;
  }
  .homepageClasses .searchTitle span {
    font-size: 1.5rem;
    font-weight: 800;
  }
  .homepageClasses .searchBy input,
  .filterCategory em,
  .minPrice,
  .minPrice p,
  .maxPrice,
  .maxPrice p {
    font-size: 15px;
    font-weight: 300;
  }
  .searchBy .filter span {
    font-size: 1rem;
    font-weight: 213;
  }
  .searchButton h2 {
    font-size: 1.2rem;
    font-weight: 213;
  }
  .searchBy .searchButton {
    margin-top: 1rem;
  }

  .subscription h5 {
    font-size: 1.5rem;
    font-weight: 320;
  }
  .homepageClasses .subscription.box {
    padding: 0px;
  }
  .homepageClasses .subscription.box p.subTitle {
    font-size: 0.8rem;
    font-weight: 320;
    padding: 0rem 0.5rem;
  }
  .homepageClasses .subscription.box p {
    font-size: 0.7rem;
  }

.subscription .lighbProBox .MuiRadioGroup-root{
  -webkit-flex-wrap:nowrap;
  margin-bottom: 1rem;
}
.subscription .lighbProBox .MuiRadioGroup-root .MuiGrid-grid-xs-6{
  margin-bottom: 1rem;
}
.homepageClasses .subscription .subscribeContent{
  margin-top: 0px;
  padding-top: 0px;
}
.homepageClasses .box{
  margin: 0px;
  padding: 0px 20px;
}

.box .overlap-group-wrapper .overlap-group{
  height: 13.75rem;
  width: 6.813rem;
}
.box .group .overlap-group-wrapper {
  left: 5rem;
  top: 13rem;
}
.box .glam-wrapper {
  height: 10.5rem;
  width: 4.9rem;
}
.download-the, .manage-your-schedule, .group-wrapper{
  left: 2rem;
}
.QR-code-for-mobile-wrapper img{
  width: 2rem;
  height: 2rem;
}
.homepageClasses .recommendedSection{
  margin-left: 0rem;
  width: 100%;
}
.homepageClasses .testimonial .MuiPaper-elevation{
  box-shadow: none;
}
.testimonial .slick-slider .slick-prev, .testimonial .slick-slider .slick-next{
  top:28rem;
}

.homepageClasses .Home .slick-list{
  margin-left: 0px;
  margin-right: 0px;
}
.testimonial .slick-slider .MuiGrid-grid-md-3{
  height: 100%;
  margin-top: 1.5rem;
}
.testimonial .userDetails{
  left: 2rem;
}
.testimonial .slick-slider .slick-prev{
  left: 4.125rem; 
}
.testimonial .slick-slider .slick-next{
  left: 7.125rem;
}
.homepageClasses .searchButton {
  width: 90%;
  height: 2.8rem;
}
.locationSearch .MuiInputBase-root{
  padding-left: 0px;
}
.homepageClasses .Home{
  padding: 0px 20px;
}
.homepageClasses button.seeAll{
  margin-top: 1rem;
  width: 140px;
}
.recommendedSection .contentSection p.categoryBoxTitle{
  font-size: 0.9rem;
}
.homepageClasses .businessTypeContainer{
  margin-top: 45px;
}
#root .homepageClasses .extraSpaceFilter{
  padding-top: 0px;
}
.homepageClasses .minPrice{
  padding-top: 0px ;
}
.home_title{
  font-size: 32px;
}

.homepageClasses .seeAll{
  margin-top: 15px;
}
.testimonialContainer .customerWords p{
  font-weight: 700;
}
.testimonial .testimonialSecond{
  padding: 0px;
}
.testimonial .slick-slider{
  margin-bottom: 0px;
}
.homepageClasses .testimonial .slick-slider {
  margin-top: 0px;
}
.homepageClasses .catSlider.slick-slider{
  padding: 5px 20px ;
}
.homepageClasses .offer{
  padding: 0px 20px;
}
.homepageClasses .offer img{
  width: 100%;
  height: auto;
}
.homepageClasses .offer img.seeAll{
  width: auto;
}

.box .group-wrapper {
  top: 0rem;
  left: 0rem;
}
.box .img-wrapper {
  left: 16rem;
  top: -0.3rem;
}
.homepageClasses .box p.download-the{
  top:5.125rem;
  left: 0rem;
}

.homepageClasses .testimonial .testimonialDesc{
  font-size: 13px;
}
.homepageClasses .testimonial .MuiRating-root{
  display: flex;
}
.homepageClasses .customerName, .homepageClasses .customerLocation {
  font-size: 12px;
}

.homepageClasses .box .group p.manage-your-schedule{
  top:9.5rem;
  left: 0rem;
}
.box .group .overlap-wrapper {
  left: 55%;
  top: 14.688rem;
}
.box .group{
  height: 28rem;
}
.recommendedSection .imageSaloon{
  height: 250px;
}
} */
