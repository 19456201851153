.team-selection {
  width: 100%;
  margin: 0 auto;  
}

.team-selection h2.title{
  font-size: 36px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
}

.team-selection .css-1mi3tt8 {
  -webkit-align-items: flex-start;
}

.title.css-lcem0f-MuiTypography-root {
  font-size: 36px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
}

.title {
  margin-top: 2rem;
  font-weight: bold;
  text-align: left;
  color: black;
}
