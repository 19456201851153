@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.footerContainer{
  width: 100%;
  background-color: #231F20;
  color: white;
  top: 0px,
}

.footerContainer .footer {
  max-width: 100%;
  padding-left: 6.25rem;
  padding-right: 6.25rem;
  background: #171717;
}

.footerContainer .footer .footerSocial{
  display: flex;
  margin-Top: 25px;
  gap: 25px;
}

.footerContainer .footer .MuiGrid-container{
  padding: 20px 0px;
  margin-top: 0px;
}


.footerContainer .footer p a {
  color: #f6f6f6;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;  
}

.footerContainer .footer p a:hover {
  color: white;
  /* font-weight: 500; */
} 

.footer .MuiSvgIcon-root {
  margin-right: 0.938rem;
}

.footerBox p{    
    color: white;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 16px;
}

.footer .footerCopyWrite{
  padding-bottom: 20px;
}

.footer .footerHeads {  
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  color: white;
}

.footerContactInfo .footerAddressTop{
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  margin-bottom: 10px;
}
.footerContactInfo .footerAddressTop img{
  margin-right: 10px;
}

.footer .footerAddress{  
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: rgb(255, 255, 255);  
}
.footer .footerAddress span {
  display: block;
}


.footer .footerCopyWrite {  
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: rgb(255, 255, 255);
}

.footer img.footerIcon {
  width: 150px;
}

.footerSocial {
  margin-left: 0px;
}

.footerSocial img {
  width: 20px;
  height: 20px;
}
.footer .footerBox {
  max-width: 20%;
}

@media (max-width: 1600px) {
  .footer .footerBox:first-child {
    max-width: 16%;
  }
  .footer .footerBox:last-child {
    max-width: 24%;
    flex-basis: 24%;
  }
}
/* For screens wider than 1920 */
@media (max-width: 1920) {
  .footerContainer h1 {
    font-size: 3.75rem;
    font-weight: 100;
  }
  .footerContainer h1 span {
    font-size: 3.75rem;
    font-weight: 800;
  }
  .footerContainer h6 {
    font-size: 1.25rem;
    font-weight: 600;
  }
  .footerContainer p {
    font-size: 1rem;
    font-weight: 400;
  }
  .footerContainer p a {
    font-size: 1rem;
    font-weight: 400;
  }
}


/* For screens wider than 1440px */
@media (max-width: 1440px) {  
  
  .footerContainer .footer p a, .footer .footerAddress {
    font-size: 14px;
    line-height: 22px;
  }
}

/* For screens wider than 1366px */
@media (max-width: 1366px) {
  .footerContainer .footer p a{
    font-size: 13px;
  }  
}

/* For screens wider than 1280px */
@media (max-width: 1280px) {
  .footerContainer .footer p a{
    font-size: 13px;
  }  
  .footerBox p {
    margin-bottom: 10px;
  }
  .footer .footerBox {
    max-width: 18%;
  }
  .footer .footerBox:first-child {
    max-width: 18%;
  }
  .footer .footerBox:last-child {
    max-width: 28%;
    flex-basis: 28%
  }  
  
}

/* For screens wider than 1024px */
@media (max-width: 1199px) {
  .footer .footerAddress span {
    display: inline;
  }
  .footerContainer .footer {
    padding-left: 70px;
    padding-right: 70px;
  }  
  .footer .footerBox:first-child {
    max-width: 20%;
  }
  .footer .footerBox:last-child {
    max-width: 26%;
    flex-basis: 26%;
  }
  .footerContainer .footer p a, .footer .footerAddress {
    font-size: 13px;
    line-height: 22px;
  }
  .footerBox p {
    margin-bottom: 5px;
  }
  .footer .footerHeads {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .footerDivider {
    margin: 15px 0 !important;
  }
  .footer .footerCopyWrite {
    font-size: 13px;
    line-height: 22px;
  }
}

@media (max-width: 899px) {
  .footerContainer .footer {
    padding-left: 50px;
    padding-right: 50px;
  }
  .footerBox  {
    padding-top: 15px !important;
  }
  .footer .footerBox:first-child {
    max-width: 33.33%;
  }
  .footer .footerBox {
    max-width: 33.33%;
    flex-basis: 33.33%;
  }
  .footer .footerBox:last-child {
    max-width: 40%;
    flex-basis: 40%;

  }
}

@media (max-width: 600px) {
  .footerContainer .footer {
    padding: 0 20px;
  }
  .footer .footerBox, .footer .footerBox:first-child, .footer .footerBox:last-child {
    max-width: 100%;
    flex-basis: 100%;
  }
  .footerSocial {
    margin-top: 5px !important;
    gap: 10px !important;
  }
  .footerSocial img {
    width: 15px;
    height: 15px;
  }
  .footer img.footerIcon {
    width: 100px;
  }
}
