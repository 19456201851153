.input-field-container {
  padding: 5px 0;
}

.address-box .input-field input {
  background-color: white;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  color: #333;
}

.input-field-container .full-width-input {
  margin-top: 12px;
  margin-right: 8px;
  margin-left: 7px;
}

.address-box .input-field {
  margin-top: 0px;
}

.address-box .input-field fieldset {
  padding-right: 15px;
}

.input-field-container .default-input {
  max-width: 100%;
}

@media (max-width: 768px) {
  .input-field-container .full-width-input {
    margin-top: 10px;
    margin-right: 2.5px;
  }
}
